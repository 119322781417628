<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Professional Information
        <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="
            'See Profile Section below for Additional Details'
          "
        />
        <em
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToExpendableSection()"
        ></em>
      </h6>
    </div>
    <CRow class="pb-2">
      <CCol>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Speciality</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ speciality }}</span>
      </CCol>
      </CRow>

      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Years in Speciality</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ yearsSpeciality }}</span>
      </CCol>
      </CRow>

      <!-- <CCol lg="6">
        <label class="text-gray">Other Areas of Expertise</label>
        <span class="text-black">{{ expertise }}</span>
      </CCol> -->

      <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Sub Type</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ subType }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Highest Medical Qualification</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ highestQualName }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Country Obtained</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ highestQualCountry }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Year Obtained</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ highestQualYear }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Current Job Title</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.current_job || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4">
        <label class="text-gray">Dataflow Complete</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{
          profile.dataflow_complete ? "Yes" : "No"
        }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Local License/Eligibility </label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ eligibility_letter_available_for }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">License Number</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.license_number || "--" }}</span>
      </CCol>
      </CRow>
       <CRow>
        <CCol class="more-less text-center" @click="ShowMoreLess()" >
          <i class="fa fa-chevron-circle-up" title='Show less' v-if="showMore" aria-hidden="true"></i>
          <i class="fa fa-chevron-circle-down" title='Show more' v-else aria-hidden="true"></i>
        </CCol>
      </CRow> 
      </CCOl>
    </CRow>

    <ProfessionalInfoAHPEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="ProfessionalInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import ProfessionalInfoAHPEdit from "./EditForm/ProfessionalInfoAHPEdit";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import moment from "moment";

export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
    nurseSpeciality: {
      type: Array,
      default: [],
    },
    specialInterest: {
      type: Array,
      default: [],
    },
  },
  components: {
    ProfessionalInfoAHPEdit,
    Modal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      showMore: false,
      splConfirmModal: {
        modalColor: "primary",
        modalTitle: "Reset Special Interest",
        modalContent: "Do you need to reset the Special Interest?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateSpecialInterest",
      "getCandidateNonMedicalQuals",
    ]),
    mainSpeciality() {
      return this.nurseSpeciality.filter(({ main }) => main);
    },
    yearsSpeciality() {
      return (
        (this.mainSpeciality &&
          this.mainSpeciality
            .map(({ experience_in_years }) => experience_in_years)
            .join()) ||
        "--"
      );
    },
    highestQualification() {
      return (
        (this.profile?.qualifications &&
          this.profile.qualifications.length > 1 &&
          this.profile?.qualifications.filter(({ main }) => main)) ||
        (this.profile.qualifications.length === 1 &&
          this.profile?.qualifications) ||
        []
      );
    },
    highestQualificationName() {
      return (
        (this.highestQualification &&
          this.highestQualification
            .map(
              ({
                qualification_name,
                month_awarded,
                year_awarded,
                country_name,
              }) =>
                `${qualification_name}-${country_name}-${this.formatDate(
                  month_awarded,
                  year_awarded
                )}`
            )
            .join()) ||
        "--"
      );
    },
    pqrlevel() {
      const pqrLevel =
        this.profile?.pqr_levels.filter(({ potential }) => !potential) || [];
      return pqrLevel.length
        ? pqrLevel[0]?.pqr_detail?.level?.candidate_level ||
            pqrLevel[0]?.pqr_ahp_detail?.description ||
            "--"
        : "--";
    },
    speciality() {
      return (
        (this.mainSpeciality &&
          this.mainSpeciality
            .map(({ speciality: { speciality } }) => speciality)
            .join()) || this.profile?.speciality?.speciality ||
        "--"
      );
    },
    subType() {
      return (
        (this.mainSpeciality &&
          this.mainSpeciality
            .map(({ sub_speciality }) => sub_speciality?.sub_speciality)
            .join()) ||
        "--"
      );
    },
    expertise() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities
            .filter(({ main }) => main === false)
            .map(
              ({ special_interest: { special_interest } }) => special_interest
            )
            .join()) ||
        "--"
      );
    },
    highestQualName() {
      return (
        (this.highestQualification &&
          this.highestQualification
            .map(
              ({
                qualification_name,
                month_awarded,
                year_awarded,
                country_name,
                description,
                qualification: { allow_subject_input },
              }) =>
                `${qualification_name}${
                  allow_subject_input && description
                    ? "(" + description + ")"
                    : ""
                }`
            )
            .join()) ||
        "--"
      );
    },
    highestQualCountryYear() {
      return (
        (this.highestQualification &&
          this.highestQualification
            .map(
              ({ year_awarded, country_name }) =>
                `${country_name}-${year_awarded}`
            )
            .join()) ||
        "--"
      );
    },
    highestQualCountry() {
      return (
        (this.highestQualification &&
          this.highestQualification
            .map(({ country_name }) => country_name)
            .join()) ||
        "--"
      );
    },
    highestQualYear() {
      return (
        (this.highestQualification &&
          this.highestQualification
            .map(({ year_awarded }) => year_awarded)
            .join()) ||
        "--"
      );
    },    
    eligibility_letter_available_for(){
      return this.profile?.eligibility_letter_available_for ? this.profile.eligibility_letter_available_for.split(",").join(", ") :"--"
    }
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "fetchQualification",
      "deleteSplInterestWithoutToast",
      "fetchspecialInterest",
      "showToast",
    ]),
    editProfessionalInfo() {
      this.EditModal.isShowPopup = true;
    },
    ProfessionalInfoModalCallBack(action, data) {
      this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          this.fetchQualification();
        });
      }
    },
    splModalCallBack(action) {
      this.splConfirmModal.isShowPopup = false;
      if (action == "Yes") {
        let appendAction = [];
        this.getCandidateSpecialInterest.forEach((val) => {
          if (val.special_interest_id)
            appendAction = this.deleteSplInterestWithoutToast(
              val.candidate_speciality_id
            );
        });
        Promise.all([appendAction]).then((res) => {
          this.fetchspecialInterest();
          this.showToast({
            class: "bg-success text-white",
            message: "Special Interest reset successful",
          });
        });
        this.$parent.goToSplInterestSection();
      }
    },
    formatDate(month_awarded, year_awarded) {
      if (month_awarded)
        return `${moment.monthsShort()[month_awarded - 1]} - ${year_awarded}`;
      return year_awarded || "";
    },
    ShowMoreLess() {
      this.showMore = !this.showMore;
    },
  },
};
</script>
