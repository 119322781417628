<template>
  <div class="pb-2">
    <a
      class="d-flex btn pl-0"
      @click="
        ($parent.isExpand.document = !$parent.isExpand.document),
          $parent.checkExpandAll()
      "
    >
      <span class="pr-1">
        <i
          class="text-primary fas"
          :class="
            $parent.isExpand.document ? 'fa-angle-down' : 'fa-angle-right'
          "
          alt="angle-down"
        />
      </span>
      <h5 class="text-primary text-left">
        Document Information
        <span class="nowrap"
          >[{{ documents.length + resumeDocument.length }} Record{{
            documents.length + resumeDocument.length > 1 ? "s" : ""
          }}]</span
        >
      </h5>
    </a>
    <div v-if="$parent.isExpand.document">
      <div v-if="isAdmin">
        <DocumentUpload @upload="upload" />
      </div>
      <div v-if="isMobile">
        <h6
          v-if="resumeDocument.length || documents.length"
          class="pb-1 fw-600 pt-2 pb-2"
        >
          Documents :
        </h6>
        <div v-for="document in resumeDocument" :key="document.document_id">
          <div class="pb-1 border-bottom pt-2 pb-2">
            <CRow class="pb-2">
              <CCol col="4" class="text-gray">Type</CCol>
              <CCol col="6" class="h6 fw-600">{{ document.lable }}</CCol>
              <CCol col="0" class="text-gray">
                <CButton
                  type="button"
                  @click="fileDownload(document, document.document_id)"
                  class="p-0"
                >
                  <i class="fas fa-download"></i>
                </CButton>
              </CCol>
            </CRow>
            <CRow class="pb-2">
              <CCol col="4" class="text-gray">Name</CCol>
              <CCol col="6" class="text-black">{{ document.name }}</CCol>
              <CCol col="0" class="text-black">
                <CButton type="button" @click="preview(document)" class="p-0">
                  <i class="fas fa-eye"></i>
                </CButton>
              </CCol>
            </CRow>
            <CRow class="pb-2">
              <CCol col="4" class="text-gray">Date of Upload</CCol>
              <CCol col="6" class="text-black">{{ document.updatedDate }}</CCol>
            </CRow>
            <CRow class="pb-2">
              <CCol col="6" class="text-gray">Description </CCol>
              <CCol col="6" class="text-black">
                {{ document.comments ? document.comments : "--" }}
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-for="document in documents" :key="document.document_id">
          <div class="pb-1 border-bottom pt-2 pb-2">
            <CRow class="pb-2">
              <CCol col="4" class="text-gray">Type</CCol>
              <CCol col="6" class="h6 fw-600 center">{{ document.lable }}</CCol>
              <CCol col="0" class="text-gray">
                <CButton
                  type="button"
                  @click="fileDownload(document, document.document_id)"
                  class="p-0"
                >
                  <i class="fas fa-download"></i>
                </CButton>
              </CCol>
            </CRow>
            <CRow class="pb-2">
              <CCol col="4" class="text-gray">Name</CCol>
              <CCol col="6" class="text-black center">{{ document.name }}</CCol>
              <CCol col="0" class="text-black">
                <CButton type="button" @click="preview(document)" class="p-0">
                  <i class="fas fa-eye"></i>
                </CButton>
              </CCol>
            </CRow>
            <CRow class="pb-2">
              <CCol col="4" class="text-gray">Date of Upload</CCol>
              <CCol col="6" class="text-black center">{{
                document.updatedDate
              }}</CCol>
            </CRow>
            <CRow class="pb-2">
              <CCol col="6" class="text-gray">Description </CCol>
              <CCol col="6" class="text-black">
                {{ document.comments ? document.comments : "--" }}
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="!documents.length">
          <p v-if="resumeDocument.length" class="text-center text-gray pt-3 h6">
            No Further Documents Found
          </p>
          <p v-else class="text-center text-gray h6">
            No Document Information Found
          </p>
        </div>
        <h6
          v-if="isCandidateRole"
          colspan="7"
          class="text-center pt-3"
          style="cursor: pointer"
        >
          <a class="text-primary" @click="navigateToEditProfile()">
            (Upload New Documents)
          </a>
        </h6>
      </div>
      <div v-else>
        <div
          class="
            d-flex
            align-items-center
            documentScrolling
            document-table
            border
          "
        >
          <table class="table">
            <thead>
              <tr>
                <th class="nowrap w-25">Type</th>
                <th class="nowrap">Description</th>
                <th class="nowrap">Name</th>
                <th class="nowrap">Date Uploaded</th>
                <th class="nowrap">Download</th>
                <th class="nowrap">Preview</th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="data in resumeDocument" :key="data.document_id">
                <td>{{ data.lable || "--" }}</td>
                <td class="text-justify">
                  {{ data.comments ? data.comments : "--" }}
                </td>
                <td class="candidate-file-name">{{ data.name || "--" }}</td>
                <td>{{ data.updatedDate }}</td>
                <td>
                  <button
                    :disabled="disabledBtnDocID.includes(data.document_id)"
                    class="hand-icon action-button"
                    v-if="data && data.link"
                    @click="fileDownload(data, data.document_id)"
                  >
                    <i class="fas fa-download ml-3"></i>
                  </button>
                </td>
                <td>
                  <a
                    class="hand-icon"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <i class="fas fa-eye ml-3"></i>
                  </a>
                </td>
              </tr>
              <tr v-for="data in documents" :key="data.document_id">
                <td>{{ data.lable || "--" }}</td>
                <td class="text-justify">
                  {{ data.comments ? data.comments : "--" }}
                </td>
                <td class="candidate-file-name">{{ data.name || "--" }}</td>
                <td>{{ data.updatedDate }}</td>
                <td>
                  <button
                    :disabled="disabledBtnDocID.includes(data.document_id)"
                    class="hand-icon action-button"
                    v-if="data && data.link"
                    @click="fileDownload(data, data.document_id)"
                  >
                    <i class="fas fa-download ml-3"></i>
                  </button>
                </td>
                <td>
                  <a
                    class="hand-icon"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <i class="fas fa-eye ml-3"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td v-if="!documents.length" colspan="7">
                  <p
                    v-if="resumeDocument.length"
                    class="text-center text-gray h6"
                  >
                    No Further Documents to Preview
                  </p>
                  <p v-else class="text-center text-gray h6">
                    No Documents to Preview
                  </p>
                  <h6
                    v-if="isCandidateRole"
                    colspan="7"
                    class="text-center"
                    style="cursor: pointer"
                  >
                    <a class="text-primary" @click="navigateToEditProfile()">
                      (Upload New Documents)
                    </a>
                  </h6>
                </td>
              </tr>
              <tr v-if="documents.length">
                <td colspan="7">
                  <h6
                    v-if="isCandidateRole"
                    colspan="7"
                    class="text-center"
                    style="cursor: pointer"
                  >
                    <a class="text-primary" @click="navigateToEditProfile()">
                      (Upload New Documents)
                    </a>
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PreviewModal
        :modalTitle="previewModal.modalTitle"
        :modalColor="previewModal.modalColor"
        :isShowPopup="previewModal.isShowPopup"
        :buttons="previewModal.buttons"
        :modalCallBack="modalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :size="previewModal.size"
        :closeOnBackdrop="previewModal.closeOnBackdrop"
      />
      <DocumentInfoEdit
        :documentInfo="EditModal.documentInfo"
        :isShowPopup="EditModal.isShowPopup"
        @modalCallBack="documentInfoModalCallBack"
        v-if="EditModal.isShowPopup"
      />
      <Modal
        :modalTitle="deleteConfirmModal.modalTitle"
        :modalColor="deleteConfirmModal.modalColor"
        :modalContent="deleteConfirmModal.modalContent"
        :isShowPopup="deleteConfirmModal.isShowPopup"
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
      />
      <QualificationMappingModal
        :isShowPopup="linkDocument.isShowPopup"
        :document_type_id="linkDocument.document_type_id"
        :candidate_document_id="linkDocument.candidate_document_id"
        :label="linkDocument.label"
        :modalTitle="linkDocument.modalTitle"
        :modalContent="linkDocument.modalContent"
      />
    </div>
  </div>
</template>
<script>
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import DocumentInfoEdit from "@/containers/CandidateDetailsTalentFind/EditForm/DocumentInfoEdit";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import DocumentUpload from "@/containers/CandidateDetailsTalentFind/EditForm/DocumentUpload";
import Modal from "@/components/reusable/Modal";
import QualificationMappingModal from "@/containers/CandidateProfile/QualificationMappingModal.vue";
export default {
  props: {
    resumeDocument: { type: Array, default: () => [] },
    documents: { type: Array, default: () => [] },
    disabledBtnDocID: { type: Array, default: () => [] },
    profile: { type: Object, default: () => null },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewModal,
    DocumentInfoEdit,
    DocumentUpload,
    Modal,
    QualificationMappingModal,
  },
  data() {
    return {
      iframe: {
        url: null,
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      EditModal: {
        isShowPopup: false,
        documentInfo: null,
      },
      Role,
      currentUserRole: getScope(),
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      candidate_document: {},
      linkDocument: {
        label: "",
        modalTitle: "",
        modalContent: "",
        candidate_document_id: null,
        isShowPopup: false,
        document_type_id: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getUserId", "uploaded", "lastUploadedDocs"]),
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    isAdmin() {
      return [
        this.Role.systemAdmin,
        this.Role.customerAdmin,
        this.Role.customerRecruiter,
      ].includes(this.currentUserRole);
    },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
  },
  methods: {
    ...mapActions([
      "updateDocument",
      "uploadPhoto",
      "uploadResume",
      "updateCandidateInfoByID",
      "deleteDocument",
      "fetchQualification",
      // "fetchLicense",
    ]),
    navigateToEditProfile() {
      this.$store.state.candidate.block = 2;
      this.$router.push("/profile");
    },
    fileDownload(data, docId) {
      this.$emit("fileDownload", data, docId);
    },
    preview(data) {
      var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      var _base_domain = "https://" + window.location.host;
      this.iframe.url = _base_domain + data.link;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(_base_domain + data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    editInfo(data) {
      this.EditModal.isShowPopup = true;
      this.EditModal.documentInfo = data;
      return false;
    },
    documentInfoModalCallBack(action, data) {
      this.EditModal.isShowPopup = false;
      if (action == true) {
        let payload = {
          candidate_document_id: this.EditModal.documentInfo.document_id,
          comments: data,
        };
        this.updateDocument(payload);
      }
    },
    upload(payload) {
      const document_type = payload.document_type || 1;
      const { customer_uid, candidate_uuid } = this.profile;
      const user_id = this.getUserId;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uuid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
          });
        } else {
          this.uploadResume({
            customer_uid,
            candidate_uuid,
            user_id,
            [payload.name]: payload.value,
            document_type,
          });
        }
      }
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${data.name} ?`;
      this.candidate_document = data;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        let document = this.candidate_document;
        this.deleteDocument(document.document_id).then((res) => {
          if (document.document_type_id == 12) this.fetchQualification();
          // if (document.document_type_id == 9) this.fetchLicense();
        });
      }
      this.candidate_document = {};
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        const { candidate_uid, candidate_uuid } = this.profile;
        this.updateCandidateInfoByID(candidate_uuid);
        this.linkDocument.document_type_id =
          this.lastUploadedDocs?.document_type_id;
        this.linkDocument.label = this.lastUploadedDocs?.document_name;
        this.linkDocument.candidate_document_id =
          this.lastUploadedDocs?.candidate_document_id;
        if (this.lastUploadedDocs?.document_type_id == 9) {
          this.linkDocument.modalTitle = "License Mapping";
          this.linkDocument.modalContent =
            "Select the License to be mapped with the Document:";
          this.linkDocument.isShowPopup = true;
        } else if (this.lastUploadedDocs?.document_type_id == 12) {
          this.linkDocument.modalTitle = "Qualification Mapping";
          this.linkDocument.modalContent =
            "Select the Qualification to be mapped with the Document:";
          this.linkDocument.isShowPopup = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  .iframeclass {
    width: 75%;
  }
  .modalPdfContent {
    height: 350px !important;
    overflow-y: scroll;
  }
  .wrapper {
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  @media all and (max-width: 480px) {
    .iframeclass {
      width: auto;
    }
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  cursor: pointer;
}
</style>
