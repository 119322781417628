<template>
  <div>
    <div class="d-flex justify-content-end action-wrapper">
      <CButton
        v-if="currentUserRole === Role.customerCandidate"
        @click="navigateToEditProfile()"
        class="btn-primary"
        title="Edit Profile"
        >Edit Profile</CButton
      >
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Personal Information</h6>
      <!--<div class="d-flex justify-content-end action-wrapper">
        <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editBasicInfo"
        >
          <i class="fas fa-edit" alt="Edit" />
        </a>
      </div>-->
    </div>
    <CRow class="pb-2">
      <CCol>
      <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Known as</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.known_as || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Middle Name</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.middle_name || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Date of Birth</label>
      </CCol> 
      <CCol lg="8">
        <span class="text-black">{{ profile.dob | moment }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Place of Birth</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.birthPlaceName || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4">
        <label class="text-gray">Marital Status</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.marital_status || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Dependents</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.dependents || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Family Move</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.relocate_family | yesORno }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Passport Number</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.passport_number || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Emirates ID</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.emirates_id || "--" }}</span>
      </CCol>
      </CRow>
       <CRow>
        <CCol class="more-less text-center" @click="ShowMoreLess()" >
          <i class="fa fa-chevron-circle-up" title='Show less' v-if="showMore" aria-hidden="true"></i>
          <i class="fa fa-chevron-circle-down" title='Show more' v-else aria-hidden="true"></i>
        </CCol>
      </CRow> 
      </CCol>
    </CRow>
    <BasicInfoEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="basicInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import moment from "moment";
import router from "@/router/index";
import { Role, getScope } from "@/helpers/helper";
import BasicInfoEdit from "./EditForm/BasicInfoEdit";
import { mapActions } from "vuex";
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  components: {
    BasicInfoEdit,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      showMore: false
    };
  },
  computed: {
    title() {
      return this.profile.title?.title || "--";
    },
    spouseProfile() {
      return (
        this.profile.spouse_registered &&
        this.profile.spouse_email != null &&
        this.profile.spouse_profile_id != null
      );
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
    yesORno(data) {
      if (data != null) return data ? "Yes" : "No";
      return "N/A";
    },
  },
  methods: {
    ...mapActions(["updateCandidate", "fetchPQRLevels"]),
    editBasicInfo() {
      this.EditModal.isShowPopup = true;
    },
    basicInfoModalCallBack(action, data) {
      if (action === false) this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          if (res) this.EditModal.isShowPopup = false;
          this.fetchPQRLevels();
        });
      }
    },
    navigateToEditProfile() {
      this.$store.state.candidate.block = 0;
      router.push("/profile");
    },    
    ShowMoreLess() {
      this.showMore = !this.showMore;
    }
  },
};
</script>
