<template>
  <div>
    <div class="d-flex align-items-center justify-content-between pb-2">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.qualification = !$parent.isExpand.qualification),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas pt-1"
            :class="
              $parent.isExpand.qualification
                ? 'fa-angle-down'
                : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary pt-1 text-left">
          Qualification Information
          <span class="nowrap"
            >[{{ sortedQualifications.length || "0" }} Record{{
              sortedQualifications.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.qualification"
      >
        <!--<CButton
          v-if="isCustomerAdmin && qualifications.length === 1"
          class="btn-primary small mr-1 mt-1"
          style="height: 28px"
          @click="addQualification()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >-->
        <CButton
          v-if="sortedQualifications.length"
          @click="qualification_step -= 1"
          :disabled="qualification_step == 0"
        >
          <CIcon name="cil-caret-left" />
        </CButton>
        <CButton
          v-if="sortedQualifications.length"
          @click="qualification_step += 1"
          :disabled="qualification_step + 1 == qualificationsCustom.length"
        >
          <CIcon name="cil-caret-right" />
        </CButton>
      </div>
    </div>
    <div class="ml-3" v-if="$parent.isExpand.qualification">
      <div v-if="sortedQualifications.length">
        <div class="m-0">
          <CRow
            v-for="(data, parentIndex) in qualificationsCustom"
            :key="parentIndex"
          >
            <CCol lg="12" v-if="parentIndex === qualification_step">
              <div v-if="isMobile">
                <div class="d-flex justify-content-between p-2">
                  <h6 class="pb-1 fw-600 pt-1">
                  Qualification {{subIndex + 1}} {{ qualification.main ?  `- (Highest)`  : '' }} 
                  
                  <!--  {{ data.isFirst ? `First` : `Highest` }} Qualification-->
                  </h6>
                  <!--<span v-if="isCustomerAdmin">
                    <a
                      class="hand-icon btn p-0"
                      @click="showDocumentModal(data)"
                    >
                      <i class="fas fa-link"></i>
                    </a>
                    <a
                      class="hand-icon btn p-0"
                      @click="editQualification(data)"
                    >
                      <i class="fas fa-edit ml-3"></i>
                    </a>
                    <a
                      class="hand-icon btn p-0"
                      v-if="!data.isFirst"
                      @click="deleteQualification(data)"
                    >
                      <i class="fas fa-trash ml-3"></i>
                    </a>
                  </span>-->
                </div>
                <QualificationInfoCardNurse
                  :qualification="data"
                  :mainSpeciality="mainSpeciality"
                />
              </div>
              <div v-else>
                <CRow>
                  <CCol
                    lg="6" 
                    v-for="(qualification, subIndex) in data"
                    :key="subIndex"
                  >
                  <div class="gray-box mx-1">
                    <div class="d-flex justify-content-between p-2">
                      <h6 class="pb-1 fw-600 pt-1">
                        
                         Qualification {{ subIndex + parentIndex * 2 + 1}}
                        <span> {{ qualification.main ? "- (Highest)" : "" }} </span>

                      </h6>
                      <!--<span v-if="isCustomerAdmin">
                        <a
                          class="hand-icon btn p-0"
                          @click="showDocumentModal(qualification)"
                        >
                          <i class="fas fa-link"></i>
                        </a>
                        <a
                          class="hand-icon btn p-0"
                          @click="editQualification(qualification)"
                        >
                          <i class="fas fa-edit ml-3"></i>
                        </a>
                        <a
                          class="hand-icon btn p-0"
                          v-if="!qualification.isFirst"
                          @click="deleteQualification(qualification)"
                        >
                          <i class="fas fa-trash ml-3"></i>
                        </a>
                      </span>-->
                    </div>
                    <QualificationInfoCardNurse
                      :qualification="qualification"
                      :mainSpeciality="mainSpeciality"
                      :profile="profile"
                    />
                  </div>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
      <div v-else>
        <p class="text-center text-gray h6">
          No Qualification Information Found
        </p>
      </div>
    </div>
    <QualificationInfoNurseEdit
      :qualificationInfo="EditModal.qualificationInfo"
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="qualificationInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteQualificationCallBack"
      :closeOnBackdrop="false"
    />
    <DocumentMappingModal
      :isShowPopup="linkDocument.isShowPopup"
      :document_type_id="12"
      :label="linkDocument.label"
      :candidate_qualification_id="linkDocument.candidate_qualification_id"
      :document_ids="linkDocument.document_ids"
      @modalCallBack="linkModalCallBack"
    />
  </div>
</template>
<script>
import m  from "moment";
import QualificationInfoCardNurse from "@/containers/CandidateDetailsTalentFind/ProfileSection/QualificationNurse/QualificationInfoCardNurse";
import QualificationInfoNurseEdit from "@/containers/CandidateDetailsTalentFind/EditForm/QualificationInfoNurseEdit";
import Modal from "@/components/reusable/Modal";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import DocumentMappingModal from "@/containers/CandidateProfile/DocumentMappingModal.vue";
export default {
  props: {
    qualifications: {
      type: Array,
      default: () => [],
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    profile: {
      type: Object,
      default: null,
    },
    specialInterests: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    QualificationInfoCardNurse,
    Modal,
    QualificationInfoNurseEdit,
    DocumentMappingModal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      qualification_step: 0,
      EditModal: {
        isShowPopup: false,
        qualificationInfo: null,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      candidate_qualification_id: "",
      linkDocument: {
        isShowPopup: false,
        label: "",
        candidate_qualification_id: null,
        document_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters(["uploaded"]),
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    qualificationsCustom() {
      if (!this.isMobile)
        return this.sortedQualifications.reduce((a, c, i) => {
          return i % 2 === 0
            ? a.concat([this.sortedQualifications.slice(i, i + 2)])
            : a;
        }, []);
      return this.sortedQualifications;
    },
    sortedQualifications() {
      if (this.qualifications) {
        return this.qualifications
          .sort((a, b) => {
            return b.qualification_name - a.qualification_name;
          })
          .sort((a, b) => {
            return b.year_awarded - a.year_awarded;
          })
          .sort((a, b) => (a.main === b.main ? 0 : a.main ? 1 : -1));
      }
      return false;
    },
    mainSpeciality() {
      return this.specialInterests.filter(({ main }) => main) || [];
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.fetchQualification();
        this.fetchPQRLevels();
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchQualification",
      "uploadQualification",
      "updateQualification",
      "deleteCandidateQualification",
      "showToast",
      "fetchPQRLevels",
      "fetchUploadedDocument",
    ]),
    addQualification() {
      this.$parent.tooltipHide();
      this.EditModal.isShowPopup = true;
      this.EditModal.qualificationInfo = null;
      return false;
    },
    editQualification(data) {
      this.EditModal.isShowPopup = true;
      this.EditModal.qualificationInfo = data;
      return false;
    },
    showDocumentModal(data) {
      this.linkDocument.candidate_qualification_id = data.candidate_qualification_id
        ? data.candidate_qualification_id
        : null;
      this.linkDocument.document_ids = data.document_ids || [];
      this.linkDocument.label = data.qualification_with_acronym;
      this.linkDocument.isShowPopup = true;
    },
    linkModalCallBack(action, value, candidate_qual_id) {
      if (action == true) {
        let qualificationPayload = {
          document_ids: value?.length ? value : null,
        };
        this.updateQualification({
          candidate_qualification_id: candidate_qual_id,
          qualificationPayload,
        }).then((res) => {
          this.fetchQualification();
          this.fetchUploadedDocument();
        });
      }
      this.linkDocument.candidate_qual_id = null;
      this.linkDocument.isShowPopup = false;
    },
    qualificationInfoModalCallBack(action, data) {
      this.EditModal.isShowPopup = false;
      let candidate_qualification_id = this.EditModal.qualificationInfo
        ?.candidate_qualification_id;
      let qualificationPayload = data;
      if (action == true) {
        if (candidate_qualification_id !== null) {
          let _count = 0;
          _.forEach(this.candidateProfile?.qualifications?.length ? this.candidateProfile?.qualifications : [], (qual, i) => {
            let qual_year = qualificationPayload?.year_awarded
            if (qual.qualification_id === qualificationPayload.qualification_id && m(qual.year_awarded).isSame(qual_year)) {
              this._index = i;
              _count = _count + 1;
            }
          })
          if (
            _count >= 1 &&
            this.candidateProfile.qualifications[this._index]
              .candidate_qualification_id !== candidate_qualification_id
          ) {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Already Exists!",
            });
            return;
          }
        } else {
          if (
            _.findIndex(this.candidateProfile.qualifications?.length ?  this.candidateProfile.qualifications : [], (qual) => {
              let qual_year = qualificationPayload?.year_awarded;
              return qual.qualification_id === qualificationPayload.qualification_id && m(qual.year_awarded).isSame(qual_year)
            }) >= 0
          ) {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Already Exists!",
            });
            return;
          }
        }
        this.EditModal.isShowPopup = false;
        if (candidate_qualification_id) {
          this.updateQualification({
            candidate_qualification_id,
            qualificationPayload,
          }).then((res) => this.fetchUploadedDocument());
        } else {
          let payload = {
            ...qualificationPayload,
            candidate_uid: this.profile.candidate_uid,
          };
          this.uploadQualification(payload).then((res) => {
            if (qualificationPayload?.document_ids?.length) {
              this.fetchUploadedDocument();
            }
          });
        }
      }
      this.EditModal.qualificationInfo = null;
    },
    deleteQualification(data) {
      if (this.qualifications && this.qualifications.length === 1) {
        (this.deleteConfirmModal.modalTitle = "Can't be Deleted"),
          (this.deleteConfirmModal.buttons = ["OK"]);
        this.deleteConfirmModal.modalContent =
          "Please add another Qualification to delete the selected entry!";
        this.deleteConfirmModal.isShowPopup = true;
      } else if (
        this.profile.qualifications.filter(({ main }) => main === true)[0]
      ) {
        let main_id = this.profile.qualifications.filter(
          ({ main }) => main === true
        )[0].qualification_id;
        let delete_id = data.qualification_id;
        if (main_id === delete_id) {
          (this.deleteConfirmModal.modalTitle = "Alert !!"),
            (this.deleteConfirmModal.buttons = ["Cancel", "Confirm"]);
          this.deleteConfirmModal.modalContent =
            "Are you sure to delete this main qualification? This will reset Speciality and Sub Speciality!";
          this.deleteConfirmModal.isShowPopup = true;
          this.candidate_qualification_id = data.candidate_qualification_uuid;
        } else {
          (this.deleteConfirmModal.modalTitle = "Delete Qualification"),
            (this.deleteConfirmModal.buttons = ["Cancel", "Confirm"]);
          this.deleteConfirmModal.modalContent = `Do you want to delete this Qualification Info  - ${data.qualification_name} ?`;
          this.candidate_qualification_id = data.candidate_qualification_uuid;
          this.deleteConfirmModal.isShowPopup = true;
        }
      }
    },
    deleteQualificationCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateQualification(this.candidate_qualification_id);
      }
      this.candidate_qualification_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
};
</script>
