<template>
  <div class="profile-info">
    <div class="mt-2 text-right">
      <h6>
        <button
          class="text-primary bg-white"
          style="border: none; outline: none; font-weight: 500"
          @click="expandAll()"
        >
          {{ isExpandAll ? "- Collapse All" : "+ Expand All" }}
        </button>
      </h6>
    </div>
    <div
      class="specialization-section mt-2 pb-2 border-bottom"
      v-if="isNurse || isAHP"
    >
      <SpecializationInfo
        :profile="profile"
        :specialInterests="getCandidateSpecialInterest"
        :isMobile="isMobile"
      />
    </div>
    <div class="qualification-section mt-2 pb-2 border-bottom">
      <QualificationInfoNurse
        v-if="isNurse || isAHP"
        :profile="profile"
        :qualifications="qualifications"
        :isMobile="isMobile"
        :specialInterests="getCandidateSpecialInterest"
      />
      <QualificationInfo
        v-else
        :profile="profile"
        :qualifications="qualifications"
        :isMobile="isMobile"
        :specialInterests="getCandidateSpecialInterest"
      />
    </div>
    <!-- <div class="specialInterest-info-section mt-2 pt-4 border-top" v-if="isAHP">
      <SpecialInterestInfo
        :profile="profile"
        :specialInterests="specialInterests"
        :isMobile="isMobile"
      />
    </div> -->
    <div class="document-info-section mt-2 pt-2 border-bottom">
      <DocumentInfo
        :resumeDocument="resumeDocument"
        :documents="documents"
        :disabledBtnDocID="disabledBtnDocID"
        :profile="profile"
        @fileDownload="fileDownload"
        :isMobile="isMobile"
      />
    </div>
    <!-- <div class="language-info-section mt-2 pt-4 border-top">
      <LanguageInfo
        :LanguageSpokenInfo="LanguageSpokenInfo"
        :isMobile="isMobile"
      />
    </div> -->
    <div class="language-info-section mt-2 pt-2 border-bottom">
      <NonMedicalInfo
        :profile="profile"
        :NonMedicalInfo="NonMedicalInfos"
        :isMobile="isMobile"
      />
    </div>
    <div class="privacyAndTerms-info-section mt-2 pt-2 border-bottom">
      <PrivacyAndTerms :profile="profile" />
    </div>
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import QualificationInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/Qualification/QualificationInfo";
import DocumentInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/DocumentInfo";
import NonMedicalInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/NonMedicalInfo/NonMedicalInfo";
import LanguageInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/LanguageInfo";
import SpecialInterestInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/SpecialInterestInfo";
import PrivacyAndTerms from "@/containers/CandidateDetailsTalentFind/ProfileSection/T&CandGDPRStatementInfo";
import Modal from "@/components/reusable/Modal";
import SpecializationInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/SpecializationInfoNurse";
import QualificationInfoNurse from "@/containers/CandidateDetailsTalentFind/ProfileSection/QualificationNurse/QualificationInfoNurse";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    qualifications: {
      type: Array,
      default: () => [],
    },
    resumeDocument: {
      type: Array,
      default: () => [],
    },
    documents: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    QualificationInfo,
    DocumentInfo,
    LanguageInfo,
    NonMedicalInfo,
    SpecialInterestInfo,
    PrivacyAndTerms,
    Modal,
    SpecializationInfo,
    QualificationInfoNurse,
  },
  data() {
    return {
      isMobile: false,
      qualification_step: 0,
      disabledBtnDocID: [],
      isExpand: {
        qualification: false,
        NonMedicalQual: false,
        pqr: false,
        specialInterest: false,
        document: false,
        license: false,
        language: false,
        privacyAndTerms: false,
        specialization: false,
      },
      isExpandAll: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 420px)")
      .matches;
    this.isMobile = isMobile;
  },
  computed: {
    ...mapGetters([
      "getCandidatePQRLevels",
      "getCandidatePQRLevel",
      "getCandidateLanguage",
      "getCandidateNonMedicalQuals",
      "getSkills",
    ]),
    getCandidateSpecialInterest() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities) ||
        []
      );
    },
    NonMedicalInfos() {
      return (
        this.getCandidateNonMedicalQuals.map(
          ({
            id,
            non_medical_qual,
            non_medical_qual_id,
            name,
            score,
            skill_level,
            month,
            year,
            others_name,
            others_score,
            expired_message,
          }) => {
            return {
              id: id !== null ? id : "--",
              non_medical_qual_id: non_medical_qual
                ? non_medical_qual?.id
                : null,
              name: non_medical_qual?.name ? non_medical_qual?.name : "--",
              qual_type_id: non_medical_qual?.non_medical_qual_type
                ? non_medical_qual?.non_medical_qual_type?.id
                : "--",
              qual_type_name: non_medical_qual?.non_medical_qual_type?.type_name
                ? non_medical_qual?.non_medical_qual_type?.type_name
                : "--",
              name: non_medical_qual ? non_medical_qual?.name : "--",
              score: non_medical_qual ? non_medical_qual?.score : "--",
              skill_level: non_medical_qual
                ? non_medical_qual?.skill_level
                : "--",
              month: month !== null ? month : null,
              year: year !== null ? year : null,
              others_name: others_name !== null ? others_name : "--",
              others_score: others_score !== null ? others_score : "--",
              expired_message: this.expiryCheck(month, year, non_medical_qual),
            };
          }
        ) || []
      );
    },
    LanguageSpokenInfo() {
      return (
        this.getCandidateLanguage.map(
          ({
            language_id,
            candidate_language_id,
            language,
            language_proficiency,
            comments,
          }) => {
            return {
              language_id: language.language_id,
              candidate_language_id: candidate_language_id,
              language: language.language,
              proficiency: language_proficiency?.proficiency,
              comments: comments,
            };
          }
        ) || []
      );
    },
    specialInterests() {
      return (
        this.getCandidateSpecialInterest
          .filter(
            (val) => val.special_interest_id || val.other_special_interest
          )
          .map(
            ({
              candidate_speciality_id,
              special_interest,
              other_special_interest,
            }) => {
              return {
                candidate_speciality_id: candidate_speciality_id,
                special_interest_id:
                  special_interest != null
                    ? special_interest.special_interest_id
                    : null,
                special_interest_name:
                  special_interest !== null
                    ? special_interest.special_interest
                    : "Other",
                other_special_interest: other_special_interest,
              };
            }
          ) || []
      );
    },
    skills() {
      return (
        this.getSkills.map(
          ({
            candidate_skill_id,
            skill_id,
            skill_name,
            skill,
            date_attained,
            procedures_performed,
            description,
          }) => {
            return {
              candidate_skill_id: candidate_skill_id,
              skill_id: skill_id,
              skill_name: skill.skill,
              date_attained: date_attained,
              procedures_performed: procedures_performed,
              description: description,
            };
          }
        ) || []
      );
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
    isCandidate() {
      return [Role.customerCandidate, Role.systemCandidate].includes(
        this.currentUserRole
      );
    },
  },
  methods: {
    ...mapActions(["downloadDocument", "checkPQRLevel", "deleteDocument"]),
    fileDownload(data, docId) {
      if (docId) this.disabledBtnDocID.push(docId);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        if (docId) {
          this.disabledBtnDocID = this.disabledBtnDocID.filter(
            (i) => i != docId
          );
        }
      });
      return false;
    },
    expandAll() {
      this.isExpandAll = !this.isExpandAll;
      for (const [key, value] of Object.entries(this.isExpand)) {
        this.isExpand[key] = this.isExpandAll;
      }
    },
    checkExpandAll() {
      let temp = false;
      for (const [key, value] of Object.entries(this.isExpand)) {
        value ? (temp = true) : null;
      }
      if (temp) this.isExpandAll = false;
    },
    resetExpand() {
      for (const [key, value] of Object.entries(this.isExpand)) {
        this.isExpand[key] = false;
      }
      this.isExpandAll = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    expiryCheck(month, year, non_medical_qual) {
      let today = new Date();
      let expires_in = non_medical_qual?.expires_in
        ? non_medical_qual?.expires_in
        : null;
      if (month && year && expires_in) {
        year += expires_in;
        let expiration_date = new Date(
          `${month != 12 ? year : year + 1}-${month != 12 ? month + 1 : 1}-01`
        );
        if (expiration_date <= today) {
          let expired_message = non_medical_qual?.expired_message;
          return expired_message;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    checkPotential(issue_authority_id) {
      return (
        this.getCandidatePQRLevels
          .filter(
            (val) =>
              val?.potential &&
              (val?.pqr_detail?.pqr_header?.issue_authority_id ===
                issue_authority_id ||
                val?.pqr_ahp_detail?.pqr_header?.issue_authority_id ===
                  issue_authority_id)
          )
          .map((v) => ({
            pqr_detail_description:
              v?.pqr_detail?.description ||
              v?.pqr_ahp_detail?.description ||
              null,
            comments: v?.comments,
          })) || []
      );
    },
  },
};
</script>
