<template>
  <div class="top-line">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Contact Information</h6>
      <!--<div class="d-flex justify-content-end action-wrapper" v-if="isCandidate">
        <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editContactInfo"
        >
          <i class="fas fa-edit" alt="Edit" />
        </a>
      </div>-->
    </div>
    <CRow class="mb-2" v-if="preferredContactMethod">
      <CCol col="12" class="text-gray">Preferred Contact Method</CCol>
      <CCol col="12" class="text-black">
        {{ preferredContactMethod | preferred_contact_method }}
      </CCol>
    </CRow>
    <CRow class="pb-2 m-0">
      <div class="d-flex justify-content-between">
        <span>
          <i class="far fa-envelope mr-2"></i>
        </span>
        <div class="d-flex justify-content-start sticky-header">
          <span>
            <span
              class="d-block"
              :style="
                preferredContactMethod.includes('email')
                  ? 'color: red;'
                  : 'color: black;'
              "
              :class="{'mx-76':!isCandidateRole}"
              @click="openEmailModal(email)"
              >{{ email }}</span
            >
          </span>
        </div>
      </div>
    </CRow>
    <CRow class="pb-2 m-0">
      <div class="d-flex justify-content-between">
        <span>
          <i class="fa fa-phone mr-2"></i>
        </span>
        <div class="d-flex justify-content-start sticky-header">
          <span>
            <span
              class="d-block"
              :style="
                preferredContactMethod.includes('phone')
                  ? 'color: red;'
                  : 'color: black;'
              "
              >{{ phone }}</span
            >
          </span>
        </div>
      </div>
    </CRow>
    <CRow class="pb-2 m-0">
      <span>
        <i class="fab fa-whatsapp mr-2"></i>
      </span>
      <div class="d-flex justify-content-start sticky-header">
        <span
          class="d-block"
          :style="
            preferredContactMethod.includes('whatsapp')
              ? 'color: red;'
              : 'color: black;'
          "
          >{{ whatsapp }}</span
        >
      </div>
    </CRow>
    <div v-if="isCandidate">
      <CRow class="pb-2 m-0" v-if="isCandidate">
        <span>
          <i class="fas fa-map-marker-alt mr-2" style="color: #ea4335"></i>
          <span class="text-black">{{ currentLocation || "--" }}</span>
        </span>
      </CRow>
    </div>
    <ContactInfoEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="contactInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <EmailModal
      v-if="emailModal.email"
      :isShowPopup="emailModal.email"
      :email_id="emailId"
      :candidate_uid="profile.candidate_uid"
      :alternate_email="profile.alternate_email"
      @emailModalCallBack="modalCallBack"
      @conversationModalCallBack="conversationModalCallBack"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ContactInfoEdit from "./EditForm/ContactInfoEdit";
import EmailModal from "@/containers/Communication/EmailModal";
import DeleteModal from "@/containers/Communication/DeleteModal";

export default {
  props: {
    isHospital: {
      type: Boolean,
      default: false,
    },
    isSideBar: {
      type: Boolean,
      default: false,
    },
    isCandidate: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
    },
  },
  components: {
    ContactInfoEdit,
    EmailModal,
    DeleteModal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      EditModal: {
        isShowPopup: false,
      },
      emailId: null,
      emailModal: {
        email: false,
      },
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedCandidateDataForJobsList",
      "getJobDetailsById",
      "locationCountryInfo",
    ]),
    email() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.email || "--"
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.email || "--";
      } else {
        return this.profile?.email || "--";
      }
    },
    alternate_email() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.alt_email || null
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.alternate_email || null
        );
      } else {
        return this.profile?.alternate_email || null;
      }
    },
    phone() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.phone_no?.replace(
            "^",
            "-"
          ) || "--"
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.phone?.replace("^", "-") ||
          "--"
        );
      } else {
        return this.profile?.phoneNumber || "--";
      }
    },
    alternate_phone() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.alt_phone_no?.replace(
            "^",
            "-"
          ) || null
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.alternate_phone?.replace(
            "^",
            "-"
          ) || null
        );
      } else {
        return this.profile?.alternatePhoneNumber || null;
      }
    },
    whatsapp() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.prefer_whats_app?.replace(
            "^",
            "-"
          ) || "--"
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.whatsapp?.replace(
            "^",
            "-"
          ) || "--"
        );
      } else {
        return this.profile?.whatsappNumber || "--";
      }
    },
    linkedin_address() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact
            ?.linkedin_address || "--"
        );
      } else if (this.isSideBar) {
        return (
          this.getSelectedCandidateDataForJobsList?.linkedin_address || "--"
        );
      } else {
        return this.profile?.linkedin_address || "--";
      }
    },
    skype_id() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.skype || "--"
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.skype_id || "--";
      } else {
        return this.profile?.skype_id || "--";
      }
    },
    address_line_1() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.address || null
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.address_line_1 || null;
      } else {
        return this.profile?.address_line_1 || null;
      }
    },
    address_line_2() {
      if (this.isHospital) {
        return (
          this.getJobDetailsById?.organisation?.main_contact?.address2 || null
        );
      } else if (this.isSideBar) {
        return this.getSelectedCandidateDataForJobsList?.address_line_2 || null;
      } else {
        return this.profile?.address_line_2 || null;
      }
    },
    currentLocation() {
      return (
        this.locationCountryInfo &&
        this.locationCountryInfo.length &&
        this.profile.country_id &&
        this.locationCountryInfo.filter(
          (val) => val.code === this.profile.country_id
        )[0].label
      );
    },
    preferredContactMethod() {
      return this.profile.preferred_contact_method || "";
    },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
  },
  methods: {
    ...mapActions(["updateCandidate", "initFetchContactInfoOptions"]),

    editContactInfo() {
      this.EditModal.isShowPopup = true;
    },
    contactInfoModalCallBack(action, data) {
      if (action === false) this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          if (res) this.EditModal.isShowPopup = false;
        });
      }
    },
    openEmailModal(emailId) {
      if (
        this.currentUserRole === this.Role.systemCandidate ||
        this.currentUserRole === this.Role.customerCandidate
      ) {
        return;
      }
      this.emailId = emailId;
      this.emailModal.email = true;
    },
    modalCallBack(columnKey) {
      this.emailModal[columnKey] = false;
    },
    conversationModalCallBack(delete_id, subject) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.delete_id = delete_id;
      this.deleteModal.subject = subject;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.emailModal.email = true;
    },
  },
  mounted() {
    this.initFetchContactInfoOptions();
  },
  filters: {
    preferred_contact_method(data) {
      const preferred_contact_method = {
        phone: "Phone",
        whatsapp: "WhatsApp",
        email: "Email",
        sms: "SMS",
      };
      let contact_methods = data
        .split(",")
        .map((e) => preferred_contact_method[e.trim()])
        .join(", ");
      return contact_methods;
    },
  },
};
</script>

<style lang="scss" scoped>
.mx-76 {
  cursor: pointer;
}
</style>