<template>
  <div>
    <CRow class="mb-3 p-2 scrolling" id="inProcess-scrolling">
      <CCol
        v-for="(item, index) in candidates"
        :key="index"
        class="col-sm-6 col-md-6 p-1"
      >
        <div
          v-if="isFetchingCandidateJobInProcessList"
          class="skeleton-card"
        ></div>
        <CCard v-else class="candidate-card mb-2" style="min-height: 300px">
          <CCardBody>
            <div class="content-job-title mb-3">
              <div class="image-wrapper">
                <img
                  :src="getProfilePic(item.candidate)"
                  class="rounded-circle"
                  alt="DP"
                />
              </div>
              <span class="d-block"
                ><h5
                  class="m-0 mt-2"
                  :style="`color: ${getGenderColor(item.candidate.gender)}`"
                >
                  <a
                    class="cursor-action"
                    v-if="
                      item.candidate.candidate_uid !=
                        candidateProfile.candidate_uid
                    "
                    @click="navigateToCandidateDetail(item.candidate)"
                  >
                    {{ toTitleCase(item.candidate.first_name) }}
                    {{ toTitleCase(item.candidate.surname) }}
                  </a>
                  <span v-else style="cursor: default">
                    {{ toTitleCase(item.candidate.first_name) }}
                    {{ toTitleCase(item.candidate.surname) }}
                  </span>
                </h5></span
              >
              <span class="d-block">
                <span class="text-muted">{{
                  item.candidate.candidate_type.candidate_type
                }}</span>
              </span>
              <span class="d-block"></span>
              <span
                class="d-block"
                v-if="
                  item.candidate.speciality || item.candidate.qualifications
                "
                >{{ speciality(item.candidate) }}
              </span>
              <span class="d-block">{{ getCountry(item.candidate) }}</span>
            </div>
            <div class="candidate-status mt-2">
              <CBadge
                color="success"
                class="font-weight-normal"
                v-if="item.job_applied"
              >
                <span>Applied</span></CBadge
              >
              <CBadge color="primary" class="font-weight-normal" v-else
                ><span>Talent Pool</span></CBadge
              >
              <CBadge
                color="matched"
                class="font-weight-normal ml-1 text-white"
                v-if="item.status && item.status['display_text']"
                ><span>{{ item.status["display_text"] }}</span></CBadge
              >
              <CBadge
                color="warning"
                class="font-weight-normal ml-1 text-white"
                v-if="item.sub_status && item.sub_status['display_text']"
                ><span>{{ item.sub_status["display_text"] }}</span></CBadge
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        class="d-flex justify-content-center mt-2"
        v-if="!candidates.length && !isFetchingCandidateJobInProcessList"
      >
        <h3>No Data found!</h3>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InProcessSection",
  props: ["candidateJobPageInProcessList", "jobDetails"],
  computed: {
    ...mapGetters([
      "getGenderColor",
      "getCandidateJobInProcessPagination",
      "isFetchingCandidateJobInProcessList",
      "candidateProfile",
    ]),
    candidates() {
      return this.candidateJobPageInProcessList;
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("inProcess-scrolling");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "resetCandidateDetail",
      "fetchCandidateJobPageInProcessList",
    ]),
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    speciality(data) {
      let highestQualification = null;
      data.qualifications &&
        data.qualifications.length &&
        data.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
          }
        });
      let speciality = data.speciality?.speciality || null;
      let sub_speciality = data.sub_speciality?.sub_speciality || null;
      highestQualification = highestQualification
        ? highestQualification.qualification.qualification_with_acronym
        : null;
      if (highestQualification) {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality + "|" + highestQualification;
        } else if (speciality) {
          return speciality + "|" + highestQualification;
        } else {
          return highestQualification;
        }
      } else {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality;
        } else if (speciality) {
          return speciality;
        } else {
          return null;
        }
      }
    },
    getCountry(data) {
      return data.location?.country?.country_name || null;
    },
    getProfilePic(data) {
      return data.profile_picture;
    },
    navigateToCandidateDetail(data) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${data.candidate_uid}` });
    },
    onBodyScroll(e) {
      if (
        this.isFetchingCandidateJobInProcessList ||
        this.getCandidateJobInProcessPagination.noMoreCandidates
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchCandidateJobPageInProcessList({
          job_id: this.jobDetails?.job_id,
          pagination: true,
        });
      }
    },
  },
  mounted() {
    const ele = document.getElementById("inProcess-scrolling");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 80px;
  }
}
.cursor-action {
  cursor: pointer;
}
.candidate-status {
  position: absolute;
  bottom: 1.24em;
  left: 1.4em;
  z-index: 1;
}
.link {
  color: blue;
  font-size: 13px;
  font-weight: 400;
}

a:hover {
  color: #dc0050;
  text-decoration: underline;
}
.scrolling {
  overflow-y: auto;
  max-height: 630px;
}
</style>
