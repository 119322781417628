<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.NonMedicalQual = !$parent.isExpand.NonMedicalQual),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.NonMedicalQual ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          Non Medical Qualification
          <span class="nowrap"
            >[{{ NonMedicalInfo.length }} Record{{
              NonMedicalInfo.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.NonMedicalQual"
      >
        <!--<CButton
          v-if="isCustomerAdmin"
          class="btn-primary small mb-2 mr-2 mt-1"
          style="height: 28px"
          @click="addNonMedicalQualInfo()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >-->
        <CButton
          v-if="NonMedicalInfo.length"
          @click="qual_step -= 1"
          :disabled="qual_step == 0"
        >
          <CIcon name="cil-caret-left" />
        </CButton>
        <CButton
          v-if="paginatedNonMedicalQuals.length"
          @click="qual_step += 1"
          :disabled="qual_step + 1 == paginatedNonMedicalQuals.length"
        >
          <CIcon name="cil-caret-right" />
        </CButton>
      </div>
    </div>
    <div class="ml-3" v-if="$parent.isExpand.NonMedicalQual">
      <div
        v-if="NonMedicalInfo.length"
      >
        <CRow
          v-for="(data, parentIndex) in paginatedNonMedicalQuals"
          :key="parentIndex"
        >
          <CCol lg="12" v-if="parentIndex === qual_step">
            <div v-if="isMobile">
              <div class="d-flex justify-content-between p-2">
                <h6 class="fw-600">
                  Qualification {{parentIndex + 1 }}
                </h6>
                <!--<span v-if="isCustomerAdmin">
                  <a class="hand-icon btn p-0" @click="editNonMedicalQualInfo(data)">
                    <i class="fas fa-edit ml-3"></i>
                  </a>
                  <a class="hand-icon btn p-0" @click="deleteNonMedicalQualInfo(data)">
                    <i class="fas fa-trash ml-3"></i>
                  </a>
                </span>-->
              </div>
              <NonMedicalQualCard :qualification="data" />
            </div>
            <div v-else>
              <CRow>
                <CCol
                  lg="6" 
                  v-for="(qual, subIndex) in data"
                  :key="subIndex"
                >
                <div class="gray-box mx-1">
                  <div class="d-flex justify-content-between p-2">
                    <h6 class="fw-600">
                      Qualification {{ subIndex + parentIndex * 2 + 1 }}
                    </h6>
                    <!--<span v-if="isCustomerAdmin">
                      <a class="hand-icon btn p-0" @click="editNonMedicalQualInfo(qual)">
                        <i class="fas fa-edit ml-3"></i>
                      </a>
                      <a class="hand-icon btn p-0" @click="deleteNonMedicalQualInfo(qual)">
                        <i class="fas fa-trash ml-3"></i>
                      </a>
                    </span>-->
                  </div>
                  <NonMedicalQualCard :qualification="qual" />
                </div>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <p class="text-center text-muted h6">No Qualification Information Found</p>
      </div>
    </div>
    <NonMedicalInfoForm
      :candidateInfo="profile"
      :NonMedicalInfo="NonMedicalModal.data"
      :isShowPopup="NonMedicalModal.isShowPopup"
      :isEdit="NonMedicalModal.isEdit"
      @modalCallBack="nonMedicalInfoModalCallBack"
      v-if="NonMedicalModal.isShowPopup"
    >
    </NonMedicalInfoForm>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteNonMedicalInfoCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import NonMedicalQualCard from "@/containers/CandidateDetailsTalentFind/ProfileSection/NonMedicalInfo/NonMedicalQualCard";
import NonMedicalInfoForm from "@/containers/CandidateDetailsTalentFind/EditForm/NonMedicalInfoForm";
import Modal from "@/components/reusable/Modal";
export default {
  props: {
    NonMedicalInfo: {
      type: Array,
      default: () => [],
    },
    profile: {
      type: Object,
      default: () => null,
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NonMedicalInfoForm,
    NonMedicalQualCard,
    Modal,
  },
  data() {
    return {
      qual_step: 0,
      Role,
      currentUserRole: getScope(),
      NonMedicalModal: {
        data: null,
        isShowPopup: false,
        isEdit: false,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Non Medical Qualification Info",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    ...mapGetters(["getCandidateNonMedicalQuals"]),
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    paginatedNonMedicalQuals() {
      if (!this.isMobile)
        return this.NonMedicalInfo.reduce((a, b, i) => {
          return i % 2 === 0 ? a.concat([this.NonMedicalInfo.slice(i, i + 2)]) : a;
        }, []);
      return this.NonMedicalInfo;
    },
  },
  methods: {
    ...mapActions([
      "deleteNonMedicalInfo",
      "fetchCandidateNonMedicalQuals",
      "uploadCandidateNonMedicalInfo",
      "updateNonMedicalInfo",
      "showToast",
    ]),
    addNonMedicalQualInfo() {
      this.$parent.tooltipHide();
      this.NonMedicalModal.isEdit = false;
      this.NonMedicalModal.data = null;
      this.NonMedicalModal.isShowPopup = true;
    },
    editNonMedicalQualInfo(data) {
      this.NonMedicalModal.isEdit = true;
      this.NonMedicalModal.data = data;
      this.NonMedicalModal.isShowPopup = true;
    },
    nonMedicalInfoModalCallBack(action, qualpayload) {
      if (action === false) this.NonMedicalModal.isShowPopup = false;
      else {
        let appendAction = null;
        if (
          this.NonMedicalModal.data === null &&
          this.NonMedicalModal.isEdit === false
        ) {
          appendAction = this.uploadCandidateNonMedicalInfo(qualpayload);
        } else if (this.NonMedicalModal.isEdit) {
          let candidate_non_medical_qual_id = qualpayload.candidate_non_medical_qual_id;
          let payload = qualpayload.payload
          appendAction = this.updateNonMedicalInfo({ candidate_non_medical_qual_id, payload});
        }
        Promise.all([appendAction]).then((res) => {
          if(res[0]?.response?.data?.detail === "expired") {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Expired !!",
            });
            return;
          }
          if (res) {
            this.NonMedicalModal.isShowPopup = false;
            this.NonMedicalModal.isEdit = false;
            this.NonMedicalModal.data = null;
          }
        });
      }
    },
    deleteNonMedicalQualInfo(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this Qualification - ${data.name !== "--" ? data.name : data.others_name} ?`;
      this.non_medical_qual_id = data.id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteNonMedicalInfoCallBack(action) {
      if (action === "Confirm") {
        this.deleteNonMedicalInfo(this.non_medical_qual_id);
      }
      this.non_medical_qual_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
    qualificationName(data) {
      if (data !== null)
        return data.name !== "--"
          ? data.name
          : `Other${
              data.others_name
                ? " - " + data.others_name
                : ""
            }`;
      return "--";
    },
    qualificationScore(data) {
      if (data !== null)
        return data.score !== "--"
          ? data.score
          : `${
              data.others_score
                ? data.others_score
                : ""
            }`;
      return "--";
    },
  },
};
</script>
