<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Non Medical Qualification {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab :title="`Non Medical Qualification ${isEdit ? 'Edit' : 'Add'}`">
            <div class="p-2">
              <ValidationObserver
                ref="NonMedicalInfoForm"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="NonMedicalInfoForm"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_qualification_name"
                          class="required col-lg-12 col-md-12"
                          >Qualification Name</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="qualification_name"
                              :value="profile.qualification_name"
                              @input="handleChangeSelect"
                              :options="
                                options && options['qualifications']
                                  ? options['qualifications']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol v-if="!other_qualification && !isBusinessOrManQual" md="3">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_score"
                          class="required col-lg-12 col-md-12"
                          > Score / Skill Level </label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="score"
                              :value="profile.score"
                              @input="handleChangeSelect"
                              :options="
                                options && options['score']
                                  ? options['score']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol v-if="other_qualification" md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_qualification_name"
                          class="required col-lg-12 col-md-12"
                          >Other Qualification Name</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="others_name"
                              :value="profile.others_name"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol v-if="isBusinessOrManQual" md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_others_name"
                          class="required col-lg-12 col-md-12"
                          >Qualification Subject</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="others_name"
                              :value="profile.others_name"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="10">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_qual_month_year"
                          class="required col-lg-12 col-md-12"
                        >Qualication Month/year Awarded</label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider name="dob" v-slot="{ errors }">
                            <TextInput
                              hidden
                              name="dob"
                              :value="candidateInfo.dob"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            rules="QualifiedDate:@dob|date_validate|required"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="true"
                              name="qual_month_year"
                              :value="profile.qual_month_year"
                              @change="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
export default {
  mixins: [EditSection],
  props: {
    NonMedicalInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
      NonMedicalQualInfo: {},
      candidate_info_id: null,
      other_qualification: false
    };
  },
  computed: {
    ...mapGetters([
      "scoreFilteredByNonMedicalQualName",
      "getNonMedicalQual",
      "isFetchingFilter",
      "getCandidateNonMedicalQuals"
    ]),
    options() {
      return {
        qualifications: this.getNonMedicalQual || [],
        score: this.scoreFilteredByNonMedicalQualName || [],
      };
    },
    isBusinessOrManQual() {
      return this.profile?.qualification_name?.qual_type_id == 2 ||
        this.profile?.qualification_name?.qual_type_id == 3
        ? true
        : false;
    },
    isFetching() {
      if (!this.isFetchingFilter) {
        if (this.isEdit) {
          let data = this.NonMedicalInfo;
          this.profile = {
            ...this.profile,
            qualification_name: {
              code: data?.qual_type_id ? data.qual_type_id : "Others",
              label: data.name && data.qual_type_name !== "--"
                ? data.qual_type_name + ' - ' + data.name
                : "Others - not listed",
              qual_type_id: data?.qual_type_id ? data?.qual_type_id : null,
              non_medical_qual_id: data?.qual_type_id ? data?.non_medical_qual_id : null,
            },
            score: {
              label: data.score !== "--"
                ? data.score
                : null,
              code: data.non_medical_qual_id
            },
            qual_month_year:
              data.year && data.month != "--"
                ? data.month < 10
                  ? `${data.year}-0${data.month}`
                  : `${data.year}-${data.month}`
                : null,
            others_name: data.others_name !== "--" ? data.others_name : null,
            others_score: data.others_score !== "--" ? data.others_score : null  
          };
          this.candidate_info_id = data.id;
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    getNonMedicalQual() {
      return this.options.qualifications.push({label: "Others - not listed"})
    },
    "profile.qualification_name"() {
      if (this.profile?.qualification_name?.label === "Others - not listed") {
        this.other_qualification = true;
        if (this.profile?.score) this.profile.score = null;
      } else if (
        this.profile?.qualification_name?.label !== "Others - not listed"
      ) {
        this.other_qualification = false;
        if (this.profile?.others_name && !this.isBusinessOrManQual)
          this.profile.others_name = null;
        if (this.profile?.others_score) this.profile.others_score = null;
        let qualification_name;
        this.fetchScoreByNonMedicalQual(
          qualification_name= this.profile?.qualification_name?.label.replace(/\ - /,'&')?.split('&')[1]
        );
      }
    },
  },
  mounted() {
    if(!this.getNonMedicalQual?.length) {
      this.fetchNonMedicalQualNames()
    }
  },
  methods: {
    ...mapActions(["fetchNonMedicalQualNames", "fetchScoreByNonMedicalQual", "initFetchLanguageOptions", "showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.NonMedicalInfoForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if(this.profile?.qualification_name.label !== "Others - not listed") {
        if (this.candidate_info_id !== null) {
          let _count = 0;
          let _index;
          for (var i = 0; i < this.getCandidateNonMedicalQuals.length; i++) {
            if (
              this.getCandidateNonMedicalQuals[i].non_medical_qual?.name ===
              this.profile?.qualification_name?.label?.replace(/\ - /,'&').split('&')[1]
            ) {
              _index = i;
              _count = _count + 1;
            }
          }
          if (
            _count >= 1 &&
            this.getCandidateNonMedicalQuals[_index].id !==
              this.candidate_info_id
          ) {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Already Exist!",
            });
            return;
          }
        } else {
          if (
            this.getCandidateNonMedicalQuals?.length &&
            this.getCandidateNonMedicalQuals.findIndex(
              (e) =>
                e.non_medical_qual?.name === this.profile?.qualification_name?.label?.replace(/\ - /,'&').split('&')[1]
            ) >= 0
          ) {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Already Exist!",
            });
            return;
          }
        }
      }
      if (this.profile?.qualification_name.label === "Others - not listed") {
        if (this.candidate_info_id !== null) {
          let _count = 0;
          let _index;
          for (var i = 0; i < this.getCandidateNonMedicalQuals.length; i++) {
            if (
              this.getCandidateNonMedicalQuals[i].others_name?.toLowerCase() ===
              this.profile?.others_name?.toLowerCase()
            ) {
              _index = i;
              _count = _count + 1;
            }
          }
          if (
            _count >= 1 &&
            this.getCandidateNonMedicalQuals[_index].id !==
              this.candidate_info_id
          ) {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Already Exist!",
            });
            return;
          }
        } else {
          if (
            this.getCandidateNonMedicalQuals?.length &&
            this.getCandidateNonMedicalQuals.findIndex(
              (e) =>
                e.others_name?.toLowerCase() === this.profile?.others_name?.toLowerCase()
            ) >= 0
          ) {
            this.showToast({
              class: "bg-danger text-white",
              message: "Qualification Already Exist!",
            });
            return;
          }
        }
      }
      this.NonMedicalQualInfo = {
        candidate_uid: this.candidateInfo?.candidate_uid
          ? this.candidateInfo?.candidate_uid
          : null,
        non_medical_qual_id: this.profile?.score?.code
          ? this.profile?.score?.code
          : this.isBusinessOrManQual
          ? this.profile?.qualification_name?.non_medical_qual_id
          : null,
        year: this.profile?.qual_month_year !== null
          ? m(this.profile?.qual_month_year).year()
          : null,
        month: this.profile?.qual_month_year !== null
          ? m(this.profile?.qual_month_year).month() + 1
          : null,
        others_name:
          this.profile?.qualification_name?.label == "Others - not listed" ||
          this.isBusinessOrManQual
            ? this.profile?.others_name != ""
              ? this.profile?.others_name
              : null
            : null,
        others_score:
          this.profile?.qualification_name?.label === "Others - not listed"
            ? this.profile?.others_score
            : null,
      };
      if (this.isEdit) {
        let payload = {
          non_medical_qual_id: this.NonMedicalQualInfo.non_medical_qual_id || null,
          candidate_uid: this.candidateInfo?.candidate_uid,
          year: this.NonMedicalQualInfo.year,
          month: this.NonMedicalQualInfo.month,
          others_name: this.NonMedicalQualInfo.others_name,
          others_score: this.NonMedicalQualInfo.others_score
        }
        let candidate_non_medical_qual_id = this.candidate_info_id || null
        this.modalCallBack(true, { candidate_non_medical_qual_id, payload });
      } else {
        this.modalCallBack(true, this.NonMedicalQualInfo);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>