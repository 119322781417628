<template>
  <div>
    <div class="mb-3 mt-3">
      <h6 class="text-primary">Essential</h6>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0">Qualification Region </CCol>
        <CCol col="7" class="text-black">{{ qualificationRegions }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Country Obtained </CCol>
        <CCol col="7" class="text-black">{{ qualificationCountries }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Special Interests </CCol>
        <CCol col="7" class="text-black">{{ specialInterests }} </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0">Minimum Experience </CCol>
        <CCol col="7" class="text-black"> {{ experience }}</CCol>
      </CRow>
    </div>
    <div class="mb-3">
      <h6 class="text-primary">Preferred</h6>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Regional License </CCol>
        <CCol col="7" class="text-black"> {{ regionalLicense }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Gender </CCol>
        <CCol col="7" class="text-black"> {{ gender }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Maximum Age </CCol>
        <CCol col="7" class="text-black"> {{ age }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Nationality </CCol>
        <CCol col="7" class="text-black"> {{ nationality }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Language </CCol>
        <CCol col="7" class="text-black"> {{ languages }}</CCol>
      </CRow>
    </div>
    <div class="mb-3">
      <h6 class="text-primary">Additional Information</h6>
      <p
        :class="
          !jobDetails.job.additional_info ? 'text-center text-muted' : 'ml-0'
        "
      >
        {{
          jobDetails.job.additional_info || "No Additional Information Found"
        }}
      </p>
    </div>
    <div class="mb-1">
      <h6 class="text-primary">Comments</h6>
      <p :class="!jobDetails.job.comments ? 'text-center text-muted' : 'ml-0'">
        {{ jobDetails.job.comments || "No Comments Found" }}
      </p>
    </div>
    <div class="mb-1">
      <h6 class="text-primary">Details and Benefits</h6>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Salary Range</CCol>
        <CCol col="7" class="text-black">{{jobDetails.job.salary_min || "--"}} {{salary_currency}} to {{jobDetails.job.salary_max || "--"}} {{salary_currency}}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Target Salary</CCol>
        <CCol col="7" class="text-black">{{jobDetails.job.target_salary || "--"}} {{target_currency}}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Contract Type </CCol>
        <CCol col="7" class="text-black"> {{ contractType }}</CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol col="5" class="text-gray pr-0"> Benefits </CCol>
        <CCol col="7" class="text-black" v-if="!benefits">--</CCol>
        <CCol class="text-black col-7" v-else v-html="benefits"> </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["jobDetails"],
  computed: {
    ...mapGetters([
      "getCountryNameForSelectedId",
      "getRegionNameForSelectedId",
      "getLanguageForSelectedId",
      "getSpecialInterestForSelectedId",
      "getSelectedJobBenefitsAndAdvertDesc",
      "getCurrencyForSelectedID"
    ]),
    qualificationRegions() {
      let regions = this.getRegionNameForSelectedId(
        this.jobDetails?.job?.qualification_region_ids
      ).map((val) => val.label);
      return regions?.length ? regions.join(", ") : null || "All";
    },
    qualificationCountries() {
      let countries = this.getCountryNameForSelectedId(
        this.jobDetails?.job?.qualification_country_ids
      ).map((val) => val.label);
      return countries?.length ? countries.join(", ") : null || "All";
    },
    specialInterests() {
      const comments = this.getSpecialInterestForSelectedId(
        this.jobDetails?.job?.specialities[0]?.special_interest_ids
      ).map((specialInterests) => specialInterests.label);
      return comments?.length ? comments.join(", ") : null || "--";
    },
    experience() {
      return this.jobDetails?.job?.min_yrs_of_exp || "--";
    },
    regionalLicense() {
      return this.jobDetails?.job?.regional_license
        ? this.jobDetails?.job?.regional_license !== "n/a"
          ? this.jobDetails?.job?.regional_license
          : "--"
        : "--";
    },
    gender() {
      return this.jobDetails?.job?.gender?.gender || "All";
    },
    age() {
      return this.jobDetails?.job?.maximum_age || "--";
    },
    nationality() {
      const comments = this.jobDetails?.job?.countries_included?.map(
        (nationality) => nationality?.country?.country_name
      );
      const nationality = comments.length ? comments.join(", ") : null;
      return nationality || "All";
    },
    languages() {
      const language_ids = this.jobDetails?.job?.language_ids;
      const comments = this.getLanguageForSelectedId(language_ids).map(
        (qualification) => qualification.label
      );
      return comments?.length ? comments.join(", ") : null || "--";
    },
    contractType() {
      let job = this.jobDetails?.job;
      let returnData = "";
      job.full_time != "no"
        ? job.full_time == "flexible"
          ? (returnData += "Full Time (Fexible); ")
          : (returnData += "Full Time; ")
        : null;
      job.part_time != "no"
        ? job.part_time == "flexible"
          ? (returnData += "Part Time (Fexible); ")
          : (returnData += "Part Time; ")
        : null;
      if (job.permanent_work) returnData += "Permanent job; ";
      if (job.temporary_work) returnData += "Temporary job; ";
      this.getSelectedJobBenefitsAndAdvertDesc?.job_details?.forEach((val) => {
        returnData += `${
          val.job_detail_type == "Working Week"
            ? "Working Days"
            : val.job_detail_type
        }(${val.detail_number}); `;
      });
      return returnData || "--";
    },
    benefits() {
      let stdBenefits = [];
      let addBenefit = [];
      this.getSelectedJobBenefitsAndAdvertDesc?.benefits?.forEach((item) => {
        item?.benefit_types == "standard_benefits"
          ? stdBenefits.push(item)
          : addBenefit.push(item);
      });
      let benefits = stdBenefits.concat(addBenefit);
      benefits = benefits.map((val) => ({
        comments: val.comments,
        description: val.description,
      }));
      if (benefits?.length) {
        let list = "";
        benefits.forEach((item) => {
          list += `<li>${item.description}${
            item.comments ? ` -${item.comments}` : ""
          }</li>`;
        });
        return list;
      }
      return false;
    },
    target_currency(){
      return this.getCurrencyForSelectedID(this.jobDetails?.job?.target_salary_currency_id).map((val)=>val.label)[0];
    },
    salary_currency(){
      return this.getCurrencyForSelectedID(this.jobDetails?.job?.salary_currency_id).map((val)=>val.label)[0];
    },
  },
};
</script>
