<template>
  <div class="action-modal">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Actions</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <div class="text-primary mb-2 font-weight-bold">
          Status:&nbsp;&nbsp;&nbsp;&nbsp;{{ status
          }}{{ sub_status ? `(${sub_status})` : null }}
        </div>
        <ValidationObserver ref="candidateStatus" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="candidateStatus">
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Action</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="action_id"
                        @input="handleChangeSelect"
                        :value="profile.action_id"
                        :options="[]"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>

              <CCol lg="6">
                <CRow class="mb-3" v-if="options['sub_status'].length">
                  <label class="required col-lg-12">Sub Status</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="sub_status_id"
                        @input="handleChangeSelect"
                        :value="profile.sub_status_id"
                        :options="
                          options && options['sub_status']
                            ? options['sub_status']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="options['reason_code'].length">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Reason Code</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="reason_id"
                        @input="handleChangeSelect"
                        :value="profile.reason_id"
                        :options="
                          options && options['reason_code']
                            ? options['reason_code']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12" :class="{ required: isOtherReason }"
                    >Comments</label
                  >
                  <div class="col-lg-12">
                    <ValidationProvider
                      :rules="{ required: isOtherReason }"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="comments"
                        :value="profile.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      ></TextInput>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div v-if="isSubmissionAction">
              <h6 class="text-primary border-top pt-3">Alert</h6>
              <CRow>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Alert Set For</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <DatePicker
                          name="expiry_date"
                          :value="profile.expiry_date"
                          @input="handleDatePickerChange"
                          :error="errors[0]"
                          :showFromToday="true"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Priority Level</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="priority_level"
                          @input="handleChangeSelect"
                          :value="profile.priority_level"
                          :options="
                            options && options['priority_level']
                              ? options['priority_level']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: null,
    },
    action: {
      type: Object,
      default: null,
    },
  },
  components: {
    Select,
    TextInput,
    DatePicker,
  },
  data() {
    return {
      profile: {},
      payload: {},
      sub_filter_list: { 57: 20, 60: 22 },
    };
  },
  computed: {
    ...mapGetters(["getTabReasons", "getSubStatusOptions", "isFetchingFilter"]),
    options() {
      return {
        sub_status: this.getSubStatusOptions || [],
        reason_code: this.getTabReasons || [],
        priority_level: [
          { code: 1, label: 1 },
          { code: 2, label: 2 },
          { code: 3, label: 3 },
          { code: 4, label: 4 },
        ],
      };
    },
    isOtherReason() {
      return this.profile?.reason_id?.code === 100 || false;
    },
    sub_filter_id() {
      return this.sub_status_id && [57, 60].includes(this.sub_status_id)
        ? this.sub_filter_list[this.sub_status_id]
        : this.sub_status_id;
    },
    status() {
      return this.details?.status?.display_text;
    },
    sub_status() {
      return this.details?.sub_status?.display_text;
    },
    isSubmissionAction() {
      return (
        (this.action?.action_id === 14 &&
          this.$route?.name === "Job List Preview") ||
        false
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchStatusOptions",
      "fetchSubStatusOptions",
      "fetchJobCandidateReasonCode",
    ]),
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (["sub_status_id"].includes(name)) {
        const { action_id, label, filter_id, tab_id } = this.action;
        this.fetchJobCandidateReasonCode({
          tab_id,
          filter_id,
          action_id,
          sub_status_id: this.payload["sub_status_id"],
        }).then((res) => {
          if (this.getTabReasons.length === 1)
            this.handleChangeSelect("reason_id", this.getTabReasons[0]);
        });
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    async onSubmit() {
      const isValid = await this.$refs.candidateStatus.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const { action_id, filter_id, tab_id } = this.action;
      const {
        status_id: current_status,
        sub_status_id: current_sub_status,
      } = this.details;
      this.payload = {
        ...this.payload,
        action_id,
        filter_id,
        tab_id,
        current_status,
        current_sub_status,
      };
      this.modalCallBack(true, this.payload);
    },
    resetObject(obj) {
      for (const key in obj) {
        this.profile[obj] = null;
        this.payload[obj] = null;
      }
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(
        this.payload,
        name,
        value ? m(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
  },
  mounted() {
    const { action_id, label, filter_id, tab_id } = this.action;
    this.profile["action_id"] = { label, code: action_id };
    if (this.isSubmissionAction) {
      let alertDate = m().add(5, "d").toDate();
      this.handleDatePickerChange("expiry_date", alertDate);
      this.handleChangeSelect("priority_level", { code: 1, label: 1 });
    }
    this.fetchSubStatusOptions({
      tab_id,
      filter_id,
      action_id,
    }).then((res) => {
      if (this.getSubStatusOptions.length === 1)
        this.handleChangeSelect("sub_status_id", this.getSubStatusOptions[0]);
    });
    this.fetchJobCandidateReasonCode({ tab_id, filter_id, action_id });
  },
};
</script>