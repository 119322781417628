import { mapGetters } from "vuex";
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker
  },
  data() {
    return {
      stepperConfirm: false,
    }
  },

  computed: {
    ...mapGetters([
      // Personal Info

      "organizationId",
      "getRoleScopes",
      "candidateLevelFilterByType",
      "getCustomerId",
      "hospitalNames",

      "titles",
      "gender",
      "locations",
      "insideUAELocation",
      //"locationCountryInfo",
      "locationLanguage",
      "languageProficiency",

      // Professional Info
      "candidateType",
      "specialityFilterByType",
      "subSpecialityFilterBySpeciality",
      "candidateLevelFilterByType",
      "specialInterest",
      "issueAuthority",
      "skill",
      "benefits",
      "getQualification",
    ]),

  },
  methods: {
    handleInput(name, value) {
      Vue.set(this.jobs, name, value);
      Vue.set(this.payload, name, value);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.jobs, name, value);
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM-DD") : null);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.jobs, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null
      };
      if (name === "organisation") {
        this.organisationName = value
          ? `<h1> ${value.label} </h1>`
          : "";
      }
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.jobs, name, value);
      this.payload = {
        ...this.payload,
        [name]: val
      };
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.jobs, name, value);
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
  },
}
