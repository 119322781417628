<template>
  <div v-if="isJobDetails">
    <CRow v-if="jobStatus" class="bottom-line mb-4">
      <CCol col="8" class="text-primary font-weight-bold">
        <CRow class="pb-2">
          <CCol col="12">
            <div class="d-flex">
              <span class="mr-2">Status:</span>
              <span>
                <span>{{ jobStatus }} </span>
                <span class="text-nowrap" v-if="jobSubStatus">{{
                  `(${jobSubStatus})`
                }}</span>
              </span>
            </div>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="4">
        <div class="d-flex justify-content-end" v-if="index_selectedJob > -1">
          <CButton
            class="pt-0 pb-0"
            @click="getJobDetails(previousJob)"
            :disabled="!previousJob"
          >
            <CIcon name="cil-caret-left" class="text-primary" />
          </CButton>
          <CButton
            class="pt-0 pb-0"
            @click="getJobDetails(nextJob)"
            :disabled="!nextJob"
          >
            <CIcon name="cil-caret-right" class="text-primary" />
          </CButton>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="4" class="pr-0">
        <div class="job-img text-center m-auto pb-1">
          <img :src="logo" alt />
        </div>
        <div class="text-center">
          <span class="text-black fw-600">{{ organisation }}</span>
          <span class="text-muted d-block">
            <span class="icon-location"></span>
            {{ hospitalLocation }}
          </span>
          <div class="mt-1">
            <CButton
              color="primary"
              class="text-center px-2 f-12"
              @click="navigateToJobDetail"
              >View Job</CButton
            >
            <span class="ml-2"
              ><i
                v-if="isFeatured"
                v-c-tooltip="'Featured Job'"
                style="color: #ff7600; font-size: 11px"
                class="fas fa-star"
              ></i
            ></span>
          </div>
          <div
            class="d-block d-lg-none"
            v-if="currentUserRole === Role.customerAdmin"
          >
            <div class="m-auto action-set">
              <CDropdown
                placement="left"
                class="text-nowrap text-primary m-1 drop-padding"
              >
                <template #toggler>
                  <div class="action-1 font-weight-bold pr-5">
                    <i class="fas fa-ellipsis-v circle-border mr-1"></i>
                    Action
                  </div>
                </template>
                <a
                  class="dropdown-item cursor-action"
                  v-for="data in jobActions"
                  :key="data.action_id"
                  @click="jobActionEvent(data)"
                >
                  {{ data.label }}
                </a>
              </CDropdown>
            </div>
          </div>
        </div>
      </CCol>
      <CCol lg="8" class="position-relative">
        <h6 class="text-primary">
          {{ jobTitle }} | Job ID: <span>{{ referenceId }}</span>
        </h6>
        <CRow class="mb-1">
          <CCol col="5" class="text-gray pr-0">Level</CCol>
          <CCol col="7" class="text-black">{{ level }}</CCol>
        </CRow>
        <CRow class="mb-1">
          <CCol col="5" class="text-gray pr-0">Speciality</CCol>
          <CCol col="7" class="text-black">{{ speciality }}</CCol>
        </CRow>
        <CRow class="mb-1">
          <CCol col="5" class="text-gray pr-0">Sub Speciality</CCol>
          <CCol col="7" class="text-black">{{ sub_speciality }}</CCol>
        </CRow>
        <CRow class="mb-1">
          <CCol col="5" class="text-gray pr-0">Status</CCol>
          <CCol col="7" class="text-black">{{ publishStatus }}</CCol>
        </CRow>
        <CRow class="mb-1">
          <CCol col="5" class="text-gray pr-0">Days to Expire</CCol>
          <CCol col="7" class="text-black">{{
            jobDetails.expiry_review_date | expiryDate
          }}</CCol>
        </CRow>
        <div
          class="d-none d-lg-block"
          v-if="currentUserRole === Role.customerAdmin"
        >
          <div class="d-flex action-set">
            <CDropdown
              placement="left"
              class="text-nowrap text-primary mx-0 drop-padding"
            >
              <template #toggler>
                <div class="action-1 font-weight-bold">
                  <i class="fas fa-ellipsis-v circle-border mr-1"></i>
                  Action
                </div>
              </template>
              <a
                class="dropdown-item cursor-action"
                v-for="data in jobActions"
                :key="data.action_id"
                @click="jobActionEvent(data)"
              >
                {{ data.label }}
              </a>
            </CDropdown>
          </div>
        </div>
      </CCol>
    </CRow>
    <div class="scroll-arrow mt-3">
      <a class="arrow left clickable" @click="arrowClick('left')">
        <CIcon name="cil-caret-left" />
      </a>
      <a class="arrow right clickable" @click="arrowClick('right')">
        <CIcon name="cil-caret-right" />
      </a>
      <div class="scroll-wrapper">
        <ul class="nav nav-tabs px-2" id="jobTab">
          <li
            role="presentation"
            v-for="(data, index) in jobTabs"
            :key="index"
            v-bind:class="{ active: job_step === index }"
          >
            <a class="clickable btn" @click="setJobStep(index)">{{ data }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="job-content mt-2">
      <div v-if="job_step == 0">
        <details-section :jobDetails="jobDetails" />
      </div>
      <div v-if="job_step == 1">
        <in-process-section
          :jobDetails="jobDetails"
          :candidateJobPageInProcessList="getCandidateJobPageInProcessList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { isObject, isEmptyObjectCheck, Role, getScope } from "@/helpers/helper";
import DetailsSection from "./JobDetailsSection/DetailsSection.vue";
import { mapActions, mapGetters } from "vuex";
import InProcessSection from "./JobDetailsSection/InProcessSection.vue";

export default {
  props: ["jobDetails", "getJobDetails", "actionModalEvent"],
  components: {
    DetailsSection,
    InProcessSection,
  },
  data() {
    return {
      jobTabs: ["Details", "In Process", "Alerts"],
      job_step: 0,
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters([
      "getJobListForCandidateID",
      "getCandidateJobPageInProcessList",
    ]),
    jobInfo() {
      return this.jobDetails?.job;
    },
    isJobDetails() {
      return isObject(this.jobInfo) && !isEmptyObjectCheck(this.jobInfo);
    },
    logo() {
      return this.jobInfo?.logo_url
        ? `https://${window.location.host}${this.jobInfo?.logo_url}`
        : "/img/hospital.png";
    },
    organisation() {
      return this.jobInfo?.organisation?.name || null;
    },
    referenceId() {
      return `${this.jobInfo?.job_id}` || "--";
    },
    jobTitle() {
      return this.jobInfo?.job_title || "--";
    },
    level() {
      return this.jobInfo?.level_names?.join(", ") || "--";
    },
    candiadteType() {
      return this.jobInfo?.candidate_type?.candidate_type || "--";
    },
    speciality() {
      return this.jobInfo?.specialities?.length
        ? this.jobInfo?.specialities[0]?.speciality?.speciality
        : "--";
    },
    sub_speciality() {
      return this.jobInfo?.specialities?.length
        ? this.jobInfo?.specialities[0]?.sub_speciality?.sub_speciality
        : "--";
    },
    candidateLocation() {
      return this.jobInfo?.location?.location || "--";
    },
    hospitalLocation() {
      return this.jobInfo?.organisation?.location?.location || "--";
    },
    isFeatured() {
      return this.jobInfo?.featured || false;
    },
    publishStatus() {
      return this.jobInfo.published ? "Published" : "Drafted";
    },
    jobStatus() {
      return this.jobDetails?.status?.display_text || null;
    },
    jobSubStatus() {
      return this.jobDetails?.sub_status?.display_text || null;
    },
    index_selectedJob() {
      let selectedJob_id = this.jobDetails?.job?.job_id;
      return this.getJobListForCandidateID.findIndex(
        ({ job }) => job?.job_id === selectedJob_id
      );
    },
    previousJob() {
      if (this.jobDetails && this.index_selectedJob) {
        if (
          this.getJobListForCandidateID &&
          this.getJobListForCandidateID.length
        ) {
          if (this.index_selectedJob > 0)
            return this.getJobListForCandidateID[this.index_selectedJob - 1];
        }
      }
      return null;
    },
    nextJob() {
      if (this.jobDetails && this.index_selectedJob > -1) {
        if (
          this.getJobListForCandidateID &&
          this.getJobListForCandidateID.length
        ) {
          if (
            this.index_selectedJob > -1 &&
            this.index_selectedJob < this.getJobListForCandidateID.length - 1
          )
            return this.getJobListForCandidateID[this.index_selectedJob + 1];
        }
      }
      return null;
    },
    jobActions() {
      return (
        (this.jobDetails &&
          this.jobDetails.actions &&
          this.jobDetails.actions.map((val) => {
            return {
              label: val?.name,
              action_id: val?.id,
              directAction: val?.direct_action,
              status: val?.status,
              filter_id: val?.filter,
              tab_id: val?.tab,
              reason_id: val?.reason,
            };
          })) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidateJobPageInProcessStatus",
      "fetchCandidateJobPageInProcessList",
    ]),
    navigateToJobDetail() {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push(`/job-list-preview/${this.jobInfo.job_uuid}`);
    },
    arrowClick(direction) {
      let e = document.querySelector("#jobTab");
      if (e != null)
        if (direction === "left") {
          e.scrollLeft -= 150;
        } else if (direction === "right") {
          e.scrollLeft += 150;
        }
    },
    jobActionEvent(action) {
      const jobDetails = this.jobDetails;
      let payload = { jobDetails, selectedAction: action };
      this.actionModalEvent(payload);
      this.hideDropDown();
    },
    hideDropDown() {
      const cols = document.getElementsByClassName("dropdown-menu");
      cols.forEach((element) => {
        element.classList.remove("show");
      });
    },
    setJobStep(step) {
      this.job_step = step;
      if (step == 1)
        this.fetchCandidateJobPageInProcessStatus().then((res) => {
          this.fetchCandidateJobPageInProcessList({
            job_id: this.jobDetails?.job?.job_id,
            pagination: false,
          });
        });
    },
  },
  filters: {
    moment(date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
    expiryDate(date) {
      if (date !== null) {
        let expiration = moment(date);
        let current_date = moment();
        let expirydate = expiration.diff(current_date, "days");
        if (expirydate < 0) {
          return "Already Expired";
        } else {
          return expirydate;
        }
      }
      return "0";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-1,
.action-2 {
  width: 75px;
  height: 24px;
  font-size: 11px;
  margin: auto;
}
.action-set {
  margin-right: 0;
  margin-left: auto;
  @media all and (max-width: 480px) {
    float: none;
    margin: auto;
  }
}
.drop-padding {
  padding: 0.25rem 0;
  cursor: pointer;
}
.circle-border {
  border: 1px solid #dd3651;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}
</style>
