<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Qualification Information {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab :title="`Qualification Information ${isEdit ? 'Edit' : 'Add'}`">
            <div class="p-2">
              <ValidationObserver
                ref="editQualificationInfo"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editQualificationInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_country"
                          class="required col-lg-12 col-md-12"
                          >Qualification Obtained In</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="country_id"
                              :value="profile.country_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['country']
                                  ? options['country']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_main_qualification_id"
                          class="required col-lg-12 col-md-12"
                          >Qualification</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="main_qualification_id"
                              :value="profile.main_qualification_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['main_qualification_id']
                                  ? options['main_qualification_id']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow
                    v-if="mainQualificationisOther"
                  >
                    <CCol lg="6"> </CCol>
                    <CCol lg="6">
                      <CRow name="other_qualification_name" class="row mb-3">
                        <label class="required col-lg-12 col-md-12"
                          >Other Qualification</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="other_qualification_name"
                              :value="profile.other_qualification_name"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow
                    v-if="
                      profile.main_qualification_id && profile.main_qualification_id.by_exam
                        ? profile.main_qualification_id.by_exam
                        : false
                    "
                  >
                    <CCol md="6"></CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_by_exam"
                          class="required col-lg-12 col-md-12"
                          >Is This By Exam?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <RadioButton
                              name="by_exam"
                              :value="profile.by_exam"
                              :options="
                                options && options['byExam']
                                  ? options['byExam']
                                  : []
                              "
                              @change="handleChangeRadio"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol
                      md="6"
                      v-if="
                        profile.main_qualification_id && profile.main_qualification_id.allow_subject_input &&
                          profile.main_qualification_id.allow_subject_input ===
                            true
                      "
                    >
                      <CRow name="qualification_subject_name" class="mb-3">
                        <label class="col-lg-12 col-md-12"
                          >Qualification Subject</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="description"
                            :value="profile.description"
                            @input="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_main_qualification_year"
                          class="required col-lg-12 col-md-12"
                          >Qualification Month / Year Awarded</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider name="dob" v-slot="{ errors }">
                            <TextInput
                              hidden
                              name="dob"
                              :value="candidateInfo.dob"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            rules="QualifiedDate:@dob|date_validate|required"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="true"
                              name="qualified_date"
                              :value="profile.qualified_date"
                              @change="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow
                    v-if="
                      profile.issued_by && profile.issued_by.code == 'Others'
                    "
                  >
                    <CCol md="6">
                      <CRow name="other_issue_authority" class="row mb-3">
                        <label class="required col-lg-12 col-md-12"
                          >Other Issued Authority</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="other_issue_authority"
                              :value="profile.other_issue_authority"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="p-1">
                        <h6 class="mb-2">
                          Select the Document to be mapped with the
                          Qualification :
                        </h6>
                        <div
                          class="mb-1"
                          style="font-weight: 600; background-color: lightgrey; height:50px;"
                          v-if="filteredDocuments.length"
                        >
                          <CRow class="ml-2">
                            <div class="doc-table col col-1"></div>
                            <div class="doc-table col col-3">
                              <p class="head text-danger">Document Type</p>
                            </div>
                            <div class="doc-table col col-4">
                              <p class="head text-danger">Document Name</p>
                            </div>
                            <div class="doc-table col col-3">
                              <p class="head text-danger">Uploaded Date</p>
                            </div>
                          </CRow>
                        </div>
                        <div
                          v-for="i in filteredDocuments"
                          :key="i.document_id"
                          style="background-color: whitesmoke"
                          class="mt-1 border-bottom"
                          :style="
                            `${
                              profile.document_ids.includes(i.document_id)
                                ? 'background-color: #D0F0C0'
                                : 'background-color: whitesmoke'
                            }`
                          "
                        >
                          <CRow class="ml-2">
                            <div class="doc-table col col-1">
                              <p class="head mt-1">
                                <input
                                  type="checkbox"
                                  :style="'cursor:pointer'"
                                  :value="i.document_id"
                                  v-model="profile.document_ids"
                                />
                              </p>
                            </div>
                            <div class="doc-table col col-3">
                              <p class="data mt-1">{{ i.label }}</p>
                            </div>
                            <div class="doc-table col col-4">
                              <p class="data mt-1">{{ i.name }}</p>
                            </div>
                            <div class="doc-table col col-3">
                              <p class="data mt-1">{{ i.updatedDate }}</p>
                            </div>
                          </CRow>
                        </div>
                        <div
                          v-if="!filteredDocuments.length"
                          :style=" isMobile ? 'background-color: whitesmoke; height: 65px' : 'background-color: whitesmoke; height: 40px'"
                        >
                          <h6 class="text-center mt-2 p-2" :style=" isMobile ? 'font-size: 13px' : 'font-size: 15px'">
                            No document found - you may upload and link this document in Document Information section
                          </h6>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import m from "moment";
import Vue from "vue";
const debounce = require("lodash.debounce");

extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});

export default {
  mixins: [EditSection],
  props: {
    qualificationInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
      isEdit: false,
      isFetchingOptions: true,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters([
      "qualificationByCountryAndCandidateType",
      "issueAuthorityByQualification",
      "locationCountryInfo",
      "getUploadedDocument",
    ]),
    mainQualificationisOther() {
      return (
        this.profile?.main_qualification_id.label.includes(
          'Other - not listed'
        ) || false
      );
    },
    isFetching() {
      if (!this.isFetchingFilter && !this.isFetchingOptions) {
        if (
          isObject(this.qualificationInfo) &&
          !isEmptyObjectCheck(this.qualificationInfo)
        ) {
          let data = this.qualificationInfo;
          this.profile = {
            ...data,
            main_qualification_id: {
              code: data?.qualification_id,
              label: data?.qualification_with_acronym,
              by_exam: data?.qualification.by_exam,
              allow_subject_input: data?.qualification?.allow_subject_input,
            },
            country_id: {
              code: data?.country_id,
              label: data?.country_name,
            },
            qualified_date:
              data?.year_awarded && data?.month_awarded
                ? data?.month_awarded < 10
                  ? `${data?.year_awarded}-0${data?.month_awarded}`
                  : `${data?.year_awarded}-${data?.month_awarded}`
                : null,
            issued_by: {
              code: data?.issue_authority_id
                ? data?.issue_authority_id
                : "Others",
              label: data?.issue_authority_name
                ? data?.issue_authority_name
                : "Others - not listed",
            },
            other_issue_authority: data?.other_issue_authority
              ? data?.other_issue_authority
              : null,
            course_duration: data?.course_duration,
            distance_learning: data?.distance_learning,
            by_exam: data?.by_exam,
            document_ids: data?.document_ids ? data?.document_ids : [],
            documents: this.documents,
          };
          this.isEdit = true;
        } else {
          this.profile = { document_ids: [] };
        }
        return true;
      }
      this.isEdit = false;
      return false;
    },
    isShowOtherAuthority() {
      if (this.profile.issued_by) {
        return this.profile.issued_by.code == "Others" ? true : false;
      }
      return false;
    },
    options() {
      return {
        main_qualification_id:
          this.qualificationByCountryAndCandidateType || [],
        issued_by:
          this.issueAuthorityByQualification({
            country_id: this.profile?.country_id?.code,
            qualification_id: this.profile?.main_qualification_id?.code,
          }) || [],
        distance_learning: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
          { id: null, label: "N/A" },
        ],
        country: this.locationCountryInfo || [],
        byExam: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    documents() {
      return (
        this.getUploadedDocument?.length &&
        this.getUploadedDocument
          .filter((val) => val.document_type_id == 12)
          .map(
            ({
              document_name,
              document_type,
              created_on,
              candidate_document_uuid,
              candidate_qualification_id,
              candidate_qualification_uuid,
            }) => ({
              label: document_type.comments,
              name: document_name,
              updatedDate: m(created_on).format("DD-MM-YYYY"),
              document_id: candidate_document_uuid,
              candidate_qualification_id: candidate_qualification_id,
              candidate_qualification_uuid: candidate_qualification_uuid,
            })
          )
      );
    },
    filteredDocuments() {
      if (this.documents?.length) {
        if (this.qualificationInfo) {
          return this.documents?.filter(
            (val) =>
              val.candidate_qualification_id ==
                this.qualificationInfo.candidate_qualification_id ||
              val.candidate_qualification_id == null
          );
        }
        return this.documents?.filter(
          (val) => val.candidate_qualification_id == null
        );
      }
      return [];
    },
  },
  watch: {
    "profile.country_id"() {
      if (!this.isFetchingOptions) {
        this.fetchQualificationByCountryAndCandidateType({
          country_id: this.profile?.country_id?.code,
          candidate_type_id: this.candidateInfo?.candidate_type_id,
        });
      }
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "initFetchQualificationInfoOptions",
      "fetchIssueAuthorityByQualification",
      "fetchQualificationByCountryAndCandidateType",
    ]),
    async onSubmit() {
      const isValid = await this.$refs.editQualificationInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      } else if (
        this.profile.main_qualification_id.course_duration_reqd &&
        !this.profile.course_duration
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      }
      let QualificationInfoPayload = {
        country_id:
          this.profile.country_id !== null
            ? this.profile.country_id.code
            : null,
        qualification_id:
          this.profile.main_qualification_id !== null
            ? this.profile.main_qualification_id.code
            : null,
        year_awarded:
          this.profile.qualified_date !== null
            ? m(this.profile.qualified_date).year()
            : null,
        month_awarded:
          this.profile.qualified_date !== null
            ? m(this.profile.qualified_date).month() + 1
            : null,
        speciality_id: this.candidateInfo.speciality_id || null,
        sub_speciality_id: this.candidateInfo.sub_speciality_id || null,
        by_exam: this.profile.by_exam !== null ? this.profile.by_exam : null,
        other_qualification_name:
          this.mainQualificationisOther
            ? this.profile.other_qualification_name
            : null,
        description:
          this.profile.description !== null &&
          this.profile.main_qualification_id.allow_subject_input
            ? this.profile.description
            : null,
        document_ids: this.profile.document_ids?.length
          ? this.profile.document_ids
          : null,
      };
      this.modalCallBack(true, QualificationInfoPayload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    handleSlider: debounce(function(value) {
      Vue.set(this.profile, "course_duration", value);
      Vue.set(this.payload, "course_duration", value);
    }, 500),
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
    this.initFetchQualificationInfoOptions().then((res) => {
      this.isFetchingOptions = false;
    });
    this.fetchQualificationByCountryAndCandidateType();
    if (this.qualificationInfo)
      this.fetchIssueAuthorityByQualification({
        qualification_id: this.qualificationInfo.qualification_id,
      });
  },
};
</script>
