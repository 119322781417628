<template>
  <div>
    <div v-if="isFetching">
      <CRow class="py-2 pl-2 profileView">
        <CCol col="4" class="text-gray">Qualification</CCol>
        <CCol col="8" class="text-black">{{ qualificationName || "--" }}</CCol>
      </CRow>
      <!--<CRow class="py-2 pl-2">
        <CCol col="6" class="text-gray">{{ isNurse ? 'Main Speciality' : 'Speciality' }}</CCol>
        <CCol col="6" class="text-black">{{ speciality }}</CCol>
      </CRow>
      <CRow v-if="isAHP" class="py-2 pl-2">
        <CCol col="6" class="text-gray">Sub Type / Licensed As</CCol>
        <CCol col="6" class="text-black">{{ subType }}</CCol>
      </CRow>
       <CRow v-if="isNurse" class="py-2 pl-2">
        <CCol col="6" class="text-gray">Other Expertise</CCol>
        <CCol col="6" class="text-black">{{ otherExpertise }}</CCol>
      </CRow>-->
      <CRow class="py-2 pl-2 profileView">
        <CCol col="4" class="text-gray">Country</CCol>
        <CCol col="8" class="text-black">{{
          qualification.country_name || "--"
        }}</CCol>
      </CRow>
      <CRow v-if="qualification.qualification.allow_subject_input && qualification.description" class="profileView py-2 pl-2">
        <CCol col="4" class="text-gray">Subject</CCol>
        <CCol col="8" class="text-black">{{
          qualification.description || "--"
        }}</CCol>
      </CRow>
      <CRow class="py-2 pl-2 profileView">
        <CCol col="4" class="text-gray">Month / Year Obtained</CCol>
        <CCol col="8" class="text-black">{{ qualification | monthYear }}</CCol>
      </CRow>
      <CRow v-if="qualification.by_exam" class="py-2 pl-2 profileView">
        <CCol col="4" class="text-gray">Is this by exam</CCol>
        <CCol col="8" class="text-black">{{
          qualification.by_exam ? "Yes" : "No"
        }}</CCol>
      </CRow>
      <CRow v-if="qualification.distance_learning" class="py-2 pl-2 profileView">
        <CCol col="4" class="text-gray">Distance Learning</CCol>
        <CCol col="8" class="text-black">{{
          qualification.distance_learning ? "Yes" : "No"
        }}</CCol>
      </CRow>
    </div>
    <preview-modal
      modalTitle="Document Preview"
      modalColor="primary"
      :buttons="['Ok']"
      size="lg"
      :isShowPopup="previewModal.isShowPopup"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import pdf from "vue-pdf";
import moment from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import PreviewModal from "../../../../components/reusable/PreviewModal.vue";
import { mapActions } from "vuex";
export default {
  components: { PreviewModal },
  props: {
    qualification: {
      type: Object,
      default: () => {},
    },
    mainSpeciality: {
      type: Array,
      default: () => [],
    },
    profile: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    previewModal: {
      isShowPopup: false,
      isPreview: false,
    },
    iframe: {
      src: "",
      loaded: false,
      isImg: false,
      pdf: {
        ispdf: false,
        src: "",
        numPages: undefined,
        addContentClasses: "",
      },
    },
  }),
  computed: {
    isFetching() {
      return (
        isObject(this.qualification) && !isEmptyObjectCheck(this.qualification)
      );
    },
    qualificationName() {
      return this.qualification?.qualification_name?.includes(
        "Other - not listed"
      )
        ? `Other${
            this.qualification.other_qualification_name
              ? " - " + this.qualification.other_qualification_name
              : ""
          }`
        : this.qualification.qualification_with_acronym;
    },
    speciality() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ speciality: { speciality } }) => speciality)
            .join()) ||
        "--"
      );
    },
    subType() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ sub_speciality }) => sub_speciality?.sub_speciality)
            .join()) ||
        "--"
      );
    },
    otherExpertise() {
      return _(this.profile?.candidate_specialities)
        .filter((speciality) => {
          return speciality.main != true && !speciality.other_special_interest && !speciality.special_interest
        })
        .map((speciality) => {
          return speciality.speciality
            ? speciality.speciality?.speciality
            : speciality?.other_speciality
        })
        .value()
        .join(', ') || "--"
    },

    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    }
  },
  filters: {
    monthYear: function (data) {
      let month_year = "";
      if (data.month_awarded)
        month_year = moment.monthsShort()[data.month_awarded - 1];
      if (data.year_awarded) month_year = `${month_year} ${data.year_awarded}`;
      return month_year || "--";
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    preview(data) {
      let document_ext = data.document_ext?.document_ext;
      if (
        document_ext === "PNG" ||
        document_ext === "JPG" ||
        document_ext === "JPEG" ||
        document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link_to_doc;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        document_ext === "TXT" ||
        document_ext === "EPS" ||
        document_ext === "TIF" ||
        document_ext === "TIFF" ||
        document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
          window.location.host +
          data.link_to_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
  },
};
</script>
