<template>
  <div class="splInterest-info-section">
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.specialInterest = !$parent.isExpand
            .specialInterest),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.specialInterest
                ? 'fa-angle-down'
                : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          {{ isAHP ? `Other Areas of Expertise` : `Special Interest` }}
          <span class="nowrap"
            >[{{ specialInterests.length }} Record{{
              specialInterests.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.specialInterest"
      >
        <!--<CButton
          v-if="isCustomerAdmin"
          class="btn-primary small mr-1 mt-1"
          style="height: 28px"
          @click="addSplInterest()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >-->
      </div>
    </div>
    <div v-if="$parent.isExpand.specialInterest">
      <div
        v-if="specialInterests.length"
        class="d-flex align-items-center documentScrolling document-table border"
      >
        <table class="table">
          <thead>
            <tr>
              <th :class="isMobile ? 'nowrap pl-2' : 'nowrap'">
                {{ isAHP ? `Other Areas of Expertise` : `Special Interest` }}
              </th>
              <!--<th v-if="isCustomerAdmin" :class="isAHP ? 'nowrap px-0' : 'nowrap'">Action</th>-->
            </tr>
          </thead>
          <tbody class="text-black">
            <tr v-for="(data, index) in specialInterests" :key="index">
              <td :class="isMobile ? 'nowrap pl-2' : 'nowrap'">
                {{
                  ["Other", "Other - not listed"].includes(
                    data.special_interest_name
                  )
                    ? data.other_special_interest
                    : data.special_interest_name
                }}
              </td>
              <!--<td v-if="isCustomerAdmin" :class="isMobile ? 'nowrap px-0' : 'nowrap'">
                <a
                  class="hand-icon btn pr-2 p-0"
                  @click="editSplInterest(data)"
                >
                  <i class="fas fa-edit"></i> </a
                >&nbsp; | &nbsp;
                <a
                  class="hand-icon btn pl-2 pr-1 p-0"
                  @click="deleteSplInterest(data)"
                >
                  <i class="fas fa-trash"></i>
                </a>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p v-if="isAHP" class="text-center text-muted h6">
          No Expertise Information Found
        </p>
        <p v-else class="text-center text-muted h6">
          No Special Interest Information Found
        </p>
      </div>
    </div>
    <SpecialInterestForm
      :splInterestInfo="splInterestModal.data"
      :candidateInfo="profile"
      :isShowPopup="splInterestModal.isShowPopup"
      :isEdit="splInterestModal.isEdit"
      @modalCallBack="splInterestModalCallBack"
      v-if="splInterestModal.isShowPopup"
    >
    </SpecialInterestForm>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteSplInterestCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import SpecialInterestForm from "../EditForm/SpecialInterestForm";
import Modal from "@/components/reusable/Modal";
import { mapActions } from "vuex";

export default {
  props: {
    specialInterests: {
      type: Array,
      default: () => [],
    },
    profile: {
      type: Object,
      default: () => null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SpecialInterestForm,
    Modal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      splInterestModal: {
        data: null,
        isShowPopup: false,
        isEdit: false,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Special Interest",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
  },
  methods: {
    ...mapActions([
      "uploadSpecialInterest",
      "fetchspecialInterest",
      "updateSplInterest",
      "deleteCandidateSpecialInterest",
    ]),
    addSplInterest() {
      this.$parent.tooltipHide();
      this.splInterestModal.isShowPopup = true;
      this.splInterestModal.isEdit = false;
    },
    editSplInterest(data) {
      this.splInterestModal.data = data;
      this.splInterestModal.isEdit = true;
      this.splInterestModal.isShowPopup = true;
    },
    splInterestModalCallBack(action, payload) {
      if (action === false) this.splInterestModal.isShowPopup = false;
      else {
        let appendAction = null;
        if (
          this.splInterestModal.data === null &&
          this.splInterestModal.isEdit === false
        ) {
          appendAction = this.uploadSpecialInterest(payload);
        } else if (this.splInterestModal.isEdit) {
          appendAction = this.updateSplInterest(payload);
        }
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.splInterestModal.isShowPopup = false;
            this.splInterestModal.isEdit = false;
            this.splInterestModal.data = null;
            this.fetchspecialInterest();
          }
        });
      }
    },
    deleteSplInterest(data) {
      let interest = null;
      if (data.special_interest_name === "Other") {
        interest = data.other_special_interest;
      } else interest = data.special_interest_name;
      this.deleteConfirmModal.modalContent =
        "Do you want to delete this Special Interest" + " - " + interest + "?";
      this.candidate_speciality_id = data.candidate_speciality_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteSplInterestCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateSpecialInterest(this.candidate_speciality_id);
      }
      this.candidate_speciality_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
};
</script>
