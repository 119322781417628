var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-line"},[_vm._m(0),(_vm.preferredContactMethod)?_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"text-gray",attrs:{"col":"12"}},[_vm._v("Preferred Contact Method")]),_c('CCol',{staticClass:"text-black",attrs:{"col":"12"}},[_vm._v(" "+_vm._s(_vm._f("preferred_contact_method")(_vm.preferredContactMethod))+" ")])],1):_vm._e(),_c('CRow',{staticClass:"pb-2 m-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_c('i',{staticClass:"far fa-envelope mr-2"})]),_c('div',{staticClass:"d-flex justify-content-start sticky-header"},[_c('span',[_c('span',{staticClass:"d-block",class:{'mx-76':!_vm.isCandidateRole},style:(_vm.preferredContactMethod.includes('email')
                ? 'color: red;'
                : 'color: black;'),on:{"click":function($event){return _vm.openEmailModal(_vm.email)}}},[_vm._v(_vm._s(_vm.email))])])])])]),_c('CRow',{staticClass:"pb-2 m-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_c('i',{staticClass:"fa fa-phone mr-2"})]),_c('div',{staticClass:"d-flex justify-content-start sticky-header"},[_c('span',[_c('span',{staticClass:"d-block",style:(_vm.preferredContactMethod.includes('phone')
                ? 'color: red;'
                : 'color: black;')},[_vm._v(_vm._s(_vm.phone))])])])])]),_c('CRow',{staticClass:"pb-2 m-0"},[_c('span',[_c('i',{staticClass:"fab fa-whatsapp mr-2"})]),_c('div',{staticClass:"d-flex justify-content-start sticky-header"},[_c('span',{staticClass:"d-block",style:(_vm.preferredContactMethod.includes('whatsapp')
            ? 'color: red;'
            : 'color: black;')},[_vm._v(_vm._s(_vm.whatsapp))])])]),(_vm.isCandidate)?_c('div',[(_vm.isCandidate)?_c('CRow',{staticClass:"pb-2 m-0"},[_c('span',[_c('i',{staticClass:"fas fa-map-marker-alt mr-2",staticStyle:{"color":"#ea4335"}}),_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.currentLocation || "--"))])])]):_vm._e()],1):_vm._e(),(_vm.EditModal.isShowPopup)?_c('ContactInfoEdit',{attrs:{"candidateInfo":_vm.profile,"isShowPopup":_vm.EditModal.isShowPopup},on:{"modalCallBack":_vm.contactInfoModalCallBack}}):_vm._e(),(_vm.emailModal.email)?_c('EmailModal',{attrs:{"isShowPopup":_vm.emailModal.email,"email_id":_vm.emailId,"candidate_uid":_vm.profile.candidate_uid,"alternate_email":_vm.profile.alternate_email},on:{"emailModalCallBack":_vm.modalCallBack,"conversationModalCallBack":_vm.conversationModalCallBack}}):_vm._e(),(_vm.deleteModal.isShowPopup)?_c('DeleteModal',{attrs:{"isShowPopup":_vm.deleteModal.isShowPopup,"delete_id":_vm.deleteModal.delete_id,"email_subject":_vm.deleteModal.subject},on:{"deleteModalCallBack":_vm.deleteModalCallBack,"emailModalCallBack":_vm.modalCallBack}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"text-primary pb-2"},[_vm._v("Contact Information")])])
}]

export { render, staticRenderFns }