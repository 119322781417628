<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Professional Information
        <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="
            'See Profile Section below for Additional Details'
          "
        />
        <em
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToExpendableSection()"
        ></em>
      </h6>
    </div>
    <CRow class="pb-2">
      <CCol>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Main Speciality</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ speciality }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Years in Speciality</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ yearsSpeciality }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Other Expertise</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ expertise }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Highest Medical Qualification</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ highestQualName || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Country Obtained</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ highestQualCountry }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Year Obtained</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ highestQualYear }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Current Job Title</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.current_job || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Level</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ candidateLevel }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Licensed as</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ nurseType }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Local License/Eligibility </label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ eligibility_letter_available_for }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Dataflow Complete</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{
          profile.dataflow_complete ? "Yes" : "No"
        }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">License Number</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.license_number || "--" }}</span>
      </CCol>
      </CRow>
      </CCol>
    </CRow>

    <ProfessionalInfoNurseEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="ProfessionalInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <Modal
      v-if="splConfirmModal.isShowPopup"
      :modalTitle="splConfirmModal.modalTitle"
      :modalColor="splConfirmModal.modalColor"
      :modalContent="splConfirmModal.modalContent"
      :isShowPopup="splConfirmModal.isShowPopup"
      :buttons="splConfirmModal.buttons"
      :modalCallBack="splModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import ProfessionalInfoNurseEdit from "./EditForm/ProfessionalInfoNurseEdit";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import moment from "moment";

export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
    nurseSpeciality: {
      type: Array,
      default: [],
    },
    specialInterest: {
      type: Array,
      default: [],
    },
  },
  components: {
    ProfessionalInfoNurseEdit,
    Modal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      splConfirmModal: {
        modalColor: "primary",
        modalTitle: "Reset Special Interest",
        modalContent: "Do you need to reset the Special Interest?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateSpecialInterest",
      "getCandidateNonMedicalQuals",
    ]),
    mainSpeciality() {
      return this.nurseSpeciality.filter(({ main }) => main) || [];
    },
    otherSpeciality() {
      return this.nurseSpeciality.filter(({ is_current }) => !is_current) || [];
    },
    speciality() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ speciality: { speciality } }) => speciality)
            .join()) || this.profile?.speciality?.speciality ||
        "--"
      );
    },
    expertise() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities
            .filter(({ main }) => main === false)
            .map(({ speciality: { speciality } }) => speciality)
            .join()) ||
        "--"
      );
    },
    yearsSpeciality() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ experience_in_years }) => experience_in_years)
            .join()) ||
        "--"
      );
    },
    specialInterestName() {
      return (
        (this.specialInterest.length &&
          this.specialInterest
            .map(({ special_interest, other_special_interest }) =>
              special_interest
                ? special_interest?.special_interest
                : other_special_interest
            )
            .join()) ||
        "--"
      );
    },
    firstQualification() {
      return (
        (this.profile?.qualifications &&
          this.profile?.qualifications.filter(
            ({ main }) =>
              (main && this.profile.qualifications.length === 1) ||
              (!main && this.profile.qualifications.length > 1)
          )) ||
        []
      );
    },
    firstQualificationName() {
      return (
        (this.firstQualification.length &&
          this.firstQualification
            .map(
              ({
                qualification_name,
                month_awarded,
                year_awarded,
                country_name,
              }) =>
                `${qualification_name}-${country_name}-${this.formatDate(
                  month_awarded,
                  year_awarded
                )}`
            )
            .join()) ||
        "--"
      );
    },
    highestQualification() {
      return (
        (this.profile?.qualifications &&
          this.profile.qualifications.length > 1 &&
          this.profile?.qualifications.filter(({ main }) => main)) ||
        (this.profile.qualifications.length === 1 &&
          this.profile?.qualifications) ||
        []
      );
    },
    highestQualificationName() {
      return (
        (this.highestQualification.length &&
          this.highestQualification
            .map(
              ({
                qualification_name,
                month_awarded,
                year_awarded,
                country_name,
                description,
                qualification: { allow_subject_input },
              }) =>
                `${qualification_name}${
                  allow_subject_input && description
                    ? "(" + description + ")"
                    : ""
                }-${country_name}-${year_awarded}`
            )
            .join()) ||
        "--"
      );
    },
    nonMedicalQualifications() {
      return (
        this.getCandidateNonMedicalQuals
          .map(({ non_medical_qual }) => non_medical_qual?.name)
          .join() || "--"
      );
    },
    candidateLevel() {
      return this.profile?.candidate_level?.candidate_level
        ? `${this.profile?.candidate_level?.candidate_level}${
            this.profile?.candidate_level?.description !==
            this.profile?.candidate_level?.candidate_level
              ? " - " + this.profile?.candidate_level?.description
              : ""
          }`
        : "--";
    },
    pqrlevel() {
      const pqrLevel =
        this.profile.pqr_levels.filter(({ potential }) => !potential) || [];
      return pqrLevel.length
        ? pqrLevel[0]?.pqr_detail?.level?.candidate_level ||
            pqrLevel[0]?.pqr_ahp_detail?.description ||
            "--"
        : "--";
    },
    isNurse() {
      return this.profile?.candidate_type_id
        ? this.profile?.candidate_type_id === 2
        : false;
    },
    nurseType() {
      return this.profile?.nurse_registration_type
        ? _.startCase(this.profile?.nurse_registration_type.join()) || "--"
        : "--";
    },
    highestQualName() {
      if (
        this.profile?.qualifications &&
        this.profile?.highest_qualification_name &&
        this.profile?.highest_qualification_name == "Other - not listed"
      ) {
        return _(this.profile?.qualifications)
          .filter((qualification) => {
            return (
              qualification?.main &&
              qualification?.qualification_name ==
                this.profile?.highest_qualification_name
            );
          })
          .map((qualification) => {
            return qualification?.other_qualification_name
              ? "Other - " + qualification?.other_qualification_name
              : null;
          })
          .value()
          .toString();
      } else {
        return (
          (this.highestQualification.length &&
            this.highestQualification
              .map(
                ({
                  qualification_name,
                  month_awarded,
                  year_awarded,
                  country_name,
                  description,
                  qualification,
                }) =>
                  `${qualification_name != null ? qualification_name : "--"}${
                    qualification?.allow_subject_input && description
                      ? "(" + description + ")"
                      : ""
                  }`
              )
              .join()) ||
          "--"
        );
      }
    },
    highestQualCountryYear() {
      return (
        (this.highestQualification.length &&
          this.highestQualification
            .map(
              ({ year_awarded, country_name }) =>
                `${country_name}-${year_awarded}`
            )
            .join()) ||
        "--"
      );
    },
    highestQualCountry() {
      return (
        (this.highestQualification.length &&
          this.highestQualification
            .map(({ country_name }) => country_name)
            .join()) ||
        "--"
      );
    },
    highestQualYear() {
      return (
        (this.highestQualification.length &&
          this.highestQualification
            .map(({ year_awarded }) => year_awarded)
            .join()) ||
        "--"
      );
    },    
    eligibility_letter_available_for(){
      return this.profile?.eligibility_letter_available_for ? this.profile.eligibility_letter_available_for.split(",").join(", ") :"--"
    }
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "fetchQualification",
      "deleteSplInterestWithoutToast",
      "fetchspecialInterest",
      "showToast",
    ]),
    editProfessionalInfo() {
      this.EditModal.isShowPopup = true;
    },
    ProfessionalInfoModalCallBack(action, data, isSpecialityChanged) {
      this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          if (isSpecialityChanged?.length) {
            this.fetchQualification();
            if (
              this.getCandidateSpecialInterest?.length &&
              isSpecialityChanged.some((val) => val == "speciality_id")
            ) {
              this.splConfirmModal.isShowPopup = true;
            }
          }
        });
      }
    },
    splModalCallBack(action) {
      this.splConfirmModal.isShowPopup = false;
      if (action == "Yes") {
        let appendAction = [];
        this.getCandidateSpecialInterest.forEach((val) => {
          if (val.special_interest_id)
            appendAction = this.deleteSplInterestWithoutToast(
              val.candidate_speciality_id
            );
        });
        Promise.all([appendAction]).then((res) => {
          this.fetchspecialInterest();
          this.showToast({
            class: "bg-success text-white",
            message: "Special Interest reset successful",
          });
        });
        this.$parent.goToSplInterestSection();
      }
    },
    formatDate(month_awarded, year_awarded) {
      if (month_awarded)
        return `${moment.monthsShort()[month_awarded - 1]} - ${year_awarded}`;
      return year_awarded || "";
    },
  },
};
</script>
