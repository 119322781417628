<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Language Information {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab :title="`Language Information ${isEdit ? 'Edit' : 'Add'}`">
            <div class="p-2">
              <ValidationObserver
                ref="languageInfoForm"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="languageInfoForm"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_languages_spoken"
                          class="required col-lg-12 col-md-12"
                          >Languages Spoken</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="languages_spoken"
                              :value="profile.languages_spoken"
                              @input="handleChangeSelect"
                              :options="
                                options && options['languages']
                                  ? options['languages']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_language_proficiency"
                          class="col-lg-12 col-md-12"
                          >Language Proficiency</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="language_proficiency"
                            :value="profile.language_proficiency"
                            @input="handleChangeSelect"
                            :options="
                              options && options['proficiency']
                                ? options['proficiency']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("singleRequired", {
  ...required,
  message: "Please input atleast one of the fields!",
});
export default {
  mixins: [EditSection],
  props: {
    languageInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
      candidate_language_id: null,
    };
  },
  computed: {
    ...mapGetters([
      "isFetchingFilter",
      "languageProficiency",
      "locationLanguage",
      "getCandidateLanguage"
    ]),
    options() {
      return {
        languages: this.locationLanguageReOrder || [],
        proficiency: this.languageProficiency || [],
      };
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage &&
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    isFetching() {
      if (!this.isFetchingFilter) {
        let profileData = this.candidateInfo;
        if (this.isEdit) {
          let data = this.languageInfo;
          this.profile = {
            ...this.profile,
            languages_spoken: {
              code: data.language_id,
              label: data.language,
            },
            language_proficiency: {
              code: data.proficiency_id,
              label: data.proficiency,
            },
          };
          this.candidate_language_id = data.candidate_language_id;
        }

        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["initFetchLanguageOptions", "showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.languageInfoForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      } 
      if (this.candidate_language_id !== null) {
        let _count = 0;
        let _index;
        for (var i = 0; i < this.getCandidateLanguage.length; i++) {
          if (
            this.getCandidateLanguage[i].language.language_id ===
            this.profile.languages_spoken.code
          ) {
            _index = i;
            _count = _count + 1;
          }
        }
        if (
          _count >= 1 &&
          this.getCandidateLanguage[_index].candidate_language_id !==
            this.candidate_language_id
        ) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Language Already Exist!",
          });
          return;
        }
      } else {
        if (
          this.getCandidateLanguage &&
          this.getCandidateLanguage.length &&
          this.getCandidateLanguage.findIndex(
            (e) =>
              e.language.language_id === this.profile.languages_spoken.code
          ) >= 0
        ) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Language Already Exist!",
          });
          return;
        }
      }
      let langPayload = {
        language_id: this.profile.languages_spoken
          ? this.profile.languages_spoken.code
          : null,
        lang_prof_id: this.profile.language_proficiency
          ? this.profile.language_proficiency.code
          : null,
      };
      if (this.isEdit) {
        this.modalCallBack(true, {
          langPayload,
          candidate_lang_id: this.candidate_language_id,
        });
      } else {
        this.modalCallBack(true, langPayload);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    this.initFetchLanguageOptions();
  },
};
</script>