<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Availability & Preferences</h6>
      <!--<div class="d-flex justify-content-end action-wrapper">
        <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editAvailabilityInfo"
        >
          <i class="fas fa-edit" alt="Edit" />
        </a>
      </div>-->
    </div>
    <CRow class="pb-2">
      <CCol>
      <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Notice Period</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ noticePeriod }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">{{
          profile.available ? "Join Date/Availability" : "Available From"
        }}</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{
          profile.available ? expectedJoinDate : availableFrom
        }}</span>
      </CCol>
      </CRow>
       <CRow class="profileView no-line">
      <CCol lg="4">  
        <label class="text-gray">Current Salary</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ salaryCurrent }}</span>
      </CCol>
       </CRow>
        <CRow class="profileView no-line">
      <CCol lg="4">  
        <label class="text-gray">Expected Salary</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ salaryExpectation }}</span>
      </CCol>
        </CRow>
         <CRow class="profileView no-line">
      <CCol lg="4"> 
        <label class="text-gray">Preferred Contract</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ contract }}</span>
      </CCol>
         </CRow>
      </CCol>
    </CRow>

    <AvailabilityInfoEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="availiabilityInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import AvailabilityInfoEdit from "./EditForm/AvailabilityInfoEdit";
import { mapActions, mapGetters } from "vuex";
import profile from "../AddJobs/profile";
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  components: {
    AvailabilityInfoEdit,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCandidatePreferredRegionList",
      "getCandidatePreferredCountryList",
    ]),
    availableEmployment() {
      if (this.profile.available != null)
        return this.profile.available ? "Yes" : "No";
      return "--";
    },
    preferredCountry() {
      if (this.getCandidatePreferredCountryList.length)
        return this.getCandidatePreferredCountryList
          .map((ele) => {
            return ` ${ele.label}`;
          })
          .toString();
      return "--";
    },
    preferredRegion() {
      if (this.getCandidatePreferredRegionList.length)
        return this.getCandidatePreferredRegionList
          .map(({ label }) => {
            return ` ${label}`;
          })
          .toString();
      return "--";
    },
    noticePeriod() {
      if (this.profile.notice_period_days != null)
        return this.profile.notice_period_days + " Days";
      return "--";
    },
    salaryCurrent() {
      let currentSalary = `${this.profile?.current_salary || "-"} ${
        this.profile?.current_salary_currency?.currency_code || ""
      }`;
      return `${currentSalary}`;
    },
    salaryExpectation() {
      let expextedSalary = `${this.profile?.salary_expectation_min || ""}-${
        this.profile.salary_expectation_max || ""
      } ${this.profile?.salary_expectation_currency?.currency_code || ""}`;
      return `${expextedSalary}`;
    },
    contract() {
      return `${this.profile.job_type || "--"} - ${
        this.profile.job_time || "--"
      }`;
    },
    expectedJoinDate() {
      return this.profile.expected_join_date
        ? moment(this.profile.expected_join_date).format("DD-MMM-YYYY")
        : "--";
    },
    availableFrom() {
      return this.profile.available_from
        ? moment(this.profile.available_from).format("DD-MMM-YYYY")
        : "--";
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidatePreferredRegionList",
      "fetchCandidatePreferredCountryList",
      "updateCandidate",
      "deletePreferredRegion",
      "deletePreferredCountry",
      "createPreferredCountry",
      "createPreferredRegion",
    ]),
    editAvailabilityInfo() {
      this.EditModal.isShowPopup = true;
    },
    availiabilityInfoModalCallBack(action, data) {
      if (action === false) this.EditModal.isShowPopup = false;
      if (action === true) {
        let { customer_uid } = this.profile;
        let { candidate_data, region_data, country_data } = data;
        let appendAction = [];
        appendAction = [
          ...appendAction,
          this.updateCandidate({ data: candidate_data, customer_uid }),
        ];
        if (region_data != undefined) {
          this.getCandidatePreferredRegionList.forEach((region) => {
            appendAction = [
              ...appendAction,
              this.deletePreferredRegion(region.preferred_region_id),
            ];
          });
        }
        if (region_data != undefined && region_data.length) {
          region_data.forEach((region, index) => {
            let region_payload = {
              region_id: region,
              preference: index + 1,
              comments: null,
            };
            appendAction = [
              ...appendAction,
              this.createPreferredRegion(region_payload),
            ];
          });
        }
        if (country_data != undefined) {
          this.getCandidatePreferredCountryList.forEach((country) => {
            appendAction = [
              ...appendAction,
              this.deletePreferredCountry(country.preferred_country_id),
            ];
          });
        }
        if (country_data != undefined && country_data.length) {
          country_data.forEach((country_id, index) => {
            let country_payload = {
              country_id,
              preference: index + 1,
              comments: null,
            };
            appendAction = [
              ...appendAction,
              this.createPreferredCountry(country_payload),
            ];
          });
        }
        Promise.all([appendAction]).then((res) => {
          setTimeout(() => {
            this.fetchCandidatePreferredRegionList();
            this.fetchCandidatePreferredCountryList();
          }, 500);
          this.EditModal.isShowPopup = false;
        });
      }
    },
  },
};
</script>
