<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Professional Information
        <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="
            'See Profile Section below for Additional Details'
          "
        />
        <em
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToExpendableSection()"
        ></em>
      </h6>
    </div>
    <CRow class="pb-2">
      <CCol>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Speciality</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ speciality }}</span>
      </CCol>
      </CRow>
      <CRow v-if="!isDentist" class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Sub Speciality</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ subSpeciality }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Level</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.level_name || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Highest Medical Qualification</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{
          profile.highest_qualification_acronym || highestQualName || "--"
        }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Year Obtained</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ qualification_year }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Country Obtained</label>
      </CCol>
      <CCol  lg="8">
        <span class="text-black">{{ qualification_country }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4">  
        <label class="text-gray">Current Job Title</label>
      </CCol>
      <CCol lg="4">
        <span class="text-black">{{ profile.current_job || "--" }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4">  
        <label class="text-gray">Dataflow Complete</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{
          profile.dataflow_complete ? "Yes" : "No"
        }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">Local License/Eligibility </label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ eligibility_letter_available_for }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView no-line" v-if="showMore">
      <CCol lg="4"> 
        <label class="text-gray">License Number</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.license_number || "--" }}</span>
      </CCol>
      </CRow>
       <CRow>
        <CCol class="more-less text-center" @click="ShowMoreLess()" >
          <i class="fa fa-chevron-circle-up" title='Show less' v-if="showMore" aria-hidden="true"></i>
          <i class="fa fa-chevron-circle-down" title='Show more' v-else aria-hidden="true"></i>
        </CCol>
      </CRow> 
      </CCOl>
    </CRow>

    <ProfessionalInfoEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="ProfessionalInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <Modal
      v-if="splConfirmModal.isShowPopup"
      :modalTitle="splConfirmModal.modalTitle"
      :modalColor="splConfirmModal.modalColor"
      :modalContent="splConfirmModal.modalContent"
      :isShowPopup="splConfirmModal.isShowPopup"
      :buttons="splConfirmModal.buttons"
      :modalCallBack="splModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import ProfessionalInfoEdit from "./EditForm/ProfessionalInfoEdit";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";

export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  components: {
    ProfessionalInfoEdit,
    Modal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      showMore: false,
      splConfirmModal: {
        modalColor: "primary",
        modalTitle: "Reset Special Interest",
        modalContent: "Do you need to reset the Special Interest?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
      },
    };
  },
  computed: {
    ...mapGetters(["getCandidateSpecialInterest"]),
    speciality() {
      return this.profile.speciality?.speciality || "--";
    },
    subSpeciality() {
      return this.profile.sub_speciality?.sub_speciality || "--";
    },
    pqrlevel() {
      const pqrLevel =
        this.profile.pqr_levels.filter(({ potential }) => !potential) || [];
      return pqrLevel.length
        ? pqrLevel[0]?.pqr_detail?.level?.candidate_level ||
            pqrLevel[0]?.pqr_ahp_detail?.description ||
            "--"
        : "--";
    },
    qualification_year() {
      let index = this.profile.qualifications.findIndex(
        (x) => x.qualification_name === this.profile.highest_qualification_name
      );
      return this.profile.qualifications[index]?.year_awarded || "--";
    },
    qualification_country() {
      let index = this.profile.qualifications.findIndex(
        (x) => x.qualification_name === this.profile.highest_qualification_name
      );
      return this.profile.qualifications[index]?.country_name || "--";
    },
    psvDataFlow() {
      if (this.profile.psv_dataflow_complete != null) {
        if (["Yes", true, "all"].includes(this.profile.psv_dataflow_complete)) {
          return "All";
        } else if (
          ["No", false, "none"].includes(this.profile.psv_dataflow_complete)
        ) {
          return "None";
        } else if (this.profile.psv_dataflow_complete === "some") {
          return "Some";
        }
      }
      return "N/A";
    },
    isNurse() {
      return this.profile?.candidate_type_id
        ? this.profile?.candidate_type_id === 2
        : false;
    },
    isDentist() {
      return this.profile?.candidate_type_id
        ? this.profile?.candidate_type_id === 4
        : false;
    },
    highestQualName() {
      if (
        this.profile?.qualifications &&
        this.profile?.highest_qualification_name &&
        this.profile?.highest_qualification_name == "Other - not listed"
      ) {
        return _(this.profile?.qualifications)
          .filter((qualification) => {
            return (
              qualification?.main &&
              qualification?.qualification_name ==
                this.profile?.highest_qualification_name
            );
          })
          .map((qualification) => {
            return qualification?.other_qualification_name
              ? "Other - " + qualification?.other_qualification_name
              : null;
          })
          .value()
          .toString();
      } else {
        return (
          (this.profile?.qualifications?.length &&
            this.profile?.qualifications
              .map(
                ({
                  qualification_name,
                  qualification_acronym,
                  month_awarded,
                  year_awarded,
                  country_name,
                  description,
                  qualification,
                }) =>
                  `${qualification_name != null ? qualification_name : "--"}${
                    qualification?.allow_subject_input && description
                      ? "(" + description + ")"
                      : ""
                  }`
              )
              .join()) ||
          "--"
        );
      }
    },
    eligibility_letter_available_for(){
      return this.profile?.eligibility_letter_available_for ? this.profile.eligibility_letter_available_for.split(",").join(", ") :"--"
    }
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "fetchQualification",
      "deleteSplInterestWithoutToast",
      "fetchspecialInterest",
      "showToast",
      "fetchPQRLevels",
    ]),
    editProfessionalInfo() {
      this.EditModal.isShowPopup = true;
    },
    ProfessionalInfoModalCallBack(action, data, isSpecialityChanged) {
      this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          this.fetchPQRLevels();
          if (isSpecialityChanged?.length) {
            this.fetchQualification();
            if (
              this.getCandidateSpecialInterest?.length &&
              isSpecialityChanged.some((val) => val == "speciality_id")
            ) {
              this.splConfirmModal.isShowPopup = true;
            }
          }
        });
      }
    },
    splModalCallBack(action) {
      this.splConfirmModal.isShowPopup = false;
      if (action == "Yes") {
        let appendAction = [];
        this.getCandidateSpecialInterest.forEach((val) => {
          if (val.special_interest_id)
            appendAction = this.deleteSplInterestWithoutToast(
              val.candidate_speciality_id
            );
        });
        Promise.all([appendAction]).then((res) => {
          this.fetchspecialInterest();
          this.showToast({
            class: "bg-success text-white",
            message: "Special Interest reset successful",
          });
        });
        this.$parent.goToSplInterestSection();
      }
    },
    ShowMoreLess() {
      this.showMore = !this.showMore;
    },
  },
};
</script>
