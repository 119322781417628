<template>
  <div class="auditTrail-History">
    <LoadingOverlay v-if="isFetchingAuditHistory" />
    <CRow class="d-flex justify-content-start">
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">History:</label>
        <Select
          name="filter"
          :value="filter.filter"
          @input="handleChangeSelect"
          :options="options && options['filter'] ? options['filter'] : []"
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'communications'"
      >
        <label class="mb-1">Communication Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['communication_type']
              ? options['communication_type']
              : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'comments'"
      >
        <label class="mb-1">Comments Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['comments_type'] ? options['comments_type'] : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">Date Range:</label>
        <DatePicker
          name="dateRange"
          :value="filter.dateRange"
          @input="handleDatePickerChange"
          :showTillToday="true"
          :range="true"
          returnType="format"
        />
      </CCol>
    </CRow>
    <div class="container timeline-scrolling" id="history-info">
      <div class="row">
        <div class="col-md-11">
          <ul
            v-if="filter.filter.code == 'candidateTransactions'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.action_date_time) }}</span>
                <span>{{ formatDate(data.action_date_time) }}</span>
              </time>

              <div class="icon" :style="getTheme(data.table_name).bg_color">
                <em
                  :class="getTheme(data.table_name).src"
                  style="color: white"
                ></em>
              </div>
              <div class="label">
                <h5>{{ formatName(data.transaction_user_name) }}</h5>
                <p class="pl-3">
                  {{ formatName(data.transaction_user_name) }}
                  {{ action(data) }} candidate
                  {{ getTheme(data.table_name).record_name }}.
                </p>
              </div>
            </li>
          </ul>

          <ul v-if="filter.filter.code == 'statusChange'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.created_on) }}</span>
                <span>{{ formatDate(data.created_on) }}</span>
              </time>

              <div class="icon" :style="getTheme('statusChange').bg_color">
                <em
                  :class="getTheme('statusChange').src"
                  style="color: white"
                ></em>
              </div>
              <div class="label">
                <p class="h6" v-if="data.comments">
                  <span v-if="!data._toggled"
                    >{{ data.comments.substring(0, 60)
                    }}{{ data.comments.substring(61) ? "..." : "" }}</span
                  ><span v-else style="line-height: normal">{{
                    data.comments
                  }}</span
                  ><span
                    ><CButton
                      v-if="data.comments.substring(61)"
                      @click="moreToggle(data, index)"
                      class="btn btn-link p-0"
                    >
                      {{ data._toggled ? "Show less" : "Show more" }}
                    </CButton>
                  </span>
                </p>
                <p class="pl-3 mb-2">
                  Moved
                  {{ data.previous_status ? `from ` : "" }}
                  <strong
                    >{{ data.previous_status
                    }}{{
                      data.previous_sub_status
                        ? `(${data.previous_sub_status}) `
                        : " "
                    }}</strong
                  >
                  to
                  <strong
                    >{{ data.status
                    }}{{
                      data.sub_status ? `(${data.sub_status})` : ""
                    }}</strong
                  >
                  for
                  <a
                    class="h6"
                    href="javascript:void(0)"
                    @click="navigateToJobListPreview(data.job_uuid)"
                    >{{ formatName(data.job_title) }}</a
                  >
                  by
                  <strong>{{ formatName(data.created_user) }}</strong>
                </p>
              </div>
            </li>
          </ul>

          <ul v-if="filter.filter.code == 'comments'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.created_on) }}</span>
                <span>{{ formatDate(data.created_on) }}</span>
              </time>

              <div class="icon" :style="getTheme('comments').bg_color">
                <em :class="getTheme('comments').src" style="color: white"></em>
              </div>
              <div class="label">
                <p class="h6" v-if="data.comments">
                  <span v-if="!data._toggled"
                    >{{ data.comments.substring(0, 60)
                    }}{{ data.comments.substring(61) ? "..." : "" }}</span
                  ><span v-else style="line-height: normal">{{
                    data.comments
                  }}</span
                  ><span
                    ><CButton
                      v-if="data.comments.substring(61)"
                      @click="moreToggle(data, index)"
                      class="btn btn-link p-0"
                    >
                      {{ data._toggled ? "Show less" : "Show more" }}
                    </CButton>
                  </span>
                </p>
                <p class="pl-3 mb-2">
                  Updated by
                  <strong>{{ formatName(data.created_user) }}</strong>
                  <span v-if="data.job_id"
                    >for
                    <a
                      class="h6"
                      href="javascript:void(0)"
                      @click="navigateToJobListPreview(data.job_uuid)"
                      >{{ formatName(data.job_title) }}</a
                    >
                    (job)</span
                  >.
                </p>
              </div>
            </li>
          </ul>

          <ul
            v-if="filter.sub_filter && filter.sub_filter.code == 'email'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.load_datetime) }}</span>
                <span>{{ formatDate(data.load_datetime) }}</span>
              </time>

              <div class="icon" :style="getTheme('email').bg_color">
                <em :class="getTheme('email').src" style="color: white"></em>
              </div>
              <div class="label">
                <p class="h6" v-if="data.comments">
                  <span v-if="!data._toggled"
                    >{{ data.comments.substring(0, 60)
                    }}{{ data.comments.substring(61) ? "..." : "" }}</span
                  ><span v-else style="line-height: normal">{{
                    data.comments
                  }}</span
                  ><span
                    ><CButton
                      v-if="data.comments.substring(61)"
                      @click="moreToggle(data, index)"
                      class="btn btn-link p-0"
                    >
                      {{ data._toggled ? "Show less" : "Show more" }}
                    </CButton>
                  </span>
                </p>
                <p class="pl-3 mb-2">
                  <strong>{{ formatName(data.sender) }}</strong>
                  has sent a mail with subject named "<strong
                    >{{ data.subject }} </strong
                  >"<span v-if="data.job_id">
                    for
                    <a
                      class="h6"
                      href="javascript:void(0)"
                      @click="navigateToJobListPreview(data.job_uuid)"
                      >{{ formatName(data.job_title) }}
                    </a>
                    (job)</span
                  >.
                  <CButton class="btn btn-link p-0" @click="showMsgModal(data)">
                    <em class="fas fa-eye"></em> View Message
                  </CButton>
                </p>
              </div>
            </li>
          </ul>

          <h5
            class="text-center"
            v-if="!(getAuditHistory && getAuditHistory.length)"
          >
            No Data Found!
          </h5>
        </div>
      </div>
    </div>
    <CModal color="primary" :show.sync="isShowMsgModal">
      <template #header>
        <h5>Detailed Message</h5>
        <button
          aria-label="Close"
          class="close"
          @click="isShowMsgModal = false"
        >
          x
        </button>
      </template>
      <template #footer>
        <CButton color="primary" @click="isShowMsgModal = false">Close</CButton>
      </template>
      <p class="h6">
        <strong>{{ selectedMessage.subject }}</strong>
      </p>
      <p class="pl-3" v-html="selectedMessage.body"></p>
      <span
        class="attachment-item"
        v-for="(attachment, index) in selectedMessage.email_attachment"
        :key="index"
      >
        <a v-if="attachment" @click="downloadFile(attachment)"
          ><i class="fa fa-paperclip" aria-hidden="true"></i
          >{{ attachment.file_name }}</a
        >
      </span>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import m from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";

export default {
  name: "HistoryInfo",
  props: ["candidateProfile"],
  components: {
    Select,
    DatePicker,
  },
  data: () => ({
    filter: {
      filter: { label: "Profile Updates", code: "candidateTransactions" },
      sub_filter: null,
    },
    isShowMsgModal: false,
    selectedMessage: {},
  }),
  computed: {
    ...mapGetters([
      "isFetchingAuditHistory",
      "getAuditHistory",
      "getAuditHistoryPagination",
      "getContactMethods",
    ]),
    options() {
      return {
        filter: [
          { label: "Profile Updates", code: "candidateTransactions" },
          { label: "Status Changes", code: "statusChange" },
          { label: "Comments", code: "comments" },
          { label: "Communications", code: "communications" },
        ],
        communication_type: this.getContactMethods || [],
        comments_type: [
          { label: "Private Comments", code: "privateCandidateComments" },
          { label: "Candidate Related Comments", code: "comments" },
        ],
      };
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    "$route.params.id": function (id) {
      this.filter.filter = {
        label: "Profile Updates",
        code: "candidateTransactions",
      };
      this.fetchCandidateAuditHistory(false);
    },
  },
  methods: {
    ...mapActions([
      "fetchContactMethods",
      "fetchAuditHistory",
      "setCandidateListBackUrl",
      "downloadAttachment",
    ]),
    ...mapMutations(["RESET_AUDIT_HISTORY"]),
    formatTime(data) {
      return data ? m.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? m.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    action(data) {
      if (data.action == "I") {
        return "added";
      } else {
        return data?.changed_fields?.deleted == "t" ? "deleted" : "updated";
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.filter, name, value);
      if (
        name == "filter" &&
        (value?.code == "communications" || value?.code == "comments")
      ) {
        this.filter.sub_filter = null;
        this.$store.commit("RESET_AUDIT_HISTORY");
        return;
      }
      this.fetchCandidateAuditHistory(false);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.filter, name, value);
      this.fetchCandidateAuditHistory(false);
    },
    moreToggle(data, index) {
      this.$set(this.getAuditHistory[index], "_toggled", !data._toggled);
    },
    fetchCandidateAuditHistory(pagination) {
      let payload = {
        candidate_uid: this.candidateProfile?.candidate_uid,
        customer_uid: this.candidateProfile?.customer_uid,
        history: this.filter?.filter?.code,
        pagination: pagination,
        from_date: this.filter?.dateRange[0],
        to_date: this.filter?.dateRange[1],
      };
      if (this.filter?.sub_filter?.code != "email")
        payload = {
          ...payload,
          contact_method: this.filter?.sub_filter?.code,
        };
      if (this.filter?.sub_filter?.code == "email") {
        payload = {
          ...payload,
          history: "candidateEmailCommunication",
        };
      }
      if (this.filter?.filter?.code == "comments") {
        payload.history = this.filter?.sub_filter?.code;
      }
      this.fetchAuditHistory(payload);
    },
    onBodyScroll(e) {
      if (
        this.isFetchingAuditHistory ||
        this.getAuditHistoryPagination?.noMoreData
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchCandidateAuditHistory(true);
      }
    },
    navigateToJobListPreview(job_uuid) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${job_uuid}` });
    },
    showMsgModal(data) {
      this.selectedMessage = data;
      this.isShowMsgModal = true;
    },
    downloadFile(attachment) {
      let attachment_id = attachment.attachment_id;
      let appendAction = this.downloadAttachment({ attachment_id });
      Promise.all([appendAction]).then((res) => {
        let url = res[0];
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", attachment.file_name);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getTheme(data) {
      let src = "";
      let color = "";
      let record_name = "";
      switch (data) {
        case "statusChange":
          src = "fas fa-user-md";
          color = "#DC0050 !important";
          break;
        case "comments":
          src = "fas fa-comment";
          color = "#1973e8;";
          break;
        case "email":
          src = "fas fa-envelope";
          color = "#50d38a";
          break;
        case "whatsapp":
          src = "fas fa-whatsapp";
          color = "#25D366;";
          break;
        case "phone":
          src = "fas fa-phone";
          color = "#B23850;";
          break;
        case "message":
          src = "fas fa-sms";
          color = "#61892F;";
          break;
        case "mail":
          src = "fas fa-mail-bulk";
          color = "#A64AC9;";
          break;
        case "candidates":
          src = "fas fa-user-md";
          color = "#50d38a !important";
          record_name = "profile";
          break;
        case "candidate_qualifications":
          src = "fas fa-user-graduate";
          color = "#DC0050;";
          record_name = "qualification";
          break;
        case "candidate_documents":
          src = "fas fa-file-word";
          color = "#1973e8";
          record_name = "document";
          break;
        case "candidate_specialities":
          src = "fas fa-user-tag";
          color = "#61892F;";
          record_name = "specialities";
          break;
        case "candidate_languages":
          src = "fas fa-language";
          color = "#B23850;";
          record_name = "language";
          break;
        case "candidate_pqr_levels":
          src = "fas fa-level-up-alt";
          color = "#A64AC9;";
          record_name = "PQR level";
          break;
        default:
          src = "fas fa-history";
          color = "#A64AC9;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
        record_name: record_name,
      };
    },
  },
  mounted() {
    if (!this.getContactMethods?.length) {
      this.fetchContactMethods();
    }
    this.filter.dateRange = [
      m().subtract(30, "days").format("YYYY-MM-DD"),
      m().format("YYYY-MM-DD"),
    ];
    this.fetchCandidateAuditHistory(false);
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
