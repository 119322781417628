<template>
  <div>
    <CRow v-if="jobs.length">
      <CCol
        lg="4"
        v-for="({ job, actions, sub_status, job_applied, status }, index) in jobs"
        :key="job.job_id"
      >
        <CCard
          class="job-card"
          :class="{ 'job-active': isSelectedJob(job.job_id) }"
        >
          <CCardBody class="p-1 pt-2 position-relative">
            <div
              class="action-icon cursor-action d-flex"
              v-if="currentUserRole === Role.customerAdmin"
            >
              <div class="fav-action mx-2">
                <span
                  class="f-1_5 action position-relative multi-select-icon-div"
                  v-if="getMatched(index)"
                >
                  <i
                    v-show="getFavourite(index)"
                    class="text-primary fas fa-heart"
                    v-c-tooltip="
                      `Click to Remove favourite Job`
                    "
                    @click="removeFromFavourite(index)"
                  ></i>
                  <i
                    v-show="!getFavourite(index)"
                    class="far fa-heart"
                    v-c-tooltip="`Click to Add favourite Job`"
                    @click="addToFavourite(index)"
                  ></i>
                </span>
              </div>
              <CDropdown placement="top-start">
                <template #toggler>
                  <div class="px-2">
                    <i class="fas fa-ellipsis-v"></i>
                  </div>
                </template>
                <a
                  class="dropdown-item cursor-action"
                  v-for="data in getActions(actions)"
                  :key="data.action_id"
                  @click="openPopup({ index, data })"
                >
                  {{ data.label }}
                </a>
              </CDropdown>
            </div>
            <div
              class="content-job-title cursor-action p-2"
              @click="getToJobDetails(index)"
            >
              <div class="job-content">
                <div class="image-wrapper">
                  <img :src="getOrganisationLogo(job)" />
                </div>
                <h5 class="d-block">{{ job.job_title }}</h5>
                <span class="d-block">
                  <span class="text-muted">ID:</span>
                  {{ job.job_id }}
                </span>
                <span class="d-block"></span>
                <span class="d-block text-muted" v-if="job.organisation.name">{{
                  job.organisation.name
                }}</span>
                <span class="text-muted">
                  <span class="icon-location"></span>
                  {{ getHospitalLocation(job) }}
                </span>
              </div>
              <div class="text-left mb-3 ml-2" style="font-size: medium">
                <CBadge
                  color="success"
                  class="font-weight-normal ml-1"
                  v-if="job_applied"
                >
                  <span>Applied</span></CBadge
                >
                <CBadge color="primary" class="font-weight-normal ml-1" v-else
                  ><span>Talent Pool</span></CBadge
                >
                <CBadge
                  color="info"
                  class="font-weight-normal ml-1 text-white"
                  v-if="
                    status &&
                    status['display_text']
                  "
                  ><span>{{ status["display_text"] }}</span></CBadge
                >
                <CBadge
                  color="warning"
                  class="font-weight-normal ml-1 text-white"
                  v-if="
                    sub_status &&
                    sub_status['display_text'] &&
                    !getFavourite(index)
                  "
                  ><span>{{ sub_status["display_text"] }}</span></CBadge
                >
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p v-else class="text-center text-gray h5">No Job Matches Found</p>
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    selectedJob: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isMobile: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters(["candidateProfile"]),
    jobsInfo() {
      return (this.jobs && this.jobs.map(({ job }) => job)) || [];
    },
  },
  methods: {
    ...mapActions(["updateCandidateJobStatus"]),
    getToJobDetails(index) {
      let jobInfo = this.jobs[index];
      this.$emit("getJobDetails", jobInfo);
    },
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    getOrganisationLogo(data) {
      return data.organisation?.logo_url ? `https://${window.location.host}${data.organisation?.logo_url}` : "/img/hospital.png";
    },
    isSelectedJob(jobID) {
      if (jobID && this.selectedJob) return jobID === this.selectedJob.job_id;
      return false;
    },
    getHospitalLocation(data) {
      return data?.organisation?.location?.location || "--";
    },
    modalCallBack(action, payload) {
      if (!action) this.actionModal.isShowPopup = false;
      if (action && payload) {
        const { candidate_uid } = this.candidateProfile;
        const { job_id } = this.actionJob;
        const appentAction = this.updateCandidateJobStatus({
          candidate_uid,
          job_id,
          payload,
        });
        Promise.all([appentAction]).then((res) => {
          if (res) this.actionModal.isShowPopup = false;
        });
      }
      this.actionJob = [];
    },
    getActions(actions) {
      return (
        (actions &&
          actions.map((val) => {
            return {
              label: val?.name,
              action_id: val?.id,
              directAction: val?.direct_action,
              status: val?.status,
              filter_id: val?.filter,
              tab_id: val?.tab,
              reason_id: val?.reason,
            };
          })) ||
        []
      );
    },
    openPopup({ index, data }) {
      const jobDetails = this.jobs[index];
      let payload = { jobDetails, selectedAction: data };
      this.$emit("actionModalEvent", payload);
    },
    getMatched(index) {
      const jobInfo = this.jobs[index];
      return jobInfo?.status_id === 42;
    },
    getFavourite(index) {
      const jobInfo = this.jobs[index];
      return jobInfo?.status_id === 42 && jobInfo?.sub_status_id === 70;
    },
    addToFavourite(index) {
      const jobDetails = this.jobs[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: 70 },
      };
      let payload = { jobDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
    removeFromFavourite(index) {
      const jobDetails = this.jobs[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: null },
      };
      let payload = { jobDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
  },
  filters: {
    expiry(date) {
      if (date != null) {
        let now = moment().utc();
        let exp = moment().utc(date);
        return now.isAfter(exp) ? "Expired" : "Active";
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.job-card {
  border-radius: 11px;
  border: 0px !important;
  box-shadow: -1px -1px 3px 0px #797979;
}
.job-content {
  line-height: normal;
}
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
.content-job-title > .job-content {
  min-height: 210px;
}
.job-card:hover {
  background-color: #fdf1f64d;
}
.job-card.job-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 100;
}
</style>