<template>
  <div class="candidate-details px-0 container-md col-lg-10 col-md-10">
    <LoadingOverlay v-if="!isFetchingCandidatesDetails" />
    <div v-else-if="isCandidateFound">
      <div class="pb-3 mt-2" v-if="isCandidateRole">
        <div class="text-right">
          <CButton
            class="spacingHomeBtn spacingHomeBtnRes"
            color="primary"
            shape="pill"
            @click="backtocardview"
            >Back to Home</CButton
          >
        </div>
      </div>
      <div class="pb-1 mb-1" v-if="!isCandidateRole">
        <CRow class="m-0">
          <CCol md="12" sm="12" lg="6" class="d-block d-lg-none pb-3 pt-3">
            <div class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back</CButton
              >
              <CButton
                id="prevbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getPreviousCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getPreviousCandidateId)"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                id="nextbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getNextCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getNextCandidateId)"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </CCol>
          <CCol md="12" sm="12" lg="6">
            <h3 class="text-primary main-title mt-2">
              Candidate Detailed View
            </h3>
          </CCol>
          <CCol md="12" sm="12" lg="6" class="d-none d-lg-block">
            <div class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back</CButton
              >
              <CButton
                id="prevbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getPreviousCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getPreviousCandidateId)"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                id="nextbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getNextCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getNextCandidateId)"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </CCol>
        </CRow>
      </div>

      <CRow class="m-0 pb-4 mb-3">
        <CCol lg="12">
          <CCard>
            <CCardBody>
              <CRow class="pb-3">
                <CCol lg="4" class="pb-2 px-0 dark-block">
                  <div
                    class="
                      cand-info-img
                      text-center
                      m-auto
                      pb-1
                      position-relative
                    "
                  >
                    <img
                      :src="profilePicture"
                      class="rounded-circle"
                      onerror="this.onerror=null;this.src='/img/user_default_pic.png';"
                      alt
                    />
                    <div
                      class="position-absolute"
                      style="top: 0px; right: 10px"
                    >
                      <span
                        class="step bg-primary"
                        v-if="profile.retired_clinician === 'Yes'"
                        v-c-tooltip="'Retired'"
                        >R</span
                      >
                      <span
                        class="step bg-primary"
                        v-if="profile.volunteer === 'Yes'"
                        v-c-tooltip="'Volunteer'"
                        >V</span
                      >
                    </div>
                  </div>
                  <div class="text-center text-white-bold">
                    <h5 class="m-0">
                      {{ toTitleCase(profile._title) }}
                      {{ toTitleCase(profile.first_name) }}
                      {{ toTitleCase(profile.surname) }}
                    </h5>

                    <p class="mb-0" v-if="profile.candidateTypeName">
                      {{ profile.candidateTypeName }}
                    </p>
                    <p class="mb-1">
                      <span v-if="profile.nationalityName">
                        {{ profile.nationalityName }}
                      </span>
                      <span v-if="profile.genderName"
                        >, {{ profile.genderName }}</span
                      >
                      <span class="nowrap" v-if="profile.age"
                        >, {{ profile.age }} Years</span
                      >
                    </p>
                    <div>
                      <span>Candidate ID : </span>{{ profile.candidate_uid }}
                    </div>
                    <div
                      class="pb-2"
                      v-if="
                        currentUserRole !== Role.systemCandidate &&
                        currentUserRole !== Role.customerCandidate
                      "
                    >
                      <span :style="'font-size: 12px'"
                        >Last Login: {{ profile.lastLoginDate }}
                      </span>
                      <span
                        v-if="profile.last_date_of_practise"
                        :style="'font-size: 12px'"
                        class="d-block"
                        >Last Practice:
                        {{ profile.last_date_of_practise | formatDate }}
                      </span>
                    </div>
                    <CRow class="justify-content-center">
                      <a v-for="(data, index) in resumeDocument" :key="index">
                        <CButton
                          color="primary"
                          class="px-2 f-12 mr-2"
                          @click="preview(data)"
                        >
                          {{ data.lable }}
                          <i class="fas fa-eye ml-2"></i>
                        </CButton>
                      </a>
                    </CRow>
                    <div
                      v-if="
                        currentUserRole === Role.customerAdmin ||
                        currentUserRole === Role.customerRecruiter
                      "
                      class="my-2"
                    >
                      <div v-if="!candidateProfile.doh_candidate_status">
                        <CButton
                          color="primary"
                          class="f-12 my-2"
                          style="width: 110px"
                          @click="
                            confirmationPopupwithReason(
                              getShortliststatus.status
                            )
                          "
                          >ShortList</CButton
                        >
                      </div>
                      <div v-else class="justify-content-center">
                        <CRow class="my-2">
                          <CCol>
                            <CButton
                              v-if="
                                candidateProfile.doh_candidate_status
                                  .status_id === getShortliststatus.status_id
                              "
                              color="primary"
                              class="f-12 mx-1"
                              @click="
                                confirmationPopupwithReason(
                                  getInterviewstatus.status
                                )
                              "
                              >Interview</CButton
                            >
                            <CButton
                              v-if="
                                candidateProfile.doh_candidate_status
                                  .status_id === getInterviewstatus.status_id
                              "
                              color="primary"
                              class="f-12 mx-1"
                              @click="
                                confirmationPopupwithReason(
                                  getRecruitstatus.status
                                )
                              "
                              >Recruit</CButton
                            >
                            <CButton
                              v-if="
                                this.candidateProfile.doh_candidate_status
                                  .status_id
                              "
                              color="danger"
                              variant="outline"
                              class="f-12 mx-1"
                              @click="
                                confirmationPopupwithReason(
                                  getReleasestatus.status
                                )
                              "
                              >Release</CButton
                            >
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <span
                              class="px-1 fs-12 text-primary"
                              style="font-weight: bold"
                              >{{
                                candidateProfile.doh_candidate_status
                                  .days_to_expire
                              }}
                              Days to Expiry</span
                            >
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                    <CRow
                      v-if="currentUserRole === Role.systemAdmin"
                      class="mt-2 justify-content-center"
                    >
                      <CButton
                        v-if="!profile.deleted"
                        name="Delete"
                        color="primary"
                        class="px-2 f-12 col-4"
                        @click="candidateState($event)"
                        >Delete</CButton
                      >
                      <CButton
                        v-if="profile.deleted"
                        name="Restore"
                        color="primary"
                        class="px-2 f-12 col-4"
                        @click="candidateState($event)"
                        >Restore</CButton
                      >
                      <CButton
                        v-if="
                          (profile.available_during_covid ||
                            profile.available_during_covid === null) &&
                          !profile.deleted
                        "
                        name="Deactivate"
                        variant="outline"
                        color="primary"
                        class="ml-2 f-12 px-1 col-4"
                        @click="candidateState($event)"
                        >Deactivate</CButton
                      >
                      <CButton
                        v-if="
                          profile.available_during_covid === false &&
                          !profile.deleted
                        "
                        name="Reactivate"
                        variant="outline"
                        color="primary"
                        class="ml-2 px-1 f-12 col-4"
                        @click="candidateState($event)"
                        >Reactivate</CButton
                      >
                    </CRow>
                  </div>
                  <div>
                    <ContactInfo :profile="profile" :isCandidate="true" />
                  </div>
                </CCol>
                <CCol lg="8">
                  <CRow class="mb-2">
                    <CCol lg="12">
                      <BasicInfo :profile="profile" />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol lg="12" v-if="isNurse">
                      <ProfessionalInfoNurse
                        :profile="profile"
                        :nurseSpeciality="nurseSpeciality"
                        :specialInterest="specialInterest"
                      />
                    </CCol>
                    <CCol lg="12" v-else-if="isAHP">
                      <ProfessionalInfoAHP
                        :profile="profile"
                        :nurseSpeciality="nurseSpeciality"
                        :specialInterest="specialInterest"
                      />
                    </CCol>
                    <CCol lg="12" v-else>
                      <ProfessionalInfo :profile="profile" />
                    </CCol>
                    <CCol lg="12">
                      <AvailabilityInfo :profile="profile" />
                    </CCol>
                    <CCol lg="12">
                      <CandidateLanguageInfo />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardBody>
              <CButton
                v-if="isCandidateRole"
                @click="navigateToEditProfile()"
                class="btn-primary float-right ml-2"
                title="Edit Profile"
              >
                Edit Section</CButton
              >
              <GenericTabFilters
                :isShowRefresh="genericTabFilter.isShowRefresh"
                :refreshCallBack="refreshCallBack"
                :filterCallBack="filterCallBack"
                :activeTab="information_step"
                :tabSelectCallBack="tabSelectCallBack"
                :isShowFavourite="true"
                page="CandidateDetails"
                :tabs="tabs"
                ref="GenericTabFilters"
              />

              <div class="info-content mt-2">
                <div v-if="information_step === 0 && !getTabInfo['tab_id']">
                  <ProfileInfo
                    :profile="profile"
                    :qualifications="qualifications"
                    :resumeDocument="resumeDocument"
                    :documents="documents"
                    ref="ProfileInfo"
                  />
                </div>
                <!-- <div v-if="information_step && getTabInfo['tab_id']">
                  <JobMatch
                    :jobs="jobs"
                    :selectedJob="jobDetails"
                    @getJobDetails="getJobDetails"
                    @actionModalEvent="actionModalEvent"
                  />
                  <div
                    v-if="noMoreJobs && jobs.length"
                    class="text-center mt-2"
                  >
                    <CBadge color="primary" class="font-weight-normal"
                      ><span>No More Jobs Found</span></CBadge
                    >
                  </div>
                </div>
                <div v-if="information_step === 5">
                  <AuditHistory :candidateProfile="profile" />
                </div> -->
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <!-- <CCol lg="4" id="jobDetails">
          <CCard v-if="isJobDetailsEmpty">
            <CCardBody class="f-12">
              <JobDetails
                :jobDetails="jobDetails"
                :getJobDetails="getJobDetails"
                :actionModalEvent="actionModalEvent"
              />
            </CCardBody>
          </CCard>
        </CCol> -->
      </CRow>
    </div>
    <h4 v-else class="text-muted text-center pt-4 mt-4">No Candidate Found</h4>
    <PopupModal
      v-if="popupModal.isShowPopup"
      :modalTitle="popupModal.modalTitle"
      :modalColor="popupModal.modalColor"
      :modalContent="popupModal.modalContent"
      :modalLabel="popupModal.modalLabel"
      :isShowPopup="popupModal.isShowPopup"
      :popupModalCallBack="popupModalCallBack"
      :modalJob="popupModal.modalJob"
      :modalReasonType="popupModal.modalReasonType"
      :isCommentRequired="popupModal.isCommentRequired"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
      <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
    </CToaster>
    <ActionModal
      v-if="actionModal.isShowPopup"
      :isShowPopup="actionModal.isShowPopup"
      :details="actionModal.details"
      :action="actionModal.action"
      @modalCallBack="actionModalCallBack"
    />
    <CToaster
      v-if="getUpdateToast.isShow"
      :autohide="getUpdateToast.autohide"
      position="bottom-center"
    >
      <CToast :class="getUpdateToast.cssClass" :show="true">{{
        getUpdateToast.msg || ""
      }}</CToast>
    </CToaster>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import { mapGetters, mapActions } from "vuex";
import {
  isObject,
  isEmptyObjectCheck,
  getScope,
  Role,
  appendAccessToken,
} from "@/helpers/helper";
import moment from "moment";
import Select from "@/components/reusable/Fields/Select";
import PreviewModal from "@/components/reusable/PreviewModal";

import BasicInfo from "@/containers/CandidateDetailsTalentFind/BasicInfo";
import ContactInfo from "@/containers/CandidateDetailsTalentFind/ContactInfo";
import ProfessionalInfo from "@/containers/CandidateDetailsTalentFind/ProfessionalInfo";
import AvailabilityInfo from "@/containers/CandidateDetailsTalentFind/AvailabilityInfo";
import CandidateLanguageInfo from "@/containers/CandidateDetailsTalentFind/CandidateLanguageInfo";
import ProfileInfo from "@/containers/CandidateDetailsTalentFind/ProfileInfo";
import PopupModal from "@/components/reusable/PopupModal";

import JobDetails from "@/containers/CandidateDetailsTalentFind/JobDetails";
import JobMatch from "@/containers/CandidateDetailsTalentFind/Job/JobMatch";
import JobInProcess from "@/containers/CandidateDetailsTalentFind/Job/JobInProcess";
import JobToDo from "@/containers/CandidateDetailsTalentFind/Job/JobToDo";
import Alerts from "@/containers/CandidateDetailsTalentFind/Alerts";
import AuditHistory from "@/containers/CandidateDetailsTalentFind/AuditHistory";
import GenericTabFilters from "@/components/reusable/GenericTabFilters";
import ActionModal from "@/components/reusable/GenericTabFilters/ActionModal";
import ProfessionalInfoNurse from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoNurse";
import ProfessionalInfoAHP from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoAHP";

export default {
  name: "CandidateDetails",
  components: {
    BasicInfo,
    ContactInfo,
    ProfessionalInfo,
    AvailabilityInfo,
    JobDetails,
    PopupModal,
    ProfileInfo,
    JobMatch,
    JobInProcess,
    JobToDo,
    Alerts,
    AuditHistory,
    Select,
    PreviewModal,
    CandidateLanguageInfo,
    GenericTabFilters,
    ActionModal,
    ProfessionalInfoNurse,
    ProfessionalInfoAHP,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      popupModal: {
        modalColor: "",
        modalTitle: "",
        modalContent: "",
        modalLabel: "",
        isShowPopup: false,
        modalJob: "",
        modalReasonType: "",
        isCommentRequired: true,
      },
      information_step: 0,
      jobDetails: null,
      secondaryFilters: {},
      iframe: {
        url: null,
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      genericTabFilter: { isShowRefresh: false },
      actionModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
      },
      isShortlist: false,
      isInterview: false,
      isRecruit: false,
      isRelease: false,
    };
  },
  computed: {
    ...mapGetters([
      "candidateProfile",
      "isFetchingCandidatesDetails",
      "candidateListBackUrl",
      "getPreviousCandidateId",
      "getNextCandidateId",
      "getGenderColor",
      "getUploadedDocument",
      "getCandidateQualification",
      "getCandidateLanguage",
      "getCandidateSpecialInterest",
      "getSkills",
      "getCandidatePQRLevels",
      "getCustomerId",
      "getQueryResult",
      "getJobs",
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "getJobListForCandidateID",
      "noMoreJobs",
      "getJobListForCandidateIDCount",
      "getTabInfo",
      "getJobDetailsForCandidateID",
      "getDisplayStatusSubStatus",
      "getJobCandidateTabs",
      "getUpdateToast",
      "getShortliststatus",
      "getInterviewstatus",
      "getReleasestatus",
      "getRecruitstatus",
      // "getFacilityConfig",
      "getOrgID",
      "currentPageSchema",
    ]),
    // options() {
    //   return {
    //     filterMatches: [
    //       { code: null, label: "All" },
    //       { code: true, label: "Applied" },
    //       { code: false, label: "Talent Pool" },
    //     ],
    //   };
    // },
    // jobs() {
    //   if (
    //     this.getJobListForCandidateID &&
    //     this.getJobListForCandidateID.length
    //   ) {
    //     if (this.jobDetails == null) {
    //       const job = this.getJobListForCandidateID[0];
    //       this.getJobDetails(job, false);
    //     }
    //     return this.getJobListForCandidateID;
    //   }
    //   this.jobDetails = null;
    //   return [];
    // },
    // jobInfo() {
    //   return this.jobDetails?.job;
    // },
    // isJobDetailsEmpty() {
    //   return isObject(this.jobInfo) && !isEmptyObjectCheck(this.jobInfo);
    // },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    isCustomerUserRole() {
      return this.currentUserRole === Role.customerUser;
    },
    profilePicture() {
      let _base_domain = `${window.location.origin}/api/v1`;
      return this.candidateProfile?.profile_picture !=
        "/img/user_default_pic.png"
        ? appendAccessToken(
            `${_base_domain}/customer/${this.getCustomerId}/file/${this.candidateProfile?.profile_picture}`
          )
        : "/img/user_default_pic.png";
    },
    profile() {
      if (
        isObject(this.candidateProfile) &&
        !isEmptyObjectCheck(this.candidateProfile)
      )
        return {
          ...this.candidateProfile,
          candidateTypeName: this.candidateProfile.candidate_type
            ? this.candidateProfile.candidate_type.candidate_type
            : null,
          phoneNumber: this.candidateProfile.phone
            ? this.candidateProfile.phone.replace("^", "-")
            : null,
          alternatePhoneNumber: this.candidateProfile.alternate_phone
            ? this.candidateProfile.alternate_phone.replace("^", "-")
            : null,
          whatsappNumber: this.candidateProfile.whatsapp
            ? this.candidateProfile.whatsapp.replace("^", "-")
            : null,
          genderName: this.candidateProfile.gender
            ? this.candidateProfile.gender.gender
            : null,
          nationalityName: this.candidateProfile.nationality_names
            ? this.candidateProfile.nationality_names.join(", ")
            : null,
          birthPlaceName:
            this.candidateProfile?.birth_place?.country_name || null,
          lastLoginDate: this.candidateProfile.last_date_of_login
            ? moment(this.candidateProfile.last_date_of_login).format(
                "DD-MMM-YYYY"
              )
            : "--",
          _title: this.candidateProfile?.title?.title || null,
        };
      return {};
    },
    isCandidateFound() {
      return (
        this.isFetchingCandidatesDetails &&
        isObject(this.candidateProfile) &&
        !isEmptyObjectCheck(this.candidateProfile)
      );
    },
    documents() {
      return (
        (isObject(this.candidateProfile) &&
          !isEmptyObjectCheck(this.candidateProfile) &&
          this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id !== 1)
            .map(
              ({
                document_type_id,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
              }) => {
                return {
                  link: appendAccessToken(`/api/v1/customer/${this.getCustomerId}/file/${candidate_document_uuid}`),
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                  document_type_id: document_type_id,
                };
              }
            )) ||
        []
      );
    },
    resumeDocument() {
      return (
        (isObject(this.candidateProfile) &&
          !isEmptyObjectCheck(this.candidateProfile) &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 1)
            .map(
              ({
                document_type_id,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
              }) => {
                return {
                  link: appendAccessToken(`/api/v1/customer/${this.getCustomerId}/file/${candidate_document_uuid}`),
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                  document_type_id: document_type_id,
                };
              }
            )) ||
        []
      );
    },
    qualifications() {
      return (
        (this.profile?.qualifications &&
          this.profile?.qualifications.map(
            ({
              candidate_qualification_uuid,
              candidate_qualification_id,
              qualification_id,
              qualification_name,
              qualification,
              country_id,
              country_name,
              year_awarded,
              month_awarded,
              speciality_name,
              sub_speciality_name,
              issue_authority_id,
              issue_authority_name,
              other_issue_authority,
              course_duration,
              distance_learning,
              description,
              comments,
              by_exam,
              other_qualification_name,
              document_ids,
              documents,
              main,
            }) => {
              return {
                candidate_qualification_uuid,
                candidate_qualification_id: candidate_qualification_id,
                qualification_id: qualification_id,
                qualification_name: qualification_name,
                qualification_with_acronym:
                  qualification?.qualification_with_acronym,
                qualification: qualification,
                country_id: country_id,
                country_name: country_name,
                year_awarded: year_awarded,
                month_awarded: month_awarded,
                speciality_name: speciality_name,
                sub_speciality_name: sub_speciality_name,
                issue_authority_id: issue_authority_id,
                issue_authority_name: issue_authority_name,
                other_issue_authority: other_issue_authority,
                course_duration: course_duration,
                distance_learning: distance_learning,
                description: description,
                comments: comments,
                by_exam: by_exam,
                other_qualification_name: other_qualification_name,
                document_ids: document_ids,
                documents: documents,
                main: main,
                isFirst:
                  (main && this.profile?.qualifications.length === 1) ||
                  (!main && this.profile?.qualifications.length > 1),
              };
            }
          )) ||
        []
      );
    },
    qualifications2D() {
      return this.qualifications.reduce((a, c, i) => {
        return i % 2 === 0
          ? a.concat([this.qualifications.slice(i, i + 2)])
          : a;
      }, []);
    },
    // isJobDetailsNotEmpty() {
    //   return isObject(this.jobInfo) && !isEmptyObjectCheck(this.jobInfo);
    // },
    tabs() {
      let tabs = [{ tab_id: null, tab_name: "Profile" }];
      // if (!this.isCandidateRole && !this.isCustomerUserRole) {
      //   const dynamicTabs = this.getJobCandidateTabs || [];
      //   tabs = [
      //     ...tabs,
      //     ...dynamicTabs,
      //     { tab_id: null, tab_name: "History" },
      //     { tab_id: null, tab_name: "Alerts" },
      //   ];
      // }
      return tabs;
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
    nurseSpeciality() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities.filter(
            ({ speciality_id, special_interest_id, other_special_interest }) =>
              speciality_id &&
              special_interest_id === null &&
              other_special_interest === null
          )) ||
        []
      );
    },
    specialInterest() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities.filter(
            ({ special_interest_id, other_special_interest }) =>
              special_interest_id || other_special_interest
          )) ||
        []
      );
    },
    expiryDays() {
      return this.profile?.doh_candidate_status?.days_to_expire || -1;
    },
  },
  mounted() {
    let appendAction = [];
    if (
      this.isCandidateRole &&
      isObject(this.candidateProfile) &&
      !isEmptyObjectCheck(this.candidateProfile)
    ) {
      appendAction = [
        ...appendAction,
        this.commitCandidateInfo(this.candidateProfile),
      ];
    } else {
      appendAction = [
        ...appendAction,
        this.setSelectedcandidateById(this.$route.params.uuid),
      ];
    }
    Promise.all(appendAction).then(async (res) => {
      if (this.isCandidateFound) this.initCandidateDetailsFetch();
    });
    this.scrollTop();

    if (!this.getNextCandidateId) this.fetchMoreCandidateList();
  },
  created() {
    if (
      !(
        this.currentUserRole == this.Role.systemCandidate ||
        this.currentUserRole == this.Role.customerCandidate
      )
    ) {
      if (
        this.getShortliststatus.length == undefined ||
        this.getInterviewstatus.length == undefined ||
        this.getRecruitstatus.length == undefined
      ) {
        this.getallshortliststatus();
      }
    }
  },
  watch: {
    "$route.params.uuid": function (id) {
      const appendAction = this.setSelectedcandidateById(
        this.$route.params.uuid
      );
      Promise.all([appendAction]).then((res) => {
        this.$nextTick(() => {
          const ele = this.$refs.GenericTabFilters;
          if (ele) {
            ele.initEvent();
          }
        });
        // if (this.isCandidateFound) this.initCandidateDetailsFetch();
      });
      if (!this.getNextCandidateId) this.fetchMoreCandidateList();
      this.jobDetails = null;
    },
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "setSelectedcandidateById",
      "fetchMoreCandidateList",
      // "fetchJobs",
      "commitCandidateInfo",
      "downloadDocument",
      "initCandidateDetailsFetch",
      "getCandidateDetails",
      "deleteCandidate",
      "deactivateCandidate",
      "restoreCandidate",
      "reactivateCandidate",
      "showToast",
      // "fetchJobListForCandidateID",
      // "triggerCandidateJobMatch",
      // "initUpdateJobStatus",
      // "updateCandidateJobStatus",
      // "fetchJobCandidateTabs",
      // "initJobCandidateCount",
      // "fetchJobBenefitsAdvertDescByJobId",
      // "fetchCurrency",
      "createshortlist",
      "releaseCandidate",
      "createInterviewlist",
      "createRecruitList",
      // "fetchFacilityConfig",
      "getallshortliststatus",
      "fetchAllJobsByShortlistProcess",
    ]),
    navigateToEditProfile() {
      this.$store.state.candidate.block = 0;
      this.$router.push("/profile");
    },
    backtocardview() {
      this.$router.push(this.candidateListBackUrl).then((res) => {
        this.$store.commit(
          "REMOVE_CANDIDATE_LIST_BACK_URL",
          this.candidateListBackUrl
        );
      });
    },
    navigateto(uuid) {
      this.$router.push({ path: `/candidate/${uuid}` }).then((res) => {
        let appendAction = this.setSelectedcandidateById(this.$route.params.uuid);
        Promise.all([appendAction]).then(async (res) => {
          await this.initCandidateDetailsFetch();
          this.jobDetails = null;
          if (this.information_step === 0) this.$refs.ProfileInfo.resetExpand();
        });
      });
    },
    toTitleCase(str) {
      return str
        ? str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        : "";
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
      return false;
    },
    candidateState(event) {
      let name = event.currentTarget.name;
      let cname = {
        delete: "Delete",
        deactivate: "Deactivate",
      };
      this.popupModal.modalJob = "";
      this.popupModal.modalLabel = "Select Reason Code";
      this.popupModal.isCommentRequired = true;
      this.popupModal.modalReasonType = name.toUpperCase();
      this.popupModal.modalTitle = `${name} Candidate`;
      this.popupModal.modalContent = `Are you sure to ${name.toLowerCase()} the candidate? If Yes please select an appropriate reason..`;
      this.popupModal.isShowPopup = true;
      if (name === cname.delete || name === cname.deactivate) {
        this.popupModal.modalColor = "danger";
      } else {
        this.popupModal.modalColor = "primary";
      }
    },
    confirmationPopupwithReason(type) {
      this.popupModal.modalLabel = "";
      this.popupModal.isCommentRequired = false;
      if (type === this.getReleasestatus.status) {
        this.popupModal.modalColor = "danger";
        this.popupModal.modalLabel = "Select Reason Code";
        this.popupModal.modalContent =
          "Please confirm you are withdrawing this candidate from the recruitment process ?";
      } else {
        if (type == this.getShortliststatus.status) {
          this.popupModal.modalJob = "Select Job";
          this.popupModal.modalContent =
            "Please confirm would you like to shortlist this candidate for review/interview ?";
          this.fetchAllJobsByShortlistProcess(this.profile?.candidate_uid);
        } else if (type === this.getInterviewstatus.status) {
          this.popupModal.modalContent =
            "Please confirm an interview is being scheduled with this candidate";
        } else if (type === this.getRecruitstatus.status) {
          this.popupModal.modalContent =
            "Please confirm the interview has been completed and you are recruiting this candidate. Alternatively please remove the candidate from your list by selecting the release button";
        }
        this.popupModal.modalColor = "primary";
      }
      this.popupModal.modalReasonType = type;
      this.popupModal.modalTitle = type;
      this.popupModal.isShowPopup = true;
    },
    popupModalCallBack(action, value) {
      let appendAction;
      const { modalTitle } = this.popupModal;
      const _action = {
        confirm: "Confirm",
        cancel: "Cancel",
        deleteCandidate: "Delete Candidate",
        deactivateCandidate: "Deactivate Candidate",
        restoreCandidate: "Restore Candidate",
        reactivateCandidate: "Reactivate Candidate",
      };
      if (action === _action.confirm) {
        let payload = {
          candidate_id: this.profile.candidate_uid,
          candidate_uuid: this.profile.candidate_uuid,
          reason_id: value.reason_id || null,
          comments: value.comments || "",
          customer_uid: this.profile?.customer_uid,
        };
        let shortlistPayload = payload;
        //Shortlist
        if (modalTitle === this.getShortliststatus.status) {
          shortlistPayload.job_id = value.job_id;
          appendAction = this.createshortlist(shortlistPayload);
        }
        //Interview
        else if (modalTitle === this.getInterviewstatus.status) {
          shortlistPayload.candidate_status_id =
            this.profile.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = this.getInterviewstatus.status_id;
          appendAction = this.createInterviewlist(shortlistPayload);
        }
        //RecruitList
        else if (modalTitle === this.getRecruitstatus.status) {
          shortlistPayload.candidate_status_id =
            this.profile.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = this.getRecruitstatus.status_id;
          appendAction = this.createRecruitList(shortlistPayload);
        }
        //Release
        else if (modalTitle === this.getReleasestatus.status) {
          shortlistPayload.candidate_status_id =
            this.profile.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = this.getReleasestatus.status_id;
          if (
            this.profile.doh_candidate_status.status_id ===
            this.getShortliststatus.status_id
          ) {
            shortlistPayload.releasefrom = this.getShortliststatus.status_id;
          } else if (
            this.profile.doh_candidate_status.status_id ===
            this.getInterviewstatus.status_id
          ) {
            shortlistPayload.releasefrom = this.getInterviewstatus.status_id;
          } else if (
            this.profile.doh_candidate_status.status_id ===
            this.getRecruitstatus.status_id
          ) {
            shortlistPayload.releasefrom = this.getRecruitstatus.status_id;
          }
          appendAction = this.releaseCandidate(shortlistPayload);
        }
        //Delete
        else if (modalTitle === _action.deleteCandidate) {
          appendAction = this.deleteCandidate(payload);
        }
        //Deactivate
        else if (modalTitle === _action.deactivateCandidate) {
          appendAction = this.deactivateCandidate(payload);
        }
        //Restore
        else if (modalTitle === _action.restoreCandidate) {
          appendAction = this.restoreCandidate(payload);
        }
        //Reactivate
        else if (modalTitle === _action.reactivateCandidate) {
          appendAction = this.reactivateCandidate(payload);
        }
        Promise.all([appendAction]).then((res) => {
          this.getCandidateDetails(this.$route.params.uuid);
        });
      }
      this.popupModal.isShowPopup = false;
    },
    preview(data) {
      var _base_domain = window.location.origin;
      this.iframe.url = _base_domain + data.link;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(_base_domain + data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    goToSplInterestSection() {
      this.information_step = 0;
      setTimeout(() => {
        this.$refs.ProfileInfo.isExpand.specialInterest = true;
        let el = this.$el.getElementsByClassName("splInterest-info-section")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -70);
      }, 50);
    },
    refreshCallBack() {},
    filterCallBack(isFavourite) {},
    tabSelectCallBack({ index, tab_id }) {
      this.information_step = index;
    },
    goToExpendableSection() {
      this.$refs.GenericTabFilters.selectTab(0, null);
      this.tabSelectCallBack({ index: 0 });
      setTimeout(() => {
        this.$refs.ProfileInfo.expandAll();
        let el = this.$el.getElementsByClassName("profile-info")[0];
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -70);
      }, 50);
    },
  },
  filters: {
    formatDate(date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
span.step {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: $white;
  display: inline-block;
  font-family: Poppins, sans-sarif !important;
  font-weight: 300;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
  opacity: 0.9;
}
</style>