<template>
  <div>
    <p class="text-center text-gray h5">
      No Alerts Found
    </p>
  </div>
</template>

<script>
export default {
  name: "Alerts",
};
</script>
