<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Special Interest Information {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab
            :title="`Special Interest Information ${isEdit ? 'Edit' : 'Add'}`"
          >
            <div class="p-2">
              <ValidationObserver
                ref="splInterestInfoForm"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="splInterestInfoForm"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_special_interest"
                          class="col-lg-12 col-md-12"
                          >{{
                            isAHP
                              ? `Other Areas of Expertise`
                              : `Special Interest`
                          }}</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="singleRequired"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="special_interest"
                              :value="profile.special_interest"
                              @change="handleChangeSelect"
                              :options="
                                options && options['specialInterest']
                                  ? options['specialInterest']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3" v-if="isSplOther">
                        <label
                          name="lbl_special_interest_other"
                          class="col-lg-12 col-md-12"
                          >Name Special Interest</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="other_special_interest"
                            :value="profile.other_special_interest"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("singleRequired", {
  ...required,
  message: "Please input atleast one of the fields!",
});
export default {
  mixins: [EditSection],
  props: {
    splInterestInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
    };
  },
  computed: {
    ...mapGetters([
      "specialInterestFilterBySpeciality",
      "isFetchingFilter",
      "specialInterestFilterAHP",
    ]),
    options() {
      return {
        specialInterest: this.splInterestOptions || [],
      };
    },
    splInterestOptions() {
      let opt =
        this.specialInterestFilterBySpeciality({
          speciality: this.candidateInfo.speciality_id,
        }) || [];
      if (this.isAHP) {
        let opt_ahp =
          this.specialInterestFilterAHP({
            candidate_type_id: this.candidateInfo?.candidate_type_id,
            speciality: null,
          }) || [];
        opt = [...opt, ...opt_ahp];
        opt=_.uniqBy(opt, 'code')
      }
      if (!opt.length) opt.push({ label: "Other", code: "Other" });
      return opt;
    },
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.candidateInfo;
        if (this.isEdit) {
          let data = this.splInterestInfo;
          this.profile = {
            special_interest: {
              code:
                data.special_interest_id !== null
                  ? data.special_interest_id
                  : "Other",
              label:
                data.special_interest_name !== "Other"
                  ? data.special_interest_name
                  : "Other",
            },
            other_special_interest: data.other_special_interest,
            candidate_speciality_id: data.candidate_speciality_id,
          };
        }

        return true;
      }
      return false;
    },
    isAHP() {
      return this.candidateInfo?.candidate_type_id === 3 || false;
    },
    isSplOther() {
      return (
        (this.profile?.special_interest?.code &&
          ["Other", "Other - not listed"].includes(
            this.profile?.special_interest?.label
          )) ||
        false
      );
    },
  },
  methods: {
    ...mapActions(["initFetchSplInterestOptions", "showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.splInterestInfoForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let splInterestPayload = {
        speciality_id: this.candidateInfo.speciality_id
          ? this.candidateInfo.speciality_id
          : null,
        special_interest_id: this.profile.special_interest
          ? this.profile?.special_interest?.code == "Other"
            ? null
            : this.profile?.special_interest?.code
          : null,
        other_special_interest: this.profile.other_special_interest
          ? ["Other", "Other - not listed"].includes(
              this.profile?.special_interest?.label
            )
            ? this.profile?.other_special_interest
            : null
          : null,
      };
      if (this.isEdit) {
        splInterestPayload = {
          ...splInterestPayload,
          candidate_speciality_id: this.profile.candidate_speciality_id,
        };
      }
      this.modalCallBack(true, splInterestPayload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    this.initFetchSplInterestOptions();
  },
};
</script>