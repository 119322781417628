<template>
  <div>
    <div v-for="(data,index) in 3" :key="index">
      <div class="job-board-list-card cursor-pointer highlight" @click="getJobDetails">
        <div class="title job-card-title d-flex justify-content-between">
          <p class="mb-1" style="line-height: 1;">General</p>
        </div>
        <p class="posted-time mb-1">New</p>
        <div class="location">
          <p class="mb-0">
            <span>Abu Dhabi</span>
          </p>
          <div class="organisation">
            <span>nature</span>
          </div>
        </div>
        <p class="content mb-1">Our client has an urgent requirement of General staff in Abu Dhabi</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    getJobDetails() {
      this.$emit("getJobDetails");
    },
  },
};
</script>
<style lang="scss" scoped>
.posted-time {
  color: #c68d73;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
}
.status-publish {
  background-color: #02c902;
  border: 1px solid #02c902;
  padding: 3px 10px;
  border-radius: 6px;
  color: #fff;
  max-height: 16px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer:hover {
  background-color: #e4f8fa;
}
</style>