<template>
  <div>
    <div class="scroll-arrow">
      <!-- <a class="arrow left clickable" @click="arrowClick('left')">
        <CIcon name="cil-caret-left" />
      </a>
      <a class="arrow right clickable" @click="arrowClick('right')">
        <CIcon name="cil-caret-right" />
      </a> -->
      <div class="scroll-wrapper">
        <ul class="nav nav-tabs px-2" id="infoNav">
          <li
            role="presentation"
            v-for="(data, index) in tabs"
            :key="index"
            v-bind:class="{ active: active_tab === index }"
          >
            <a class="clickable btn" @click="selectTab(index, data.tab_id)"
              >{{ data.tab_name }} {{ getCount(data.tab_id) }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div v-if="activeTab && getTabInfo['tab_id']">
      <CRow>
        <CCol md="4" class="mb-3 pr-0">
          <Select
            name="filter_id"
            :value="secondaryFilters.filter_id"
            :options="
              options && options['mainFilter'] ? options['mainFilter'] : []
            "
            @input="handleChangeSelect"
            :multiple="false"
            :clearable="false"
            :searchable="false"
          />
        </CCol>
        <CCol md="4" class="mb-3 pr-0" v-if="options['subFilter'].length">
          <Select
            name="sub_filter_id"
            :value="secondaryFilters.sub_filter_id"
            :options="
              options && options['subFilter'] ? options['subFilter'] : []
            "
            @input="handleChangeSelect"
            :multiple="false"
            :clearable="true"
            :searchable="false"
          />
        </CCol>
        <CCol md="4">
          <div class="d-flex align-items-center">
            <CButton color="primary" size="sm" @click="refreshCallBack">
              Refresh
            </CButton>
            <span class="mx-4 mt-auto mb-auto" v-if="showFavourite">
              <i
                v-if="favouriteFilter"
                class="text-primary fas fa-heart f-15"
                v-c-tooltip="`Click to Show only favourite`"
                @click="getFavouriteItems(!favouriteFilter)"
              ></i>
              <i
                v-else
                class="far fa-heart f-15"
                v-c-tooltip="`Click to Show All`"
                @click="getFavouriteItems(!favouriteFilter)"
              ></i>
            </span>
          </div>
        </CCol>
      </CRow>
      <h5>Search Results: {{ getTabFilterCandidateCount }}</h5>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: [
    "isShowRefresh",
    "refreshCallBack",
    "filterCallBack",
    "activeTab",
    "tabSelectCallBack",
    "page",
    "tabs",
    "isShowFavourite",
  ],
  components: {
    Select,
  },
  data() {
    return {
      secondaryFilters: {},
      payload: {},
      active_tab: this.activeTab,
      favouriteFilter: false,
    };
  },
  computed: {
    ...mapGetters([
      "getJobFilterOptions",
      "getJobSubFilterOptions",
      "getTabInfo",
      "getJobCandidateTabs",
      "getJobCandidateCount",
      "getTabFilterCandidateCount",
    ]),
    options() {
      return {
        mainFilter: this.getJobFilterOptions || [],
        subFilter: this.getJobSubFilterOptions || [],
      };
    },
    // tabs() {
    //   let tabs = this.getJobCandidateTabs;
    //   switch (this.page) {
    //     case "CandidateDetails":
    //       tabs = [
    //         { tab_id: null, tab_name: "Profile" },
    //         ...tabs,
    //         { tab_id: null, tab_name: "History" },
    //         { tab_id: null, tab_name: "Alerts" },
    //       ];
    //       break;
    //     case "JobDetails":
    //       tabs = [
    //         { tab_id: null, tab_name: "Details" },
    //         ...tabs,
    //         { tab_id: null, tab_name: "Comments" },
    //       ];
    //       break;
    //   }
    //   return tabs;
    // },
    showFavourite() {
      return this.isShowFavourite && this.getTabInfo?.tab_id === 1;
    },
  },
  methods: {
    ...mapActions([
      "setTabInfo",
      "fetchJobSubFilterOptions",
      "fetchJobFilterOptions",
      "fetchJobCandidateTabs",
      "fetchCandidatesStatusSubStatus",
      "initJobCandidateCount",
    ]),
    handleChangeSelect(name, value) {
      Vue.set(this.secondaryFilters, name, value);
      this.changeFilterAction(name);
    },
    changeFilterAction(name) {
      let payload = {};
      if (name === "filter_id") {
        this.secondaryFilters["sub_filter_id"] = null;
        payload = {
          filter_id: this.secondaryFilters["filter_id"]?.code || null,
          sub_filter_id: null,
        };
      } else {
        payload = {
          filter_id: this.secondaryFilters["filter_id"]?.code || null,
          sub_filter_id: this.secondaryFilters["sub_filter_id"]?.code,
        };
      }
      this.setTabInfo(payload).then(async (res) => {
        if (name === "filter_id" && this.getTabInfo[name]) {
          await this.fetchJobSubFilterOptions();
        }
        if (this.getTabInfo?.filter_id) {
          this.fetchCandidatesStatusSubStatus().then((res) => {
            this.filterCallBack();
          });
        }
      });
    },
    // fetchTabFilter(tab_id) {
    //   if (tab_id) {
    //     let appendAction = this.fetchJobFilterOptions();
    //     Promise.all([appendAction]).then((res) => {
    //       if (this.getJobFilterOptions.length) {
    //         let firstFilter = this.getJobFilterOptions[0];
    //         this.handleChangeSelect("filter_id", firstFilter);
    //       }
    //     });
    //   }
    // },
    selectTab(index, tab_id) {
      this.active_tab = index;
      this.favouriteFilter = false;
      this.tabSelectCallBack({ index, tab_id });
      this.setTabInfo({ tab_id }).then(() => {
        // this.fetchTabFilter(tab_id);
      });
    },
    getCount(tab_id) {
      if (
        tab_id &&
        isObject(this.getJobCandidateCount) &&
        !isEmptyObjectCheck(this.getJobCandidateCount)
      ) {
        return this.getJobCandidateCount && this.getJobCandidateCount[tab_id]
          ? `(${this.getJobCandidateCount[tab_id]})`
          : "";
      }
      return "";
    },
    // arrowClick(direction) {
    //   let e = document.querySelector("#infoNav");
    //   if (e != null)
    //     if (direction === "left") {
    //       e.scrollLeft -= 150;
    //     } else if (direction === "right") {
    //       e.scrollLeft += 150;
    //     }
    // },
    getFavouriteItems(flag) {
      this.favouriteFilter = flag;
      if (flag) {
        let payload = [
          {
            display_status: 42,
            display_sub_status: 70,
          },
        ];
        this.$store.commit("SET_DISPLAY_STATUS_SUB_STATUS", payload);
        this.filterCallBack(true);
      } else {
        if (this.getTabInfo?.filter_id) {
          this.fetchCandidatesStatusSubStatus().then((res) => {
            this.filterCallBack();
          });
        }
      }
    },
    initEvent() {
      this.favouriteFilter = false;
      const _tabs = this.tabs;
      const _activeTab = this.active_tab;
      if (_tabs && _tabs.length && _tabs[_activeTab]) {
        let tab_id = _tabs[_activeTab]?.tab_id || null;
        let page = this.page;
        this.setTabInfo({ tab_id, page }).then((res) => {
          this.initJobCandidateCount();
        });
        // this.fetchTabFilter(tab_id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-wrapper {
  margin-top: 2rem;
}
.f-15 {
  font-size: 15px;
}
</style>