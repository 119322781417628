var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.modalTitle))]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.onCancel()}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn px-4 btn-outline-primary",on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(_vm._s(_vm.confirmation ? "Close" : "No"))]),(!_vm.confirmation)?_c('CButton',{staticClass:"px-4 btn-primary",on:{"click":function($event){return _vm.onConfirm()}}},[_vm._v("Yes")]):_c('CButton',{staticClass:"px-4 btn-primary",on:{"click":function($event){return _vm.onSave()}}},[_vm._v("Save")])]},proxy:true}])},[_c('div',[(!_vm.confirmation)?_c('div',[_c('h6',{staticClass:"text-center"},[_vm._v(" Do you want to link the "),_c('strong',[_vm._v(_vm._s(_vm.label))]),_vm._v(" with "+_vm._s(_vm.document_type_id == 9 ? "License" : "Qualification")+" ? ")])]):_c('div',[_c('h6',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.modalContent)+" "),_c('strong',[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"text-primary pt-1",staticStyle:{"font-weight":"600","background-color":"whitesmoke"}},[_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.document_type_id == 9 ? "Licenses" : "Qualifications")+" ")])]),(_vm.document_type_id == 9)?_c('div',[_vm._l((_vm.licenses),function(i){return _c('div',{key:i.candidate_license_id,staticClass:"mt-1 border-bottom",staticStyle:{"background-color":"whitesmoke"},style:(`${
                _vm.selected.candidate_license_id == i.candidate_license_id
                  ? 'background-color: #D0F0C0'
                  : 'background-color: whitesmoke'
              }`)},[_c('CRow',{staticClass:"ml-2"},[_c('div',{staticClass:"doc-table col col-1"},[_c('p',{staticClass:"head mt-3"},[_c('input',{attrs:{"name":"candidate_license_id","type":"radio"},domProps:{"value":i.candidate_license_id,"checked":_vm.selected.candidate_license_id == i.candidate_license_id},on:{"input":function($event){return _vm.handleRadio(
                        'candidate_license_id',
                        i.candidate_license_id
                      )}}})])]),_c('div',{staticClass:"doc-table col col-3"},[_c('p',{staticClass:"head"},[_vm._v("License Valid In")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.country_name))])]),_c('div',{staticClass:"doc-table col col-4"},[_c('p',{staticClass:"head"},[_vm._v("Medical License Board")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.issue_authority_name))])]),_c('div',{staticClass:"doc-table col col-3"},[_c('p',{staticClass:"head"},[_vm._v("Issue/Registration Date")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.issue_date))])])])],1)}),(!_vm.licenses.length)?_c('div',{staticStyle:{"background-color":"whitesmoke","height":"40px"}},[_c('h5',{staticClass:"text-center mt-2 pt-2"},[_vm._v("No License found")])]):_vm._e()],2):_vm._e(),(_vm.document_type_id == 12)?_c('div',[_vm._l((_vm.SortedQualifications),function(i){return _c('div',{key:i.candidate_qualification_id,staticClass:"mt-1 border-bottom",staticStyle:{"background-color":"whitesmoke"},style:(`${
                _vm.selected.candidate_qualification_id ==
                i.candidate_qualification_id
                  ? 'background-color: #D0F0C0'
                  : 'background-color: whitesmoke'
              }`)},[_c('CRow',{staticClass:"ml-2"},[_c('div',{staticClass:"doc-table col col-1"},[_c('p',{staticClass:"head mt-3"},[_c('input',{attrs:{"name":"candidate_qualification_id","type":"radio"},domProps:{"value":i.candidate_qualification_id,"checked":_vm.selected.candidate_qualification_id ==
                        i.candidate_qualification_id},on:{"input":function($event){return _vm.handleRadio(
                        'candidate_qualification_id',
                        i.candidate_qualification_id
                      )}}})])]),_c('div',{staticClass:"doc-table col col-6"},[_c('p',{staticClass:"head"},[_vm._v("Qualification")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(_vm._f("qualificationName")(i)))])]),_c('div',{staticClass:"doc-table col col-5"},[_c('p',{staticClass:"head"},[_vm._v("Country Obtained")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.country_name))])]),_c('div',{staticClass:"doc-table col col-1"}),_c('div',{staticClass:"doc-table col col-6"},[_c('p',{staticClass:"head"},[_vm._v("Issued By")]),_c('div',{staticClass:"data"},[(i.other_issue_authority != '--')?_c('span',[_vm._v(" (others) "+_vm._s(i.other_issue_authority)+" ")]):_c('span',[_vm._v(" "+_vm._s(i.issue_authority_name)+" ")])])]),_c('div',{staticClass:"doc-table col col-5"},[_c('p',{staticClass:"head"},[_vm._v("Month and Year")]),_c('p',{staticClass:"data"},[_vm._v(" "+_vm._s(_vm.formatDate(i.month_awarded, i.year_awarded))+" ")])])])],1)}),(!_vm.SortedQualifications.length)?_c('div',{staticStyle:{"background-color":"whitesmoke","height":"40px"}},[_c('h5',{staticClass:"text-center mt-2 pt-2"},[_vm._v("No Qualification found")])]):_vm._e()],2):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }