<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.language = !$parent.isExpand.language),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.language ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary text-left">
          Language Information
          <span class="nowrap"
            >[{{ LanguageSpokenInfo.length }} Record{{
              LanguageSpokenInfo.length > 1 ? "s" : ""
            }}]</span
          >
        </h5>
      </a>
      <div
        class="d-flex justify-content-end sticky-header"
        v-if="$parent.isExpand.language"
      >
        <!--<CButton
          v-if="isCustomerAdmin"
          class="btn-primary small mr-1 mt-1"
          style="height: 28px"
          @click="addLanguage()"
          v-c-tooltip="'Click to Add multiple records!'"
          >Add</CButton
        >-->
      </div>
    </div>
    <div v-if="$parent.isExpand.language">
      <div
        v-if="LanguageSpokenInfo.length"
        class="d-flex align-items-center documentScrolling document-table border"
      >
        <table class="table">
          <thead>
            <tr>
              <th :class=" isMobile ? 'nowrap pl-1' : 'nowrap'">Language</th>
              <th :class=" isMobile ? 'nowrap px-1' : 'nowrap'">Proficiency</th>
              <!--<th v-if="isCustomerAdmin" :class=" isMobile ? 'nowrap px-0' : 'nowrap'">Action</th>-->
            </tr>
          </thead>
          <tbody class="text-black">
            <tr v-for="(data, index) in LanguageSpokenInfo" :key="index">
              <td :class="isMobile ? 'pl-1' : ''">{{ data.language || "--" }}</td>
              <td :class="isMobile ? 'px-1' : ''">{{ data.proficiency || "--" }}</td>
              <!--<td v-if="isCustomerAdmin" :class="isMobile ? 'px-0' : ''">
                <a class="hand-icon btn pr-2 p-0" @click="editLanguage(data)">
                  <i class="fas fa-edit"></i> </a
                >&nbsp; | &nbsp;
                <a class="hand-icon btn pl-2 p-0" @click="deleteLanguage(data)">
                  <i class="fas fa-trash"></i>
                </a>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-center text-muted h6">No Language Information Found</p>
      </div>
    </div>
    <LanguageInfoForm
      :languageInfo="languageModal.data"
      :isShowPopup="languageModal.isShowPopup"
      :isEdit="languageModal.isEdit"
      @modalCallBack="languageModalCallBack"
      v-if="languageModal.isShowPopup"
    >
    </LanguageInfoForm>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteLanguageCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import LanguageInfoForm from "../EditForm/LanguageInfoForm";
import Modal from "@/components/reusable/Modal";
import { mapActions } from "vuex";
export default {
  props: {
    LanguageSpokenInfo: {
      type: Array,
      default: () => [],
    },
    profile: {
      type: Object,
      default: () => null,
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LanguageInfoForm,
    Modal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      languageModal: {
        data: null,
        isShowPopup: false,
        isEdit: false,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Language Info",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidateLanguage",
      "uploadCandidateLanguage",
      "showToast",
      "updateLanguage",
      "deleteCandidateLanguage",
    ]),
    addLanguage() {
      this.$parent.tooltipHide();
      this.languageModal.isEdit = false;
      this.languageModal.data = null;
      this.languageModal.isShowPopup = true;
    },
    editLanguage(data) {
      this.languageModal.isEdit = true;
      this.languageModal.data = data;
      this.languageModal.isShowPopup = true;
    },
    languageModalCallBack(action, payload) {
      if (action === false) this.languageModal.isShowPopup = false;
      else {
        let appendAction = null;
        if (
          this.languageModal.data === null &&
          this.languageModal.isEdit === false
        ) {
          appendAction = this.uploadCandidateLanguage(payload);
        } else if (this.languageModal.isEdit) {
          appendAction = this.updateLanguage(payload);
        }
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.languageModal.isShowPopup = false;
            this.languageModal.isEdit = false;
            this.languageModal.data = null;
          }
        });
      }
    },
    deleteLanguage(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this Language - ${data.language} ?`;
      this.candidate_language_id = data.candidate_language_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteLanguageCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateLanguage(this.candidate_language_id);
      }
      this.candidate_language_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
};
</script>
