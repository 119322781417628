<template>
  <div>
    <div v-if="isFetched">
      <CRow class="pl-2 pt-2 pb-2 profileView">
        <CCol col="4" class="text-gray">Qualification Name</CCol>
        <CCol col="8" class="text-black">{{
          qualificationName(qualification)
        }}</CCol>
      </CRow>
       <CRow class="pl-2 pt-1 pb-2 profileView" v-if="qualification.others_name != '--'">
        <CCol col="4" class="text-gray">Subject</CCol>
        <CCol col="8" class="text-black">{{
          qualification.others_name
        }}</CCol>
      </CRow>
      <CRow class="pl-2 pt-1 pb-2 profileView" v-if="qualificationScore(qualification) != '--'">
        <CCol col="4" class="text-gray">Score</CCol>
        <CCol col="8" class="text-black">{{
          qualificationScore(qualification)
        }}</CCol>
      </CRow>
      <CRow class="pl-2 pt-1 pb-2 profileView" v-if="qualificationScore(qualification) != '--'">
        <CCol col="4" class="text-gray">Skill Level</CCol>
        <CCol col="8" class="text-black">{{
          qualification.skill_level || "--"
        }}</CCol>
      </CRow>
      <CRow class="pl-2 pt-1 pb-2 profileView">
        <CCol col="4" class="text-gray">Month / Year Obtained</CCol>
        <CCol col="8" class="text-black"
          >{{ formatDate(qualification.month, qualification.year) }}
          <span
            v-if="qualification.expired_message"
            class="data text-primary"
            :style="{ 'font-size': '14px' }"
            >{{ "(" + qualification.expired_message + ")" }}</span
          >
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import m from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: ["qualification"],
  computed: {
    isFetched() {
      return (
        isObject(this.qualification) && !isEmptyObjectCheck(this.qualification)
      );
    },
  },
  methods: {
    qualificationName(data) {
      if (data !== null)
        return data.name !== "--"
          ? data.name : ""
      return "--";
    },
    qualificationScore(data) {
      if (data !== null)
        return data.score !== "N/A"
          ? `${data.score ? data.score : "--"}`
          : "--";
      return "--";
    },
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
  },
};
</script>
